import { ForgotPasswordModalComponent } from './../forgot-password-modal/forgot-password-modal.component';
import { UserService } from './../../services/user.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService, GoogleLoginProvider, FacebookLoginProvider } from 'angularx-social-login';
import { ToastrService } from 'ngx-toastr';
import { isNullOrUndefined } from 'util';
import { Router } from '@angular/router';
import * as jwt_decode from 'jwt-decode';
import { NgbModal, NgbModalOptions, NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { SignUpComponent } from '../sign-up/sign-up.component';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  myForm: any;
  isRedirectSearchFromFile: boolean;

  constructor(private fb: FormBuilder,
              private userService: UserService,
              private authService: AuthService,
              private toastr: ToastrService,
              private router: Router,
              public modal: NgbActiveModal,
              private modalService: NgbModal,) {
  }

  ngOnInit() {
    this.myForm = this.fb.group({
      Email: ['', Validators.required],
      Password: ['', Validators.required],
    });
  }

  // convenience getter for easy access to form fields
  get f() { return this.myForm.controls; }

  submit() {
    this.isRedirectSearchFromFile = sessionStorage.getItem('isRedirectSearchFromFile') == 'true';
    if (this.myForm.valid) {
      this.userService.login(this.myForm.value).subscribe((res: any) => {
        this.myForm.submitUserError = false;
        this.myForm.userInActive = false;
        if (res.code !== 'OK') {
          if (res.code === 'UserInActive') {
            this.toastr.error('This User is Inactive. Please contact Admin.');
            this.myForm.userInActive = true;
          } else if (res.code === 'UserNotFound') {
            this.toastr.error('Username or Password is Incorret.');
            this.myForm.submitUserError = true;
          } else if (res.code === 'PasswordIncorrect') {
            this.myForm.submitUserError = true;
            this.toastr.error('Username or Password is Incorret.');
          }
        } else {
          const result = res.result;
          const tokenInfo = this.getDecodedAccessToken(result.token);
          localStorage.setItem('currentUser', JSON.stringify(result));
          localStorage.setItem('jwt', result.token);
          this.userService.currentUserSubject.next(result);
          //if (tokenInfo.role === 'User') {
          //  this.router.navigateByUrl('');
          //} else {
          //  this.router.navigateByUrl('dashboard');
          //}
          if (this.isRedirectSearchFromFile){
            this.router.navigateByUrl('search-from-file');
          } else {
            this.router.navigateByUrl('');
          }
        }
        this.modal.dismiss('Success');
      });
    }
  }

  signUpWithGoogle(): void {
    this.authService.signIn(GoogleLoginProvider.PROVIDER_ID).then((user) => {
      if (user) {
        const data = {
          Email: user.email
        };
        this.userService.loginWithSocial(data).subscribe((res: any) => {
          if (res.code !== 'OK') {
            if (res.code === 'UserInActive') {
              this.toastr.error('This User is Inactive. Please contact Admin.');
            } else if (res.code === 'UserNotFound') {
              this.toastr.error('Username or Password is Incorret.');
            } else if (res.code === 'PasswordIncorrect') {
              this.toastr.error('Username or Password is Incorret.');
            }
          } else {
              const result = res.result;
              const tokenInfo = this.getDecodedAccessToken(result.token);

              this.toastr.success('Sign In Success');
              localStorage.setItem('currentUser', JSON.stringify(result));
              localStorage.setItem('jwt', result.token);
              this.userService.currentUserSubject.next(result);
              if (tokenInfo.role === 'User') {
                this.router.navigateByUrl('');
              } else {
                this.router.navigateByUrl('dashboard');
              }
          }
        }).add(() => {
          this.authService.signOut(true);
        });
      }
    });
  }

  signUpWithFB(): void {
    this.authService.signIn(FacebookLoginProvider.PROVIDER_ID).then((user) => {
      if (user) {
        const data = {
          Email: user.email
        };
        this.userService.loginWithSocial(data).subscribe((res: any) => {
          if (res.code !== 'OK') {
            if (res.code === 'UserInActive') {
              this.toastr.error('This User is Inactive. Please contact Admin.');
            } else if (res.code === 'UserNotFound') {
              this.toastr.error('Username or Password is not corret.');
            } else if (res.code === 'PasswordIncorrect') {
              this.toastr.error('Username or Password is not corret.');
            }
          } else {
              const result = res.result;
              const tokenInfo = this.getDecodedAccessToken(result.token);
              this.toastr.success('Sign In Success');
              localStorage.setItem('currentUser', JSON.stringify(result));
              localStorage.setItem('jwt', result.token);
              this.userService.currentUserSubject.next(result);
              if (tokenInfo.role === 'User') {
                this.router.navigateByUrl('');
              } else {
                this.router.navigateByUrl('dashboard');
              }
          }
        }).add(() => {
          this.authService.signOut(true);
        });
      }
    });
  }

  getDecodedAccessToken(token: string): any {
    try {
        return jwt_decode(token);
    } catch (Error) {
        return null;
    }
  }

  forgotPassowrd() {
    const ngbModalOptions: NgbModalOptions = {
      backdrop : 'static',
      keyboard : false,
    };
    const modalRef = this.modalService.open(
      ForgotPasswordModalComponent, ngbModalOptions
    );
    modalRef.result.then((result) => {
    }, (reason) => {
      if (reason === 'Success') {
        this.userService.getAll().subscribe(res => {
        });
      }
    });
  }
  signUp() {
    const ngbModalOptions: NgbModalOptions = {
      backdrop : true,
      keyboard : false,
    };
    const modalRef = this.modalService.open(
      SignUpComponent, ngbModalOptions
    );
    modalRef.result.then((result) => {
    }, (reason) => {
      if (reason === 'Success') {
        this.userService.getAll().subscribe(res => {
        });
      }
    });
    this.modal.dismiss('Success');
  }
}

import { NotifyService } from 'src/app/services/notify.service';
import { Component, OnInit } from '@angular/core';
import { ColumnTable } from 'src/app/models/column-table/column-table.component';
import { Users } from 'src/app/models/users';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { ToastrService } from 'ngx-toastr';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ContactUsService } from 'src/app/services/contact-us.service';
import { ActionModel } from 'src/app/models/table-action-model';
import { ConfirmDialogComponent } from 'src/app/base-components/dialogs/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {
  columns: ColumnTable[];
  total = 100;
  data = [];
  actions = [];
  limit = 10;
  pageIndex = 1;
  modalRef: any;
  sortBy = '';
  orderBy = 'asc';
  rawData: any;
  isLoading = true;

  constructor(
    private toastr: ToastrService,
    private notifyService: NotifyService,
    private modalService: NgbModal,
  ) {

  }

  ngOnInit() {
    sessionStorage.setItem('isHome', 'false');
    sessionStorage.setItem('isRedirectSearchFromFile', 'false');
    
    this.columns = [
      new ColumnTable('', '', '', 'checkbox', false),
      new ColumnTable('Type', 'type', 'col-1', 'text', true, 'type'),
      new ColumnTable('Detail', 'detail', 'col-4', 'text-noti', true, 'detail'),
      new ColumnTable('Created Date', 'createAt', 'col-2', 'createDate', true, 'createAt'),
      new ColumnTable('Update Date', 'updateAt', 'col-2', 'ModifyDate', true, 'updateAt'),
      new ColumnTable('User', '', 'col-1', 'createBy', true, 'createBy'),
    ];

    this.actions.push(new ActionModel({
      callBack: (data) => this.read(data),
      type: 'read'
    }));
    this.actions.push(new ActionModel({
      callBack: (data) => this.unRead(data),
      type: 'unread'
    }));
    this.actions.push(new ActionModel({
      callBack: (data) => this.delete(data),
      type: 'delete'
    }));

    this.loadData();
  }

  loadData() {
    this.isLoading = true;
    this.notifyService.getAll().subscribe((res: []) => {
      this.rawData = res;
      this.data = res;
      this.total = this.data.length;
      this.isLoading = false;
    });
  }


  unRead(data: any) {
    data.isRead = false;
    this.notifyService.update(data).subscribe(x => {
      this.loadData();
    });
    this.notifyService.notification.emit(1);
  }

  read(data: any) {
    data.isRead = true;
    this.notifyService.update(data).subscribe(x => {
      this.loadData();
    });
    this.notifyService.notification.emit(-1);
  }

  delete(data: any) {
    const modalRef = this.modalService.open(ConfirmDialogComponent);
    modalRef.result.then((result) => {
      if (result) {
        this.notifyService.delete(data.id).subscribe(x => {
          this.loadData();
          this.toastr.success('Delete Success');
        });
        if (!data.isRead) {
          this.notifyService.notification.emit(-1);
        }
      }
    }, (reason) => {
    });
  }

  onTableOwnPageChange(event) {
    this.limit = event.pageSize;
    this.pageIndex = event.pageIndex;
  }

  onSortChange(event) {
    const col = event.sortBy;
    if (col === 'type') {
      this.data = this.data.sort((a, b) => {
        if (a.type < b.type) {
          return event.orderBy === 'asc' ? -1 : 1;
        } else if (a.type > b.type)  {
          return event.orderBy === 'asc' ? 1 : -1;
        } else {
          return 0;
        }
      });
    } else if (col === 'detail') {
      this.data = this.data.sort((a, b) => {
        if (a.detail < b.detail) {
          return event.orderBy === 'asc' ? -1 : 1;
        } else if (a.detail > b.detail)  {
          return event.orderBy === 'asc' ? 1 : -1;
        } else {
          return 0;
        }
      });
    } else if (col === 'createAt') {
      this.data = this.data.sort((a, b) => {
        if (new Date(a.createAt) < new Date(b.createAt) ) {
          return event.orderBy === 'asc' ? -1 : 1;
        } else if (new Date(a.createAt) > new Date(b.createAt) )  {
          return event.orderBy === 'asc' ? 1 : -1;
        } else {
          return 0;
        }
      });
    } else if (col === 'updateAt') {
      this.data = this.data.sort((a, b) => {
        if (new Date(a.updateAt) < new Date(b.updateAt) ) {
          return event.orderBy === 'asc' ? -1 : 1;
        } else if (new Date(a.updateAt) > new Date(b.updateAt) )  {
          return event.orderBy === 'asc' ? 1 : -1;
        } else {
          return 0;
        }
      });
    }
  }

  onSearch(event) {
    // tslint:disable-next-line:max-line-length
    const value = event ? event.text.toLowerCase() : null;
    const dateFromObj = event.dateFrom;
    const dateToObj = event.dateTo;
    const dateFrom = dateFromObj ? new Date(dateFromObj.year + '-' + dateFromObj.month + '-' + dateFromObj.day) : null ;
    const dateTo = dateToObj ? new Date(dateToObj.year + '-' + dateToObj.month + '-' + dateToObj.day) : null ;

    this.data = this.rawData.filter(x =>
      (x.type.toLowerCase().indexOf(value) > -1
        || x.detail.toLowerCase() .indexOf(value) > -1
        || (x.createdUser != null && (x.createdUser.firstName + x.createdUser.lastName).trim().toLowerCase().indexOf(value.trim()) > -1)
        || !value)
      && (((new Date(x.createAt).setHours(0, 0, 0, 0) >= (dateFrom ? dateFrom.getTime() : null) || dateFrom == null)
          && (new Date(x.createAt).setHours(0, 0, 0, 0) <= (dateTo ? dateTo.getTime() : null) || dateTo == null))
        || ((new Date(x.updateAt).setHours(0, 0, 0, 0) >= (dateFrom ? dateFrom.getTime() : null) || dateFrom == null)
         && (new Date(x.updateAt).setHours(0, 0, 0, 0) <= (dateTo ? dateTo.getTime() : null) || dateTo == null))
      ));
    this.total = this.data.length;
  }

  onDeleteAll(event: any[]) {
    const modalRef = this.modalService.open(ConfirmDialogComponent);
    modalRef.componentInstance.text = 'Are you sure to delete notification ' + event.length + ' items ?';
    modalRef.componentInstance.type = 'custom';
    modalRef.result.then((result) => {
      if (result) {
        event.forEach(element => {
          const data = this.data.find(x => x.id + '' === element);
          if (data) {
            this.notifyService.notification.emit(-1);
          }
        });
        this.notifyService.deleteAll(event).subscribe(x => {
          this.loadData();
          this.toastr.success('Delete Success');
        });
      }
    }, (reason) => {
    });
  }

}

import { UserFeedbackService } from './../../services/user-feedback.service';
import { ContactUsService } from './../../services/contact-us.service';


import { Component, OnInit } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { UserService } from 'src/app/services/user.service';
import { Router } from '@angular/router';
import * as jwt_decode from 'jwt-decode';
import { NotifyService } from 'src/app/services/notify.service';

@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.css'],
  animations: [
    trigger('slideInOut', [
      state('in', style({
        height: '100%'
      })),
      state('out', style({
        height: '100%'
      })),
      transition('in => out', animate('400ms ease-in-out')),
      transition('out => in', animate('400ms ease-in-out'))
    ]),
    trigger('slideShowHeight', [
      state('hide', style({
        height: '0px',
        opacity: '0',
        overflow: 'hidden',
        // display: 'none'
        })),
      state('show', style({
        height: '*',
        opacity: '1',
        // display: 'block'
      })),
      transition('in => out', animate('400ms ease-in-out')),
      transition('out => in', animate('400ms ease-in-out'))
    ]),
  ]
})
export class SideBarComponent implements OnInit {
  title = 'ClientApp';
  name = 'Angular';
  public dashboardAria = false;
  public feedbackAria = false;
  public manageUserAria = false;
  public nodeManageAria = false;

  notifyContactUs = 0;

  notification = 0;

  menuState = 'out';
  menuMappingManagementState = 'show';
  currentUser: any;
  userRole: any;
  feedback = 0;

  constructor(
    public userService: UserService,
    private router: Router,
    private notifyService: NotifyService,
    private contactUsService: ContactUsService,
    private userFeedbackService: UserFeedbackService
  ) {
    this.userService.currentUser.subscribe(x => this.currentUser = x);
    this.subscribeToEvents();
    const token = localStorage.getItem('jwt');
    if (token) {
      const decoded = jwt_decode(token);
      this.userRole = decoded.role;
      this.currentUser = decoded.name;
    }
    this.contactUsService.getUnRead().subscribe((x: []) => {
      this.notifyContactUs = x.length;
    });
    this.notifyService.getAll().subscribe((res: any[]) => {
      this.notification = res.filter(x => x.isRead === false).length;
    });
    this.userFeedbackService.getUnRead().subscribe((res: any) => {
      this.feedback = res;
    });
  }

  private subscribeToEvents(): void {
    this.notifyService.contactUs.subscribe((message) => {
      if (+message !==  0) {
        this.notifyContactUs += +message;
      } else {
        this.notifyContactUs = 0;
      }
    });

    this.notifyService.notification.subscribe((message) => {
      if (+message !==  0) {
        this.notification += +message;
      } else {
        this.notification = 0;
      }
    });
    this.notifyService.feedback.subscribe((message) => {
      if (+message !==  0) {
        this.feedback += +message;
      } else {
        this.feedback = 0;
      }
    });
  }

  ngOnInit() {
    const url = this.router.url;
    if (url.includes('/dashboard')) {
      this.dashboardAria = true;
    } else if (url.includes('/user-feedback')) {
      this.feedbackAria = true;
    } else if (url.includes('/manage-user')) {
      this.manageUserAria = true;
    } else if (url.includes('/node-management'))  {
      this.nodeManageAria = true;
    }
  }

  checkRole(roles) {
    const token = this.currentUser.token;
    const decoded = jwt_decode(token);
    const userRole = decoded.role;
    return roles.indexOf(userRole) !== -1;
  }

  toggleMenu() {
    this.menuState = this.menuState === 'out' ? 'in' : 'out';
    this.notifyService.showSidebar.emit(this.menuState);
  }

  toggleMenuMappingManagement() {
    this.menuMappingManagementState = this.menuMappingManagementState === 'show' ? 'hide' : 'show';
  }
}

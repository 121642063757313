import { OrganizationService } from 'src/app/services/organization.service';
import { Component, OnInit } from '@angular/core';
import { ColumnTable } from 'src/app/models/column-table/column-table.component';
import { Users } from 'src/app/models/users';
import { ActionModel } from 'src/app/models/table-action-model';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { ToastrService } from 'ngx-toastr';
import { NgbActiveModal, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { OrganizationModalComponent } from '../organization-modal/organization-modal.component';

@Component({
  selector: 'app-setting-organization',
  templateUrl: './setting-organization.component.html',
  styleUrls: ['./setting-organization.component.css']
})
export class SettingOrganizationComponent implements OnInit {
  columns: ColumnTable[];
  userTotal = 100;
  userData = [];
  selectAction: any;
  actionEdit: any;
  userSortBy = 'activityName';
  userOrderBy = 'asc';
  isShowResult = false;
  userLimit = 10;
  userPageIndex = 1;
  modalRef: any;
  userDetail: Users;
  isLoading = true;

  constructor(
    private router: Router,
    private userService: UserService,
    private toastr: ToastrService,
    private modal: NgbActiveModal,
    private modalService: NgbModal,
    private organizationService: OrganizationService
  ) {}

  ngOnInit() {
    this.columns = [
      new ColumnTable('Name', 'name', 'col', 'text', true),
      new ColumnTable('', 'edit', 'col-2 text-right', 'iconEdit', true),
    ];

    this.actionEdit = new ActionModel({
      text: 'Edit',
      icon: 'fas fa-edit',
      callBack: (data) => this.openModal(data),
      class: 'text-warning'
    });

    this.organizationService.getAll().subscribe((res: any[]) => {
      this.userData = res;
      this.userTotal = this.userData.length;
      this.isLoading = false;
    });

  }

  onTableOwnPageChange(event) {
    this.userLimit = event.pageSize;
    this.userPageIndex = event.pageIndex;
  }


  openModal(data: any) {
    const ngbModalOptions: NgbModalOptions = {
      backdrop : 'static',
      keyboard : false,
    };
    this.modalRef = this.modalService.open(
      OrganizationModalComponent, ngbModalOptions
    );

    if (data) {
      this.modalRef.componentInstance.data = data;
      this.modalRef.componentInstance.isUpdate = true;
      this.modalRef.result.then((res: any) => {
      }, (reason) => {
        if (reason === 'Success') {
          this.organizationService.getAll().subscribe((res: any[]) => {
            this.userData = res;
            this.userTotal = this.userData.length;
          });
        }
      });
    } else {
      this.modalRef.result.then((result) => {
      }, (reason) => {
        if (reason === 'Success') {
          this.organizationService.getAll().subscribe((res: any[]) => {
            this.userData = res;
            this.userTotal = this.userData.length;
          });
        }
      });
    }
  }

}



import { MappingSchemaService } from 'src/app/services/mapping-schema.service';
import { UserService } from 'src/app/services/user.service';
import { ToastrService } from 'ngx-toastr';
import { NodeDataService } from './../../../services/node-data.service';
import { Component, OnInit } from '@angular/core';
import { NodeService } from 'src/app/services/node.service';
import { ActivatedRoute } from '@angular/router';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormControl, Validators, AbstractControl, ValidationErrors } from '@angular/forms';
import { Observable } from 'rxjs';
import { debounceTime, map, filter } from 'rxjs/operators';
import { ConfirmDialogComponent } from 'src/app/base-components/dialogs/confirm-dialog/confirm-dialog.component';
import { SettingService } from 'src/app/services/setting.service';

@Component({
  selector: 'app-node-data-add-modal',
  templateUrl: './node-data-add-modal.component.html',
  styleUrls: ['./node-data-add-modal.component.css']
})
export class NodeDataAddModalComponent implements OnInit {

  nodeId: number;
  nodeDataId: number;
  node: any;
  form: FormGroup;
  isReady = false;
  title = 'Add new data';
  isSubmiting = false;
  isView = false;
  detail: any;
  detailCol = [];
  rawData: any;
  mappingSchema: any;
  changeFile = {};
  fileName = {};
  file = [];
  customTitle = '';
  nodeDescription = {};
  mode = '';
  limitFilSizeValue = 0;
  fileInvalid = {};
  constructor(private nodeService: NodeService,
              private activatedRoute: ActivatedRoute,
              private mappingSchemaService: MappingSchemaService,
              private modalService: NgbModal,
              public activeModal: NgbActiveModal,
              private nodeDataService: NodeDataService,
              private toastr: ToastrService,
              private userService: UserService,
              private settingService: SettingService) {
  }

  ngOnInit() {
    this.nodeService.getNodeColumn(this.nodeId).subscribe((res: any[]) => {
      res.forEach(element => {
        this.nodeDescription[element.name] = element.description;
      });
      this.isReady = false;
      const group = {};
      if (this.isView) {
        if (this.mode) {
          this.title = this.customTitle;
          res.forEach(element => {
            const nodeDatail =  this.detail.nodeDataDetail.find(x => x.property === element.name);
            if (nodeDatail) {
              group[nodeDatail.property] = new FormControl(nodeDatail.value);
              this.detailCol.push(nodeDatail.property);
            }
          });
          this.form = new FormGroup(group);
          this.isReady = true;
          this.form.disable();
        } else if (this.mode === 'searchViewDetail') {
          this.title = 'Node data detail';
          if (this.customTitle) {
            this.title = this.customTitle;
          }
          // tslint:disable-next-line:forin
          res.forEach(element => {
              const data = this.detail[element.name];
              group[element.name] = new FormControl(data);
              this.detailCol.push(element.name);
          });
          this.form = new FormGroup(group);
          this.isReady = true;
          this.form.disable();
        } else {
          this.title = 'Node data detail';
          if (this.customTitle) {
            this.title = this.customTitle;
          }
          // tslint:disable-next-line:forin
          res.forEach(element => {
              const data = this.detail[element.name];
              group[element.name] = new FormControl(data);
              this.detailCol.push(element.name);
          });
          this.form = new FormGroup(group);
          this.isReady = true;
          this.form.disable();
        }
      } else {
        this.mappingSchemaService.getBySourceOrDestination(this.nodeId).subscribe(res => {
          this.mappingSchema = res;
        });
        this.nodeService.get(this.nodeId).subscribe(res => {
          this.node = res;
          if (this.nodeDataId) {
            this.title = 'Edit node data';
            this.nodeDataService.get(this.nodeDataId).subscribe((nodeData: any) => {
              this.rawData = nodeData;
              this.node.nodeColumns.forEach(column => {
                const data = nodeData.nodeDataDetail.find(x => x.property === column.name);
                if (data) {
                  if (column.columnType.type === 'Boolean') {
                    group[column.name] = new FormControl(JSON.parse(data.value.toLowerCase()));
                  } else if (column.columnType.type === 'Date') {
                    let dataModel = null;
                    if (data) {
                      const date = data.value.split('/');
                      dataModel = {
                        day: +date[0],
                        month: +date[1],
                        year: +date[2]
                      };
                    }
                    group[column.name] = new FormControl(dataModel);
                  } else if (column.columnType.type === 'Image' || column.columnType.type === 'Binary') {
                    group[column.name] = new FormControl(data.value);
                    group[column.name + 'Source'] = new FormControl('');
                    this.fileName[column.name] = data.value;
                    this.fileInvalid[column.name] = false;
                    this.changeFile[column.name] = false;
                  } else {
                    group[column.name] = new FormControl(data.value);
                  }
                } else {
                  group[column.name] = new FormControl('');
                }

                if (column.isRequire) {
                  group[column.name].setValidators([Validators.required]);
                }

                if (column.isPrimary) {
                  group[column.name].setValidators([Validators.required]);
                  group[column.name].setAsyncValidators(this.validateDuplicate.bind(this));
                }
              });
              this.form = new FormGroup(group);
              this.isReady = true;
            });
          } else {
            this.node.nodeColumns.forEach(column => {
              if (column.columnType.type === 'Boolean') {
                group[column.name] = new FormControl(true);
              } else if (column.columnType.type === 'Image' || column.columnType.type === 'Binary') {
                group[column.name] = new FormControl('');
                group[column.name + 'Source'] = new FormControl('');
                this.fileInvalid[column.name] = false;
                this.changeFile[column.name] = false;
              } else {
                group[column.name] = new FormControl('');
              }

              if (column.isRequire) {
                group[column.name].setValidators([Validators.required]);
              }
              if (column.isPrimary) {
                group[column.name].setValidators([Validators.required]);
                group[column.name].setAsyncValidators(this.validateDuplicate.bind(this));
              }
            });

            this.form = new FormGroup(group);
            this.isReady = true;
          }
        });
      }
    });
    this.settingService.get({name: 'FileSize'}).subscribe((res: any) => {
      if (res) {
        this.limitFilSizeValue = res.value;
      }
    });
  }

  closeModal() {
    this.activeModal.close();
  }

  onSubmit() {
    if (this.form.valid) {
      const columns = [];
      this.node.nodeColumns.forEach(column => {
        const val = this.form.controls[column.name].value;
        if (column.columnType.type === 'Date') {
          const dateString = ('0' + val.day).slice(-2) + '/' + ('0' + val.month).slice(-2) + '/' + val.year;
          columns.push({column: column.name , value: dateString, type: column.columnType.type});
        }  else if (column.columnType.type === 'Image' || column.columnType.type === 'Binary') {
          const fileName = this.fileName[column.name];
          const fileChange = this.changeFile[column.name];
          columns.push({column: column.name , value: fileName,  type: column.columnType.type, isChangeFile: fileChange});
        } else {
          columns.push({column: column.name , value: val, type: column.columnType.type});
        }
      });
      const data = {
        Id: this.nodeId,
        NodeDataId: this.nodeDataId,
        NodeColunmModels: columns,
        RemoveMapping: false
      };
      if (this.nodeDataId) {
        let isChange = false;
        let changeField = '';
        this.mappingSchema.forEach(element => {
          if (+element.nodeSourceId === +this.nodeId) {
            if (element.sourceCondition != null) 
            {
              const sourceCondition = element.sourceCondition.split(',');
              sourceCondition.forEach(con => {
                const sourceNewData = data.NodeColunmModels.find(x => x.column === con);
                const sourceOldData = this.rawData.nodeDataDetail.find(x => x.property === con);
                if (sourceNewData.value !== sourceOldData.value) {
                  isChange = true;
                  changeField += con + ',';
                }
              });
            }  
          } else if (+element.nodeDestinationId === +this.nodeId) {
            if (element.destinationCondition != null) {
              const destinationCondition = element.destinationCondition.split(',');
              destinationCondition.forEach(con => {
                const destinationNewData = data.NodeColunmModels.find(x => x.column === con);
                const destinationOldData = this.rawData.nodeDataDetail.find(x => x.property === con);
                if (destinationNewData.value !== destinationOldData.value) {
                  isChange = true;
                  changeField += con + ',';
                }
              });
            }
          }
        });
        data.RemoveMapping = isChange;
        if (isChange) {
          changeField = changeField.substring(0, changeField.length - 1);
          const modalRef = this.modalService.open(ConfirmDialogComponent);
          modalRef.componentInstance.type = 'custom';
          // tslint:disable-next-line:max-line-length
          modalRef.componentInstance.text = changeField + ' ถูกใช้ใน Semantic mapping หากมีการแก้ไขข้อมูลในยาที่จับคู่ไปแล้ว คู่ยานั้นจะถูกยกเลิก !';
          modalRef.result.then((result) => {
            if (result) {
              this.isSubmiting = true;
              const formData = new FormData();
              this.file.forEach(element => {
                formData.append('Files', element.file);
              });
              formData.append('jsonString', JSON.stringify(data));
              this.nodeDataService.update(formData).subscribe(res => {
                this.toastr.success('Update Success');
                this.activeModal.close(event);
              });
            }
          }, (reason) => {
          });
        } else {
          this.isSubmiting = true;
          const formData = new FormData();
          this.file.forEach(element => {
            formData.append('Files', element.file);
          });
          formData.append('jsonString', JSON.stringify(data));

          this.nodeDataService.update(formData).subscribe(res => {
            this.toastr.success('Update Success');
            this.activeModal.close(event);
          });
        }
      } else {
        const formData = new FormData();
        this.file.forEach(element => {
          formData.append('Files', element.file);
        });
        formData.append('jsonString', JSON.stringify(data));

        this.isSubmiting = true;
        this.nodeDataService.add(formData).subscribe(res => {
          this.toastr.success('Add Success');
          this.activeModal.close(event);
        });
      }
    }
  }

  validateDuplicate(control: AbstractControl): Observable<ValidationErrors | null> {
    const nodeColumns = this.node.nodeColumns.filter(x => x.isPrimary);
    const dataForCheck = [];
    nodeColumns.forEach(column => {
      const form = this.form.controls[column.name];
      dataForCheck.push({
        nodeName: this.node.name,
        property: column.name,
        value: form.value,
        nodeId: this.nodeId,
        isDuplicate: false,
        nodeDataId: this.nodeDataId
      });
    });
    return this.nodeDataService.checkDuplicate(dataForCheck)
      .pipe(debounceTime(500), map((duplicateData: any[]) => {
        const groups = {};
        duplicateData.forEach(x => {
          groups[x.nodeDataId] = groups[x.nodeDataId] || [];
          groups[x.nodeDataId].push(x);
        });
        const groupProps = Object.keys(groups);
        for (const prop of groupProps) {
          if (groups[prop].length === dataForCheck.length) {
            dataForCheck.map(value => value.isDuplicate = false);
            groups[prop].forEach(group => {
              const checkData = dataForCheck.find(x => x.property === group.property);
              if (checkData) {
                checkData.isDuplicate = true;
              }
            });
            if (dataForCheck.every(x => x.isDuplicate)) {
              nodeColumns.forEach(column => {
                this.form.controls[column.name].setErrors({isDuplicate: true});
              });
              return {
                  isDuplicate: true
              };
            }
          }
        }
        nodeColumns.forEach(column => {
          this.form.controls[column.name].setErrors(null);
        });
        return null;
    }));
  }
  getControlName(c: AbstractControl): string | null {
    const formGroup = c.parent.controls;
    return Object.keys(formGroup).find(name => c === formGroup[name]) || null;
  }

  onFileChange(event, controlName) {
    this.fileInvalid[controlName] =  false;
    const target = event.target as HTMLInputElement;
    if (target.files && target.files[0]) {
      const fileSize = target.files[0].size / 1024 / 1024;
      if (fileSize > this.limitFilSizeValue) {
        this.fileInvalid[controlName] =  true;
      } else {
        this.fileName[controlName] = target.files[0].name;
        const file = this.file.find(x => x.name === controlName);
        if (file) {
          const fileIndex = this.file.indexOf(file);
          if (fileIndex !== -1) {
            this.file.splice(fileIndex, 1);
          }
        }
        this.file.push({file: target.files[0], name: controlName});
        this.form.controls[controlName + 'Source'].setValue(target.files[0]);
        this.form.controls[controlName].setValue(target.files[0].name);
      }
    }
  }

  deleteFile(controlName) {
    this.fileName[controlName] = null;
    this.form.controls[controlName + 'Source'].setValue('');
    this.form.controls[controlName].setValue('');
    const file = this.file.find(x => x.name === controlName);
    if (file) {
      const fileIndex = this.file.indexOf(file);
      if (fileIndex !== -1) {
        this.file.splice(fileIndex, 1);
      }
    }
    this.changeFile[controlName] = true;
  }
}

import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { UserService } from 'src/app/services/user.service';
import { AuthService } from 'angularx-social-login';
import { ToastrService } from 'ngx-toastr';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OrganizationService } from 'src/app/services/organization.service';
import { UserFeedbackService } from 'src/app/services/user-feedback.service';
import { NodeService } from 'src/app/services/node.service';
import { MappingNodeService } from 'src/app/services/mapping-node.service';
import { NodeDataService } from 'src/app/services/node-data.service';
import { ColumnTable } from 'src/app/models/column-table/column-table.component';

@Component({
  selector: 'app-mapping-result-modal',
  templateUrl: './mapping-result-modal.component.html',
  styleUrls: ['./mapping-result-modal.component.css']
})
export class MappingResultModalComponent implements OnInit {
  form: FormGroup;
  user: any;
  organizations: any;
  isUpdate = false;
  mappingId = '';
  nodeId = '';
  sourceDetailSelected: any;
  sourceDataDatailSelected: any;
  destinationDataDetailSelected: any;
  destinationDetailSelected: any;
  basicData: any;
  advanceLimit: any;
  advancePageIndex: any;
  isSourceDetailReady: boolean;
  isSourceDetailColumnReady: boolean;
  isSourceDetailDataReady: boolean;
  sourceDetailColumns: any[];
  sourceDetailNode: any;
  sourceDataDetail: any;
  sourceDetailTotal: any;
  showDetail: boolean;
  sourceDetailLimit = 10;
  sourceDetailPageIndex = 1;
  isDestinationDetailReady: boolean;
  isDestinationDetailColumnReady: boolean;
  isDestinationDataDetailReady: boolean;
  destinationDetailColumns: any[];
  destinationDetailNode: any;
  destinationDetailData: any;
  destinationDetailTotal: any;
  destinationDetailLimit = 10;
  destinationDetailPageIndex = 1;
  destinationId: any;
  data: any;
  nodeData: any;
  sourceDetailOrderBy = 'asc';
  sourceDetailSortBy = '';
  destinationDetailOrderBy = 'acs';
  destinationDetailSortBy = '';
  isSplitMapping: boolean = false;

  constructor(
    public modal: NgbActiveModal,
    private nodeService: NodeService,
    private nodeDataService: NodeDataService
  ) {
    this.nodeService.getDropDown().subscribe((res: any) => {
      this.nodeData = res;
    });
  }

  ngOnInit() {
    this.sourceDetailSelected = this.data.SourceId;
    this.sourceDataDatailSelected = this.data.SourceDataId;
    this.destinationDetailSelected = this.data.DestinationId;
    this.destinationDataDetailSelected = this.data.DestinationDataId.split(',');
    this.selectSourceDetail(this.sourceDetailSelected, false);
    this.selectDestinationDetail(this.destinationDetailSelected, false);
  }

  closeModal(event: string) {
    // this.dialogRef.close({event: event, data: data});
    this.modal.dismiss(event);
  }

  onBasicTableOwnPageChange(event, index) {
    this.basicData[index].Limit = event.pageSize;
    this.basicData[index].PageIndex = event.pageIndex;
  }
  onAdvanceTableOwnPageChange(event) {
    this.advanceLimit = event.pageSize;
    this.advancePageIndex = event.pageIndex;
  }

  selectSourceDetail(nodeId, isClear = true) {
    this.sourceDetailSelected = nodeId;
    if (isClear) {
      this.sourceDataDatailSelected = null;
    }
    this.isSourceDetailReady = false;
    this.isSourceDetailColumnReady = false;
    this.isSourceDetailDataReady = false;
    this.sourceDetailColumns = [];
    this.nodeService.get(nodeId).subscribe(nodeRes => {
      this.sourceDetailNode = nodeRes;
      this.isSourceDetailReady = true;
      this.sourceDetailNode.nodeColumns.forEach(element => {
        if (this.sourceDetailSortBy === '') {
          this.sourceDetailSortBy = element.name;
        }
        const col = new ColumnTable(element.name, element.name, 'col', 'node-text', false);
        this.sourceDetailColumns.push(col);
      });
      this.isSourceDetailColumnReady = true;

      const citeria = {
        NodeDataIds: [this.sourceDataDatailSelected],
        Text: '',
        Page: this.sourceDetailPageIndex,
        Limit: this.sourceDetailLimit,
        NodeId: this.sourceDetailSelected,
        OrderBy: this.sourceDetailOrderBy,
        SortBy: this.sourceDetailSortBy,
        SourceId: this.sourceDetailSelected,
        DestinationId: this.destinationDetailSelected
      };
      this.nodeDataService.getData(citeria).subscribe((data: any) => {
        this.sourceDataDetail = data.data;
        this.sourceDetailTotal = data.total;
        this.isSourceDetailDataReady = true;
        this.showDetail = true;
      });
    });
  }

  onSourceDetailTablePageChange(event) {
    this.sourceDetailLimit = event.pageSize;
    this.sourceDetailPageIndex = event.pageIndex;
  }

  selectDestinationDetail(nodeId, isClear = true) {
    this.destinationDetailSelected = nodeId;
    if (isClear) {
      this.destinationDataDetailSelected = [];
    }
    this.isDestinationDetailReady = false;
    this.isDestinationDetailColumnReady = false;
    this.isDestinationDataDetailReady = false;
    this.destinationDetailColumns = [];
    this.nodeService.get(nodeId).subscribe(nodeRes => {
      this.destinationDetailNode = nodeRes;
      this.isDestinationDetailReady = true;
      this.destinationDetailNode.nodeColumns.forEach(element => {
        if (this.destinationDetailSortBy === '') {
          this.destinationDetailSortBy = element.name;
        }
        const col = new ColumnTable(element.name, element.name, 'col', 'node-text', false);
        this.destinationDetailColumns.push(col);
      });
      this.isDestinationDetailColumnReady = true;
      const citeria = {
        NodeDataIds: this.destinationDataDetailSelected,
        Text: '',
        Page: this.destinationDetailPageIndex,
        Limit: this.destinationDetailLimit,
        NodeId: this.destinationDetailSelected,
        OrderBy: this.destinationDetailOrderBy,
        SortBy: this.destinationDetailSortBy,
        SourceId: this.sourceDetailSelected,
        DestinationId: this.destinationDetailSelected
      };
      this.nodeDataService.getData(citeria).subscribe((data: any) => {
        this.destinationDetailData = data.data;
        this.destinationDetailTotal = data.total;
        this.isDestinationDataDetailReady = true;
        this.showDetail = true;
      });
    });
  }

  onDestinationDetailTablePageChange(event) {
    this.destinationDetailLimit = event.pageSize;
    this.destinationDetailPageIndex = event.pageIndex;
  }
}

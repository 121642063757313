import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ContactUsService {

  private actionUrl = 'api/contactUs/';

  constructor(private http: HttpClient) {}

  public getAll<T>(): Observable<T> {
    return this.http.get<T>(this.actionUrl);
  }

  public getUnRead<T>(): Observable<T> {
    return this.http.get<T>(this.actionUrl + 'UnRead');
  }

  public get<T>(id): Observable<T> {
    return this.http.get<T>(this.actionUrl + id);
  }

  public add<T>(data): Observable<T> {
    return this.http.post<T>(this.actionUrl + 'Create', data);
  }

  public forgotPassword<T>(data): Observable<T> {
    return this.http.post<T>(this.actionUrl + 'ForgotPassword', data);
  }

  public update<T>(data): Observable<T> {
    return this.http.post<T>(this.actionUrl + 'Update', data);
  }

  public delete<T>(id): Observable<T> {
    return this.http.delete<T>(this.actionUrl + id);
  }

  public deleteAll<T>(ids): Observable<T> {
    return this.http.post<T>(this.actionUrl + 'DeleteAll', ids);
  }

}

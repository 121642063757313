import { MappingViewdetailComponent } from './../mapping-viewdetail/mapping-viewdetail.component';
import { MappingImportModalComponent } from './../mapping-import-modal/mapping-import-modal.component';
import { MappingNodeService } from './../../../services/mapping-node.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ColumnTable } from 'src/app/models/column-table/column-table.component';
import { ActionModel } from 'src/app/models/table-action-model';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmDialogComponent } from 'src/app/base-components/dialogs/confirm-dialog/confirm-dialog.component';
import { ToastrService } from 'ngx-toastr';
import { LoadingScreenService } from 'src/app/services/loading-screen.service';

import {saveAs as importedSaveAs} from 'file-saver';
import { MappingSuggessModalComponent } from '../mapping-suggess-modal/mapping-suggess-modal.component';
import { RemainingItemModalComponent } from '../remaining-item-modal/remaining-item-modal.component'

@Component({
  selector: 'app-mapping-list',
  templateUrl: './mapping-list.component.html',
  styleUrls: ['./mapping-list.component.css']
})
export class MappingListComponent implements OnInit {
  sourceId: any;
  destinationId: any;
  data: any;
  columns: ColumnTable[];
  total: any;
  limit = 10;
  pageIndex = 1;
  isReady: boolean;
  actions = [];
  source: any;
  destination: any;
  isPaginate: boolean;

  sortBy = 'source';
  orderBy = 'asc';
  isSearch = true;
  isMapping = true;
  searchText = '';
  mappingStatus = '';
  remainingItem = 0;

  constructor(
    private mappingNodeService: MappingNodeService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private modalService: NgbModal,
    private loadingScreenService: LoadingScreenService) {
      this.activatedRoute.queryParams.subscribe(params => {
        this.sourceId = activatedRoute.snapshot.params.source_id;
        this.destinationId = activatedRoute.snapshot.params.destination_id;
      });
    }

  ngOnInit() {
    sessionStorage.setItem('isHome', 'false');
    sessionStorage.setItem('isRedirectSearchFromFile', 'false');
    this.getRemainingItem();
    this.loadData();

    this.columns = [
      new ColumnTable('', '', '', 'checkbox', false),
      new ColumnTable('Source', 'source', 'col', 'source', true, 'source'),
      new ColumnTable('Destination', 'destination', 'col', 'destination', true, 'destination'),
      new ColumnTable('Create At', 'createdAt', 'col', 'createDate', true, 'createdAt'),
      new ColumnTable('Update By', 'updateBy', 'col-2', 'updateBy', true, 'updateBy'),
      new ColumnTable('Update At', 'updatedAt', 'col-2', 'ModifyDate', true, 'updatedAt'),
      new ColumnTable('Status', 'Status', 'col', 'status', true, 'status'),
      new ColumnTable('Mapping Type', 'MappingType', 'col-2', 'MappingTypeApprove', true),
    ];

    this.actions.push(new ActionModel({
      text: 'Edit',
      icon: 'fas fa-edit',
      callBack: (data) => this.edit(data),
      class: 'text-warning',
      type: 'viewDetail'
    }));
    this.actions.push(new ActionModel({
      text: 'Delete',
      icon: 'fas fa-trash',
      callBack: (data) => this.delete(data),
      class: 'text-danger pl-1 pr-2',
      type: 'delete'
    }));
  }

  getRemainingItem() {
    this.mappingNodeService.getRemainingCount(this.sourceId, this.destinationId).subscribe((res:any) => {
      this.remainingItem = res;
    });
  }

  loadData() {
    this.isReady = false;
    const criteria = {
      Text: this.searchText,
      Page: this.pageIndex,
      Limit: this.limit,
      OrderBy: this.orderBy,
      SortBy: this.sortBy,
      SourceId: this.sourceId,
      DestinationId: this.destinationId,
      Status: this.mappingStatus
    };
    this.loadingScreenService.loadingScreen.emit(true);
    this.mappingNodeService.SearchBySourceAndDestination(criteria).subscribe((res: any) => {
      this.data = res.data.data;
      this.source = res.data.source;
      this.destination = res.data.destination;
      this.total = res.total;
      if (this.total > 10) 
      {
        this.isPaginate = true;
      }
      else 
      {
        this.isPaginate = false;
      }
      this.getRemainingItem();
      this.isReady = true;
      this.loadingScreenService.loadingScreen.emit(false);
    });
  }

  delete(data: any) {
    const modalRef = this.modalService.open(ConfirmDialogComponent);
    modalRef.result.then((result) => {
      if (result) {
        this.mappingNodeService.delete(data.id).subscribe(() => {
          this.loadData();
          this.toastr.success('Delete Success');
        });
      }
    }, (reason) => {
    });
  }
  edit(data: any) {
    const ngbModalOptions: NgbModalOptions = {
      backdrop : 'static',
      keyboard : false,
      size: 'lg',
      windowClass: 'waiting-approve-view-detail',
    };
    const modalImport = this.modalService.open(MappingViewdetailComponent, ngbModalOptions);
    modalImport.componentInstance.mappingNodeId = data.id;
    modalImport.result.then((result) => {
      if (result == "update" || result == "delete") {
        this.loadData();
      }
    });
  }

  onTableOwnPageChange(event) {
    this.limit = event.pageSize;
    this.pageIndex = event.pageIndex;
    this.loadData();
  }

  onSorted(event) {
    this.sortBy = event.sortBy;
    this.orderBy = event.orderBy;
    this.loadData();
  }

  onSearch(event) {
    this.pageIndex = 1;
    this.searchText = event.text;
    this.loadData();
  }

  donwloadTemplate() {
    this.mappingNodeService.downloadTemplate(this.sourceId, this.destinationId).subscribe((blob: any) => {
      importedSaveAs(blob, 'MappingTemplate');
    });
  }

  donwloadData() {
    const criteria = {
      Text: this.searchText,
      Page: this.pageIndex,
      Limit: this.limit,
      OrderBy: this.orderBy,
      SortBy: this.sortBy,
      SourceId: this.sourceId,
      DestinationId: this.destinationId,
      IsSuggest: true,
      IsImport: true,
      IsManual: true,
    };

    this.mappingNodeService.downloadBySourceAndDestination(criteria).subscribe((res: any) => {
      importedSaveAs(res, 'Mapping');
    });
  }

  importData() {
    const ngbModalOptions: NgbModalOptions = {
      backdrop : 'static',
      keyboard : false,
    };
    const modalImport = this.modalService.open(MappingImportModalComponent, ngbModalOptions);
    modalImport.componentInstance.sourceId = this.sourceId;
    modalImport.componentInstance.destinationId = this.destinationId;
    modalImport.result.then((result) => {
      if (result == "closeAfterDone") {
        this.loadData();
      }
    });
  }

  newSuggessMapping(){
    this.router.navigateByUrl('mapping-schema/edit/' + this.sourceId + '/' + this.destinationId);
  }

  suggessMapping() {
    const ngbModalOptions: NgbModalOptions = {
      backdrop : 'static',
      keyboard : false,
    };
    const modalImport = this.modalService.open(MappingSuggessModalComponent, ngbModalOptions);
    modalImport.componentInstance.sourceId = this.sourceId;
    modalImport.componentInstance.destinationId = this.destinationId;
    modalImport.result.then((result) => {
      this.loadData();
    });
  }

  onMappinStatusChange(status) {
    this.pageIndex = 1;
    this.mappingStatus = status;
    this.loadData();
  }

  onDeleteAll(event: any[]) {
    const modalRef = this.modalService.open(ConfirmDialogComponent);
    modalRef.componentInstance.text = 'Are you sure to delete notification ' + event.length + ' items ?';
    modalRef.componentInstance.type = 'custom';
    modalRef.result.then((result) => {
      if (result) {
        this.mappingNodeService.deleteAll(event).subscribe(x => {
          this.loadData();
          this.toastr.success('Delete Success');
        });
      }
    }, (reason) => {
    });
  }

  deleteAllPreparation() {
    const approveModel = { SourceId: this.sourceId, DestinationId: this.destinationId };
    const modalRef = this.modalService.open(ConfirmDialogComponent);
    modalRef.componentInstance.text = 'Do you want to delete all preparation items?';
    modalRef.componentInstance.type = 'custom';
    modalRef.result.then((result) => {
      if (result) {
        this.mappingNodeService.deleteAllPreparation(approveModel).subscribe(() => {
          this.loadData();
          this.toastr.success('Delete Success');
        });
      }
    }, (reason) => {
    });
  }

  openRemainingItems(){
    const ngbModalOptions: NgbModalOptions = {
      backdrop : 'static',
      keyboard : false,
      size : 'xl'
    };
    const modalImport = this.modalService.open(RemainingItemModalComponent, ngbModalOptions);
    modalImport.componentInstance.sourceNodeId = this.sourceId;
    modalImport.componentInstance.destinationNodeId = this.destinationId;
    modalImport.result.then((result) => {
    });
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class NodeDataService {
  private actionUrl = 'api/NodeData/';

  constructor(private http: HttpClient) {}

  public getAll<T>(id): Observable<T> {
    return this.http.get<T>(this.actionUrl + id);
  }

  public search<T>(critiria): Observable<T> {
    return this.http.post<T>(this.actionUrl + 'search', critiria);
  }

  public getAllById<T>(id): Observable<T> {
    return this.http.get<T>(this.actionUrl + 'GetNodeById?Id=' + id);
  }

  public getAllByNodeDataId<T>(id, dataId): Observable<T> {
    return this.http.get<T>(this.actionUrl + 'GetNodeByDataId?Id=' + id + '&dataId=' + dataId);
  }

  public getSourceDropdown<T>(datas): Observable<T> {
    return this.http.post<T>(this.actionUrl + 'SourceDropdown', datas);
  }

  public generatePreview<T>(id, nodeDataIds): Observable<T> {
    return this.http.get<T>(this.actionUrl + 'GeneratePreview?Id=' + id + '&nodeDataIds=' + nodeDataIds );
  }

  public GetNodeDataDetails<T>(critiria): Observable<T> {
    return this.http.post<T>(this.actionUrl + 'GetNodeDataDetails', critiria);
  }

  public GetNodeColumns<T>(critiria): Observable<T> {
    return this.http.post<T>(this.actionUrl + 'GetNodeColumns', critiria);
  }

  public searchSource<T>(critiria): Observable<T> {
    return this.http.post<T>(this.actionUrl + 'searchSource', critiria);
  }

  public directQuerySearchSource<T>(sourceId, desId): Observable<T> {
    return this.http.get<T>(this.actionUrl + 'DirectSearchSource?sourceid=' + sourceId + '&destinationid=' + desId);
  }

  public getRemainingSource<T>(sourceId, desId): Observable<T> {
    return this.http.get<T>(this.actionUrl + 'GetRemainingSource?sourceid=' + sourceId + '&destinationid=' + desId);
  }

  public directQuerySearchDestination<T>(sourceId, desId, sourceDataId): Observable<T> {
    return this.http.get<T>(this.actionUrl + 'DirectSearchDestination?sourceid=' + sourceId + '&destinationid=' + desId + '&sourceDataId=' + sourceDataId);
  }

  public searchDestination<T>(critiria): Observable<T> {
    return this.http.post<T>(this.actionUrl + 'searchDestination', critiria);
  }

  public get<T>(nodeId): Observable<T> {
    const data = { Id: nodeId };
    return this.http.post<T>(this.actionUrl + 'GetNodeData', data);
  }
  public checkDuplicate<T>(data): Observable<T> {
    return this.http.post<T>(this.actionUrl + 'CheckDuplicate', data);
  }

  public getData<T>(data): Observable<T> {
    return this.http.post<T>(this.actionUrl + 'getData', data);
  }

  public getDataDestination<T>(data): Observable<T> {
    return this.http.post<T>(this.actionUrl + 'getDataDestination', data);
  }

  public DirectQueryByMappingNodeId<T>(mappingNodeId: number, isGetDataList: boolean = false): Observable<T> {
    let data = { mappingNodeId: mappingNodeId, isGetDataList: isGetDataList };
    return this.http.post<T>(this.actionUrl + 'DirectQueryByMappingNodeId', data);
  }

  public getDataDetail<T>(data): Observable<T> {
    return this.http.post<T>(this.actionUrl + 'getDataDetail', data);
  }

  public add<T>(data): Observable<T> {
    return this.http.post<T>(this.actionUrl, data);
  }

  public update<T>(data): Observable<T> {
    return this.http.post<T>(this.actionUrl + 'Update', data);
  }

  public delete<T>(id): Observable<T> {
    const data = { Id: id };
    return this.http.post<T>(this.actionUrl + 'Delete', data);
  }

  public getTemplate<T>(id): Observable<T> {
    const data = { Id: id };
    return this.http.post<T>(this.actionUrl + 'ExportTemplate', data, {responseType: 'blob' as 'json'});
  }

  public import<T>(data): Observable<T> {
    return this.http.post<T>(this.actionUrl + 'import', data);
  }

  public normalSearch<T>(data): Observable<T> {
    return this.http.post<T>(this.actionUrl + 'normalSearch', data);
  }

  public searchEngine<T>(data): Observable<T> {
    return this.http.post<T>(this.actionUrl + 'SearchEngine', data);
  }

  public searchEngineRelation<T>(data): Observable<T> {
    return this.http.post<T>(this.actionUrl + 'SearchEngineRelation', data);
  }

  public searchgraphRelation<T>(data): Observable<T> {
    return this.http.post<T>(this.actionUrl + 'SearchGraphRelation', data);
  }

  public advanceSearch<T>(data): Observable<T> {
    return this.http.post<T>(this.actionUrl + 'advanceSearch', data);
  }

  public graphSearch<T>(data): Observable<T> {
    return this.http.post<T>(this.actionUrl + 'graphSearch', data);
  }

  public AutoComplete<T>(data): Observable<T> {
    return this.http.post<T>(this.actionUrl + 'AutoComplete', data);
  }

  public excelSearch<T>(data): Observable<T> {
    return this.http.post<T>(this.actionUrl + 'excelSearch', data, {responseType: 'blob' as 'json'});
  }
  public downloadTemplate<T>(data): Observable<T> {
    return this.http.post<T>(this.actionUrl + 'DownloadSearchTemplate', data, {responseType: 'blob' as 'json'});
  }

  public downloadData<T>(criteria): Observable<T> {
    return this.http.post<T>(this.actionUrl + 'DownloadData', criteria, {responseType: 'blob' as 'json'});
  }
}

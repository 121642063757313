import { FilterSearchModalComponent } from './../filter-search-modal/filter-search-modal.component';
import { Component, OnInit, ViewChild } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NodeDataService } from 'src/app/services/node-data.service';
import { NodeService } from 'src/app/services/node.service';
import { ToastrService } from 'ngx-toastr';
import { MappingNodeService } from 'src/app/services/mapping-node.service';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import * as CryptoJS from 'crypto-js';

@Component({
  selector: 'app-basic-search-result',
  templateUrl: './basic-search-result.component.html',
  styleUrls: ['./basic-search-result.component.css']
})
export class BasicSearchResultComponent implements OnInit {
  currentUser: any;
  sub: any;
  criteria: number;
  basicData: any[];
  sources = [];
  normalSearchCriteria: any;
  isReady: boolean;
  encryptSecretKey = 'SearchCriteria';
  resultData: any;
  currentPage: number = 1;
  locationUrl: string = "";
  textCurrentFilter: string = 'All';
  databaseName:any;
  primaryKey:any;
  searchTextIsEmpty: boolean = false;

  filteredOptions: [];

  @ViewChild('tabSet', {static: false}) tabSet;
  constructor(
    private userService: UserService,
    private router: Router,
    private nodeDataService: NodeDataService,
    private nodeService: NodeService,
    private toastr: ToastrService,
    private mappingNodeService: MappingNodeService,
    private modalService: NgbModal,
    private route: ActivatedRoute,
  ) {
    this.userService.currentUser.subscribe(x => this.currentUser = x);
  }

  ngOnInit() {
    sessionStorage.setItem('isHome', 'false');
    sessionStorage.setItem('isRedirectSearchFromFile', 'false');

    this.locationUrl = location.origin;
    this.route.queryParams.subscribe((params: any) => {
        const basicCriteria = this.decryptData(params.param);
        this.nodeService.getDropDown().subscribe((node: any[]) => {
          this.sources = node.filter(x => basicCriteria.Sources.some(s => s === x.id));
          if(this.sources && this.sources.length > 0)
          {
            this.sources.forEach(source => {
              source["isSelected"] = true;
            });
          }

          this.normalSearchCriteria = basicCriteria.Text;
          this.currentPage = 1;
          this.loadData(this.currentPage);
          });
        });
  }

  decryptData(data) {
    try {
      const bytes = CryptoJS.AES.decrypt(data, this.encryptSecretKey);
      if (bytes.toString()) {
        return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      }
      return data;
    } catch (e) {
      // console.log(e);
    }
  }

  search() {
    if (this.normalSearchCriteria.length > 0) {
      this.searchTextIsEmpty = false;
      this.currentPage = 1;
      this.loadData(this.currentPage);
    } else {
      this.searchTextIsEmpty = true;
    }
    
  }

  onTablePageChange(event) {
    this.loadData(event);
  }

  loadData(pageIndex:number, pageSize:number = 10)
  {
    this.resultData = null;
    let nodeIds = this.sources.filter(s=> s.isSelected).map(s=> s.id)

    if(!nodeIds || nodeIds.length == 0)
    {
      this.resultData = {};
      return;
    }

    const criteria = {
      Text: this.normalSearchCriteria,
      Page: pageIndex,
      Limit: pageSize,
      NodeIds: nodeIds,
    };

    this.nodeDataService.searchEngine(criteria).subscribe((res: any) => {
      if(res)
      {
        this.resultData = res;
      }
      else
      {
        this.resultData = {};
      }
    },(err: any)=>{
      this.resultData = {};
    });
  }

  openFilterModal(data: any) {
    let sourcesCopy: any[] ;
    sourcesCopy = JSON.parse(JSON.stringify(this.sources))

    const ngbModalOptions: NgbModalOptions = {
      backdrop : 'static',
      keyboard : false,
      windowClass: 'modal-invite-new-people',
    };

    const modalRef = this.modalService.open(FilterSearchModalComponent, ngbModalOptions);
    modalRef.componentInstance.sources = this.sources;

    modalRef.result.then((result) => {
    }, (reason) => {
      if (reason === 'OK') {
        let listSelectSources =  this.sources.filter(s=> s.isSelected);
        if(listSelectSources.length == this.sources.length){
          this.textCurrentFilter = 'All';
        }
        else if(listSelectSources.length == 0){
          this.textCurrentFilter = '';
        }
        else{
          this.textCurrentFilter = listSelectSources.map(s=> s.name).join(", ");
        }
        this.loadData(1);
      }
      else{
        this.sources = sourcesCopy;
      }
    });
  }

  onGetAutoList(){
    let value = this.normalSearchCriteria;
    if(value.length >= 3){
      let data = {
        "text": value
      };
      this.nodeDataService.AutoComplete(data).subscribe((res: []) => {
        this.filteredOptions = res;
      });
    } else {
      this.filteredOptions = [];
    }
  }

  goToLink(url:string){

    let split_url = url.split('/')
    let dbname = split_url.shift();
    let primarykey = split_url.join('%2F')

    url = encodeURI(this.locationUrl + '/node-detail/' + dbname + '/' + primarykey).replace(/\(/g,'%28').replace(/\)/g,'%29');

    window.open(url,"_blank")

  }
}

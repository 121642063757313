import { UserService } from 'src/app/services/user.service';
import { MappingResultModalComponent } from './../mapping-result-modal/mapping-result-modal.component';
import { filter } from 'rxjs/operators';
import { MappingNodeService } from './../../../services/mapping-node.service';
import { NodeDataService } from 'src/app/services/node-data.service';
import { NodeService } from 'src/app/services/node.service';
import { Component, OnInit } from '@angular/core';
import { ColumnTable } from 'src/app/models/column-table/column-table.component';
import { ActionModel } from 'src/app/models/table-action-model';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmDialogComponent } from 'src/app/base-components/dialogs/confirm-dialog/confirm-dialog.component';
import { Location } from '@angular/common';
import { Ng2CheckboxComponent } from 'src/app/base-components/ng2-smart-table-custom-field/ng2-smart-table-checkbox.component';
import { Ng2RadioComponent } from 'src/app/base-components/ng2-smart-table-custom-field/ng2-smart-table-radio.component';
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';
import { Ng2TooltipComponent } from 'src/app/base-components/ng2-smart-table-custom-field/ng2-smart-table-tooltips.component';

@Component({
  selector: 'app-mapping-manual',
  templateUrl: './mapping-manual.component.html',
  styleUrls: ['./mapping-manual.component.css']
})
export class MappingManualComponent implements OnInit {
  data: any;
  title = 'Mapping Node';
  nodes = [];
  isSourceReady: boolean;
  isSourceColumnReady: boolean;
  isSourceDataReady: boolean;
  sourceNode: any;
  sourceColumns: any;
  sourceData: any;
  sourceSelected = 0;
  sourceDataSelected: any;
  isDestinationReady: boolean;
  isDestinationColumnReady: boolean;
  isDestinationDataReady: boolean;
  destinationColumns: any[];
  destinationNode: any;
  destinationData: any;
  destinationSelected = 0;
  destinationDataSelected = [];
  sourceDataInvalid: boolean;
  destinationDataInvalid: boolean;
  mappingNodeId: any;
  vertical = true;

  disabled = false;
  mode: any;
  isSuggestMapping: any;
  isManualMapping: any;
  isImportMapping: any;
  sourceDisabled = [];
  destinationDisabled = [];
  sourceId: any;
  destinationId: any;

  sourceSetting: object = {};
  destinationSetting: object = {};
  sourceRowValue;
  splitMapping;

  constructor(
    private nodeService: NodeService,
    private nodeDataService: NodeDataService,
    private mappingNodeService: MappingNodeService,
    private activatedRoute: ActivatedRoute,
    private toastr: ToastrService,
    private router: Router,
    public userService: UserService,
    private modalService: NgbModal,
    private location: Location) {
      this.activatedRoute.queryParams.subscribe(params => {
        this.mappingNodeId = activatedRoute.snapshot.params.id;
        this.sourceId = activatedRoute.snapshot.params.sourceId;
        this.destinationId = activatedRoute.snapshot.params.destinationId;
        if (this.mappingNodeId) {
          this.title = 'Edit Mapping Node';
          this.disabled = true;
        }
      });
      this.activatedRoute.data.subscribe(v => this.mode = v.mode);
      this.nodeService.getDropDown().subscribe((resNode: any) => {
        this.nodes = resNode;
        if (this.sourceId && this.destinationId) {
          this.sourceSelected = this.sourceId;
          this.destinationSelected = this.destinationId;
          this.selectSource(this.sourceSelected);
          this.selectDestination(this.destinationSelected);
        }
      });
  }

  ngOnInit() {
    sessionStorage.setItem('isHome', 'false');
    sessionStorage.setItem('isRedirectSearchFromFile', 'false');
  }

  checkAlreadyMapping(data: any){
    if (data.selected == true) {
      let removeSelected = this.sourceData.filter(d=> d.selected)
      if (removeSelected.length > 1){
        removeSelected[0].selected = false;
      }

      this.selectDestination(this.destinationSelected, data.NodeDataId)
    } else {
      this.selectDestination(this.destinationSelected)
      let clr = this.sourceData.filter(r => r.selected)
      clr.forEach(element => {
        element.selected = false;
      });
    }
  }

  selectSource(nodeId) {
    this.isSourceColumnReady = false;
    this.isSourceDataReady = false;
    this.nodeDataService.directQuerySearchSource(this.sourceSelected, this.destinationSelected).subscribe((res: any) => {
      this.sourceData = res.sort((a,b) => (a.NodeDataId > b.NodeDataId ? 1 : ((b.NodeDataId > a.NodeDataId) ? -1 : 0)));
      this.isSourceDataReady = true;
      this.sourceNode = this.nodes.find(x => x.id === +nodeId);

      this.sourceData.forEach(element => {
        element["selected"] = false;
      });
      let column = {};
      column["selected"] = {
        title: '',
        type:'custom',
        renderComponent:Ng2RadioComponent,
        filter: false
      };

      this.sourceNode.nodeColumns.forEach((element) => {
        column[element.name] = {
          title: element.name,
          filter: true,
          type: 'custom',
          renderComponent:Ng2TooltipComponent,
          }
      });

      this.sourceSetting = {
        mode: 'external',
        columns: column,
        actions: {
          add: false,
          edit: false,
          delete: false,
          position: 'right'
        },
        pager: {
          perPage: 10,
          paginateSize: 10
        }
      };
      this.isSourceColumnReady = true;
    });
  }

  selectDestination(nodeId, sourceDataId = "") {
    this.isDestinationColumnReady = false;
    this.isDestinationDataReady = false;
    this.nodeDataService.directQuerySearchDestination(this.sourceSelected, this.destinationSelected, sourceDataId).subscribe((res: any) => {
      this.destinationData = res.sort((a,b) => (a.NodeDataId > b.NodeDataId ? 1 : ((b.NodeDataId > a.NodeDataId) ? -1 : 0)));
      this.isDestinationDataReady = true;
      this.destinationNode = this.nodes.find(x => x.id === +nodeId);

      this.destinationData.forEach(element => {
        element["selected"] = false;
      });
      let column = {};

      column["selected"] = {
        title: '',
        type:'custom',
        renderComponent:Ng2CheckboxComponent,
        filter: false
      };

      this.destinationNode.nodeColumns.forEach((element) => {
        column[element.name] = {
          title: element.name,
          filter: true,
          type: 'custom',
          renderComponent:Ng2TooltipComponent,
          }
      });

      this.destinationSetting = {
        mode: 'external',
        columns: column,
        actions: {
          add: false,
          edit: false,
          delete: false,
          position: 'right'
        },
        pager: {
          perPage: 10,
          paginateSize: 10
        }
      };
      this.isDestinationColumnReady = true;
    });
  }

  create() {
    this.sourceDataInvalid = false;
    this.sourceDataSelected = this.sourceData.filter(d=> d.selected).map(d=> parseInt(d.NodeDataId));
    
    if (this.sourceDataSelected.length === 0) {
      this.sourceDataInvalid = true;
    }
    this.destinationDataInvalid = false;
    this.destinationDataSelected = this.destinationData.filter(d=> d.selected).map(d=> parseInt(d.NodeDataId));
    if (this.destinationDataSelected.length === 0) {
      this.destinationDataInvalid = true;
    }

    if (!this.sourceDataInvalid  && !this.destinationDataInvalid) {
      let sourceCol = this.nodes.find(x => x.id === +this.sourceSelected).nodeColumns.find(x => x.isPrimary === true);
      if (!sourceCol) {
        sourceCol = this.nodes.find(x => x.id === +this.sourceSelected).nodeColumns[0];
      }
      let destinationCol = this.nodes.find(x => x.id === +this.destinationSelected).nodeColumns.find(x => x.isPrimary === true);
      if (!destinationCol) {
        destinationCol = this.nodes.find(x => x.id === +this.destinationSelected).nodeColumns[0];
      }

      let sourceSelectedData = this.sourceData.filter(d=> d.selected);
      // tslint:disable-next-line:max-line-length
      const sourceValue = sourceSelectedData[0][sourceCol.name];

      const data = {
        SourceDataId: this.sourceDataSelected[0],
        SourceId: this.sourceSelected,
        SourceValue: sourceValue,
        DestinationId: this.destinationSelected,
        DestinationDataId: '',
        DestinationValue: '',
        SplitMapping: this.splitMapping
      };
      let destinationDataId = '';
      let destinationValue = '';
      
      let destinationSelectedData = this.destinationData.filter(d => d.selected)
      destinationSelectedData.forEach((element, index, array) => {
        // tslint:disable-next-line:max-line-length
        
        if (index === array.length - 1) {
          destinationDataId += element.NodeDataId;
          destinationValue += element[destinationCol.name];
        } else {
          destinationDataId += element.NodeDataId + ',';
          destinationValue += element[destinationCol.name] + ',';
        }
      });

      data.DestinationDataId = destinationDataId;
      data.DestinationValue = destinationValue;
      this.sourceDataSelected = null;
      this.destinationDataSelected = [];
      const ngbModalOptions: NgbModalOptions = {
        backdrop : 'static',
        keyboard : false,
        size: 'lg'
      };

      const modalRef = this.modalService.open(
        MappingResultModalComponent, ngbModalOptions
      );
      modalRef.componentInstance.data = data;
      modalRef.componentInstance.isSplitMapping = this.splitMapping;
      modalRef.result.then((modalResult: any) => {
        if (modalResult == 'confirm') {
          this.mappingNodeService.add(data).subscribe((res: any) => {
          this.getDisableInvisibalData();
          this.selectSource(this.sourceSelected);
          this.selectDestination(this.destinationSelected);
          this.toastr.success('Create Success');
          });
        }
      }, (reason) => {
        this.getDisableInvisibalData();
        this.selectSource(this.sourceSelected);
        this.selectDestination(this.destinationSelected);
      });    
    }
  }

  update() {
    this.sourceDataInvalid = false;
    if (!this.sourceDataSelected) {
      this.sourceDataInvalid = true;
    }
    this.destinationDataInvalid = false;
    if (this.destinationDataSelected.length === 0) {
      this.destinationDataInvalid = true;
    }
    if (!this.sourceDataInvalid  && !this.destinationDataInvalid) {
      let sourceCol = this.nodes.find(x => x.id === +this.sourceSelected).nodeColumns.find(x => x.isPrimary === true);
      if (!sourceCol) {
        sourceCol = this.nodes.find(x => x.id === +this.sourceSelected).nodeColumns[0];
      }
      let destinationCol = this.nodes.find(x => x.id === +this.destinationSelected).nodeColumns.find(x => x.isPrimary === true);
      if (!destinationCol) {
        destinationCol = this.nodes.find(x => x.id === +this.destinationSelected).nodeColumns[0];
      }

      // tslint:disable-next-line:max-line-length
      const sourceValue = this.sourceData.find(x => x.id === +this.sourceDataSelected).nodeDataDetail.find(x => x.property === sourceCol.name).value;

      const data = {
        Id: this.mappingNodeId,
        SourceDataId: this.sourceDataSelected,
        SourceId: this.sourceSelected,
        SourceValue: sourceValue,
        DestinationId: this.destinationSelected,
        DestinationDataId: '',
        DestinationValue: ''
      };
      let destinationDataId = '';
      let destinationValue = '';
      this.destinationDataSelected.forEach((element, index, array) => {
        // tslint:disable-next-line:max-line-length
        const value = this.destinationData.find(x => x.id === +element).nodeDataDetail.find(x => x.property === destinationCol.name).value;
        if (index === array.length - 1) {
          destinationDataId += element;
          destinationValue += value;
        } else {
          destinationDataId += element + ',';
          destinationValue += value + ',';
        }
      });
      data.DestinationDataId = destinationDataId;
      data.DestinationValue = destinationValue;
      this.mappingNodeService.update(data).subscribe(res => {
        this.toastr.success('Update Success');
        this.router.navigateByUrl('mapping-management/list/' + this.sourceSelected + '/' + this.destinationSelected);
      });
    }
  }

  delete() {
    const modalRef = this.modalService.open(ConfirmDialogComponent);
    modalRef.result.then((result) => {
      if (result) {
        this.mappingNodeService.delete(this.mappingNodeId).subscribe(() => {
          this.toastr.success('Delete Success');
          this.router.navigateByUrl('mapping-management/list/' + this.sourceSelected + '/' + this.destinationSelected);
        });
      }
    }, (reason) => {
    });
  }

  deleteApprove() {
    const modalRef = this.modalService.open(ConfirmDialogComponent);
    modalRef.result.then((result) => {
      if (result) {
        this.mappingNodeService.deleteApprove(this.mappingNodeId).subscribe(() => {
          this.toastr.success('Delete Success');
          this.router.navigateByUrl('mapping-management/list/' + this.sourceSelected + '/' + this.destinationSelected);
        });
      }
    }, (reason) => {
    });
  }

  back() {
    this.location.back();
  }

  approve() {
    const modalRef = this.modalService.open(ConfirmDialogComponent);
    modalRef.componentInstance.type = 'approve';
    modalRef.result.then((result) => {
      if (result) {
        this.mappingNodeService.approve(this.data).subscribe(() => {
          this.toastr.success('Approve Success');
          this.router.navigateByUrl('mapping-waiting-approve/list/' + this.sourceSelected + '/' + this.destinationSelected);
        });
      }
    }, (reason) => {
    });
  }

  reject() {
    const modalRef = this.modalService.open(ConfirmDialogComponent);
    modalRef.componentInstance.type = 'reject';
    modalRef.result.then((result) => {
      if (result) {
        this.mappingNodeService.reject(this.data).subscribe(() => {
          this.toastr.success('Reject Success');
          this.router.navigateByUrl('mapping-waiting-approve/list/' + this.sourceSelected + '/' + this.destinationSelected);
        });
      }
    }, (reason) => {
    });
  }

  getDisableInvisibalData() {
    if (this.sourceSelected && this.sourceSelected !== 0 && this.destinationSelected && this.destinationSelected !== 0) {
      this.mappingNodeService.getDisableInvisibalData(this.sourceSelected, this.destinationSelected).subscribe((res: any) => {
        this.sourceDisabled = res.disableData.map(x => x.sourceDataId);
        this.destinationDisabled = [];
        res.disableData.forEach(element => {
          // element.destinationDataId.split(',').forEach(des => {
          //   this.destinationDisabled.push(+des);
          // });
        });
      });
    }
  }

}

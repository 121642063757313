import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class NodeService {
  private actionUrl = 'api/Node/';

  constructor(private http: HttpClient) {}

  public getAll<T>(): Observable<T> {
    return this.http.get<T>(this.actionUrl);
  }

  public getNodeCount<T>(): Observable<T> {
    return this.http.get<T>(this.actionUrl + 'getNodeCount');
  }

  public getNodeAll<T>(): Observable<T> {
    return this.http.get<T>(this.actionUrl + 'getNodeAll');
  }

  public getDropDown<T>(): Observable<T> {
    return this.http.get<T>(this.actionUrl + 'getDropDown');
  }

  public get<T>(id): Observable<T> {
    return this.http.get<T>(this.actionUrl + id);
  }

  public add<T>(data): Observable<T> {
    return this.http.post<T>(this.actionUrl + 'Create', data);
  }

  public update<T>(data): Observable<T> {
    return this.http.post<T>(this.actionUrl + 'Update', data);
  }

  public delete<T>(id): Observable<T> {
    return this.http.delete<T>(this.actionUrl + id);
  }

  public getNodeColumn<T>(id): Observable<T> {
    return this.http.get<T>(this.actionUrl + 'nodeColumns/' + id);
  }

  public getNodeProperties<T>(id): Observable<T> {
    return this.http.get<T>(this.actionUrl + 'nodeProperty/' + id);
  }


  // tslint:disable-next-line:ban-types
  public checkNameDuplicate<T>(data: object): Observable<Object> {
    return this.http.post<T>(this.actionUrl + 'DuplicateName', data);
  }



}

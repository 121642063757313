import { filter } from 'rxjs/operators';
import { UserFeedbackModalComponent } from './../user-feedback-modal/user-feedback-modal.component';
import { MappingNodeService } from './../../services/mapping-node.service';
import { ToastrService } from 'ngx-toastr';
import { NodeService } from 'src/app/services/node.service';
import { Component, OnInit, AfterContentInit, ViewChild, ElementRef, AfterViewInit, Renderer } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { Router } from '@angular/router';
import { NodeDataService } from 'src/app/services/node-data.service';
import { ColumnTable } from 'src/app/models/column-table/column-table.component';
import { Node, Link } from './../../d3';
import {saveAs as importedSaveAs} from 'file-saver';
import APP_CONFIG from './../../app.config';
import { ActionModel } from 'src/app/models/table-action-model';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { LoadingScreenService } from 'src/app/services/loading-screen.service';
import { LoginComponent } from '../login/login.component';
import { SignUpComponent } from '../sign-up/sign-up.component';
import { SearchFileModalComponent } from './../search-file-modal/search-file-modal.component';
import * as CryptoJS from 'crypto-js';
import {FormControl} from '@angular/forms';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, AfterViewInit {

  // tslint:disable-next-line:ban-types
  currentUser: any;
  basicColumns: ColumnTable[];
  basicData: any[];
  basicTotal = 100;
  basicLimit = 10;
  basicPageIndex = 1;
  normalSearchCriteria = '';
  searchType = false;

  advanceColumns: ColumnTable[];
  advanceData: any[];
  advanceTotal = 100;
  advanceLimit = 10;
  advancePageIndex = 1;

  isSearch = false;


  sourceDropdownList = [];
  sourceSelectedItems = [];
  sourceDropdownSettings = {};
  destinationDropdownList = [];
  destinationSelectedItems = [];
  destinationDropdownSettings = {};

  nodes: Node[] = [];
  links: Link[] = [];

  tableResult = true;

  fileName: string;
  allowFileTypes = ['xlsx'];
  file: File;
  isSuccess = true;
  sourceBasicSelectedItems: any[];
  sourceBasicDropdownSettings: {};

  basicResult = [];
  sourceColumn = [];
  sourceProperties = [];
  selectSourceProperties = [];
  actions = [];
  nodeData: any;
  showDetail = false;

  sourceSelected: any;
  detail: any;
  sourceDetailSelected: any;
  sourceDataDatailSelected: any;
  destinationDataDetailSelected: any;
  destinationDetailSelected: any;
  isSourceDetailReady: boolean;
  isSourceDetailColumnReady: boolean;
  isSourceDetailDataReady: boolean;
  sourceDetailColumns: any[];
  sourceDetailNode: any;
  sourceDataDetail: any;
  sourceDetailTotal: any;
  sourceDetailLimit = 10;
  sourceDetailPageIndex = 1;
  destinationDetailLimit = 10;
  destinationDetailPageIndex = 1;
  isDestinationDetailReady: boolean;
  isDestinationDetailColumnReady: boolean;
  isDestinationDataDetailReady: boolean;
  destinationDetailColumns: any[];
  destinationDetailNode: any;
  destinationDetailData: any;
  destinationDetailTotal: any;

  lables: any[];
  actionsMapping = [];
  errorSelectSourceNode = false;
  errorSelectSourceProperty = false;
  errorSelectDestinationNode: boolean;
  errorSelectDestinationProperty: boolean;
  advanceDataFromGraph: any[];
  tableData: any[];
  errorSelectBasicSourceNode: boolean;
  errorKeyWord: boolean;
  nodeRawData: any;

  encryptSecretKey = 'SearchCriteria';

  @ViewChild('searchTxt', {static: false})
  searchEle: ElementRef;

  searchControl = new FormControl();
  filteredOptions: [];

  constructor(
    private userService: UserService,
    private router: Router,
    private nodeDataService: NodeDataService,
    private nodeService: NodeService,
    private toastr: ToastrService,
    private mappingNodeService: MappingNodeService,
    private modalService: NgbModal,
    private loadingScreenService: LoadingScreenService,
    private renderer: Renderer
  ) {
    this.userService.currentUser.subscribe(x => this.currentUser = x);
  }

  ngAfterViewInit() {
  }

  ngOnInit() {
    sessionStorage.setItem('isHome', 'true');
    sessionStorage.setItem('isRedirectSearchFromFile', 'false');
    sessionStorage.setItem('isRedirectSearch', 'true');

    this.nodeService.getDropDown().subscribe((res: any) => {
      this.nodeData = res;
    });
    this.basicColumns = [
      new ColumnTable('#', 'name', 'col-1', 'index', true),
      new ColumnTable('Detail', 'detail', 'col', 'detail', true),
    ];
    this.advanceColumns = [
      new ColumnTable('Source', 'nodeSource', 'col', 'sourceData', true),
      new ColumnTable('Destination', 'nodeDestination', 'col', 'destinationData', true),
      new ColumnTable('Create Date', 'CreateDate', 'col', 'createDate', true),
    ];

    this.actions.push(new ActionModel({
      text: 'Edit',
      icon: 'fas fa-edit',
      callBack: (data) => this.viewDetail(data),
      class: 'text-warning',
      type: 'viewDetail'
    }));

    this.actionsMapping.push(new ActionModel({
      callBack: (data) => this.feedback(data),
      type: 'message'
    }));

    this.nodeService.getDropDown().subscribe((res: []) => {
      this.nodeRawData = res;
      this.sourceDropdownList = res;
      this.destinationDropdownList = res;
      this.sourceBasicSelectedItems = this.sourceDropdownList;
    });
    this.sourceSelectedItems = [];
    this.sourceDropdownSettings = {
      singleSelection: true,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
    this.destinationSelectedItems = [];
    this.destinationDropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };

    this.sourceBasicDropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'เลือกทั้งหมด',
      unSelectAllText: 'ยกเลิกทั้งหมด',
      itemsShowLimit: 3,
      allowSearchFilter: true,
    };
  }

  onGetAutoList(){
    let value = this.searchControl.value
    if(value.length >= 3){
      let data = {
        "text": value
      }
      this.nodeDataService.AutoComplete(data).subscribe((res: []) => {
        this.filteredOptions = res
      });
    } else {
      this.filteredOptions = []
    }
  }

  feedback(data: any) {
    const ngbModalOptions: NgbModalOptions = {
      backdrop : 'static',
      keyboard : false,
    };
    const modalRef = this.modalService.open(
      UserFeedbackModalComponent, ngbModalOptions
    );
    modalRef.componentInstance.sourceId = this.detail.SourceId;
    modalRef.componentInstance.destinationId = this.detail.DestinationId;
    modalRef.componentInstance.sourceNodeDataId = this.detail.SourceNodeDataId;
    modalRef.componentInstance.destinationNodeDataId = data.id;
    modalRef.result.then((res: any) => {
      this.toastr.success('Send contact successful');
    }, (reason) => {
    });

  }

  viewDetail(data: any) {
    this.detail = data;
    this.sourceDetailSelected = data.SourceId;
    this.sourceDataDatailSelected = data.SourceNodeDataId;
    this.destinationDataDetailSelected = data.DestinationNodeDataId;
    this.selectSourceDetail(this.sourceDetailSelected, false);
    this.destinationDetailSelected = data.DestinationId;
    this.selectDestinationDetail(this.destinationDetailSelected, false);
  }

  onBasicTableOwnPageChange(event, index) {
    this.basicData[index].Limit = event.pageSize;
    this.basicData[index].PageIndex = event.pageIndex;
  }
  onAdvanceTableOwnPageChange(event) {
    this.advanceLimit = event.pageSize;
    this.advancePageIndex = event.pageIndex;
  }

  onSearch() {
    this.errorSelectSourceNode = false;
    this.errorSelectSourceProperty = false;
    this.errorSelectDestinationNode = false;
    this.errorSelectDestinationProperty = false;
    this.errorKeyWord = false;
    if (this.sourceSelectedItems.length === 0) {
      this.errorSelectSourceNode = true;
    } else if (this.selectSourceProperties.length === 0) {
      this.errorSelectSourceProperty = true;
    }

    if (this.destinationSelectedItems.length === 0) {
      this.errorSelectDestinationNode = true;
    } else if (this.destinationSelectedItems.some(x => x.selectedProperties.length === 0)) {
      this.errorSelectDestinationProperty = true;
    }

    if (this.normalSearchCriteria === '') {
      this.errorKeyWord = true;
    }
    this.showDetail = false;
    if (!this.errorKeyWord) {
      if (this.searchType) {
        // tslint:disable-next-line:max-line-length
        if (!this.errorSelectSourceNode && !this.errorSelectSourceProperty && !this.errorSelectDestinationNode && !this.errorSelectDestinationProperty) {
          const searchCriteria = {
            Text: this.normalSearchCriteria,
            Source: this.sourceSelectedItems[0],
            Destinations: this.destinationSelectedItems.map(s => ({id: s.id, selectedProperties: s.selectedProperties})),
            SourceSelectedProperties: this.selectSourceProperties
          };
          const paramEncrypt = this.encryptData(searchCriteria);
          this.router.navigate(['/advance-search-result'], { queryParams: { param: paramEncrypt } });
        }
      } else {
        this.errorSelectBasicSourceNode = false;
        if (this.sourceBasicSelectedItems.length === 0) {
          this.errorSelectBasicSourceNode = true;
        }
        if (!this.errorSelectBasicSourceNode) {
          const searchCriteria = {
            Text: this.normalSearchCriteria,
            Sources: this.sourceDropdownList.filter(x => this.sourceBasicSelectedItems.find(s => s.id === x.id)).map(s => s.id),
          };
          const paramEncrypt = this.encryptData(searchCriteria);
          this.router.navigate(['/basic-search-result'], { queryParams: { param: paramEncrypt } });
        }
      }
    }
  }

  encryptData(data) {
    try {
      return CryptoJS.AES.encrypt(JSON.stringify(data), this.encryptSecretKey).toString();
    } catch (e) {
      // console.log(e);
    }
  }

  onItemSelect(item: any) {
    if (this.sourceBasicSelectedItems.length > 0) {
      this.errorSelectBasicSourceNode = false;
    }
  }

  onSelectAll(items: any) {
    this.errorSelectBasicSourceNode = false;
  }

  onSourceItemSelect(item: any) {
    this.errorSelectSourceNode = false;
    this.selectSourceProperties = [];
    this.nodeService.getNodeProperties(item.id).subscribe((res: []) => {
      this.sourceProperties = res;
      const propName =  this.sourceProperties.map(x => x.name);
      this.selectSourceProperties = propName;
    });
    this.destinationDropdownList = this.nodeRawData.filter(x => x.id !== item.id);
    //this.destinationSelectedItems = this.destinationDropdownList;
  }

  ononSourceItemDeSelect(item: any) {
    this.sourceProperties = [];
  }

  sourcePropertySelected(event, value) {
    this.errorSelectSourceProperty = false;
    if (event.target.checked) {
      this.selectSourceProperties.push(value);
    } else {
      // tslint:disable-next-line:variable-name
      const index_remove = this.selectSourceProperties.findIndex(ele => {
        return ele === value;
      });
      this.selectSourceProperties.splice(index_remove, 1);
    }
    this.selectSourceProperties.sort((a, b) => {
      const indexA = this.sourceProperties.findIndex(x => x.name === a);
      const indexB = this.sourceProperties.findIndex(x => x.name === b);
      if (indexA < indexB) {
       return -1;
      } else if (indexA > indexB) {
        return 1;
      }
      return 0;
    });
  }

  sourcePropertySelectedAll(event) {
    this.errorSelectSourceProperty = false;
    if (event.target.checked) {
      this.sourceProperties.forEach(element => {
        if (this.selectSourceProperties.filter(x => x === element.name).length === 0) {
          this.selectSourceProperties.push(element.name);
        }
      });
    } else {
      this.sourceProperties.forEach(element => {
        if (this.selectSourceProperties.filter(x => x === element.name).length > 0) {
          // tslint:disable-next-line:variable-name
          const index_remove = this.selectSourceProperties.findIndex(ele => {
            return ele === element.name;
          });
          this.selectSourceProperties.splice(index_remove, 1);
        }
      });
    }
    this.selectSourceProperties.sort((a, b) => {
      const indexA = this.sourceProperties.findIndex(x => x.name === a);
      const indexB = this.sourceProperties.findIndex(x => x.name === b);
      if (indexA < indexB) {
       return -1;
      } else if (indexA > indexB) {
        return 1;
      }
      return 0;
    });
  }

  onDestinationItemSelect(item: any) {

  }

  onDestinationSelectAll(items: any) {

  }

  onDestinationItemDeSelect(item: any) {

  }

  destinationSelectedChange() {
    this.errorSelectDestinationNode = false;
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.destinationSelectedItems.length; i++) {
      if (!this.destinationSelectedItems[i].properties) {
        this.destinationSelectedItems[i].selectedProperties = [];
        this.nodeService.getNodeProperties(this.destinationSelectedItems[i].id).subscribe((res: any[]) => {
          this.destinationSelectedItems[i].properties = res;
          const propName = res.map(x => x.name);
          //this.destinationSelectedItems[i].selectedProperties = propName;
        });
      }
    }
  }

  destinationPropertySelected(event, value, index) {
    this.errorSelectDestinationProperty = false;
    if (event.target.checked) {
      this.destinationSelectedItems[index].selectedProperties.push(value);
    } else {
      // tslint:disable-next-line:variable-name
      const index_remove = this.destinationSelectedItems[index].selectedProperties.findIndex(ele => {
        return ele === value;
      });
      this.destinationSelectedItems[index].selectedProperties.splice(index_remove, 1);
    }
    this.destinationSelectedItems[index].selectedProperties.sort((a, b) => {
      const indexA = this.destinationSelectedItems[index].properties.findIndex(x => x.name === a);
      const indexB = this.destinationSelectedItems[index].properties.findIndex(x => x.name === b);
      if (indexA < indexB) {
       return -1;
      } else if (indexA > indexB) {
        return 1;
      }
      return 0;
    });
  }

  destinationPropertySelectedAll(event, index) {
    this.errorSelectDestinationProperty = false;
    if (event.target.checked) {
      this.destinationSelectedItems[index].properties.forEach(element => {
        if (this.destinationSelectedItems[index].selectedProperties.filter(x => x === element.name).length === 0) {
          this.destinationSelectedItems[index].selectedProperties.push(element.name);
        }
      });
    } else {
      this.destinationSelectedItems[index].properties.forEach(element => {
        if (this.destinationSelectedItems[index].selectedProperties.filter(x => x === element.name).length > 0) {
          // tslint:disable-next-line:variable-name
          const index_remove = this.destinationSelectedItems[index].selectedProperties.findIndex(ele => {
            return ele === element.name;
          });
          this.destinationSelectedItems[index].selectedProperties.splice(index_remove, 1);
        }
      });
    }
    this.destinationSelectedItems[index].selectedProperties.sort((a, b) => {
      const indexA = this.destinationSelectedItems[index].properties.findIndex(x => x.name === a);
      const indexB = this.destinationSelectedItems[index].properties.findIndex(x => x.name === b);
      if (indexA < indexB) {
       return -1;
      } else if (indexA > indexB) {
        return 1;
      }
      return 0;
    });
  }

  initGraph() {
    const N = APP_CONFIG.N;
    this.nodes = [];
    this.links = [];
    this.lables = [];
    this.tableData = [];
    this.loadingScreenService.loadingScreen.emit(true);
    const searchCriteria = {
      Text: this.normalSearchCriteria,
      Source: this.sourceSelectedItems[0],
      Destinations: this.destinationSelectedItems,
      SourceSelectedProperties: this.selectSourceProperties
    };
    this.nodeDataService.graphSearch(searchCriteria).subscribe((res: any) => {
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < res.ids.length; i++) {
        let source = null;
        let destination = null;
        const sourceNodeDataId = res.dataList[i][0].NodeDataId[0];
        const destinationNodeDataId = res.dataList[i][res.dataList[i].length - 1].NodeDataId[0];
        const edge = res.linkedList.find(x => x.SourceId === sourceNodeDataId);
        const sourceName = res.lables[i][0];
        const destinationName = res.lables[i][res.lables[i].length - 1];
        let sourceValue = res.dataList[i][0];
        const destinationValue = res.dataList[i][res.dataList[i].length - 1];
        const createDate = edge ? edge.Create : null;
        // tslint:disable-next-line:max-line-length
        const duplicateData = this.tableData.find(x => x.SourceName === sourceName && x.DestinationName === destinationName && x.SourceNodeDataId === sourceNodeDataId);
        if (duplicateData) {
          const index = this.tableData.indexOf(duplicateData);
          this.tableData[index].DestinationValue.push(destinationValue);
          this.tableData[index].DestinationNodeDataId.push(destinationNodeDataId);
        } else {
          const sourceNode = this.sourceDropdownList.find(x => x.name === sourceName);
          let sourceCol = sourceNode.nodeColumns.find(x => x.isPrimary);
          if (!sourceCol) {
            sourceCol = sourceNode.nodeColumns[0];
          }
          const destinationNode = this.sourceDropdownList.find(x => x.name === destinationName);
          let destinationCol = destinationNode.nodeColumns.find(x => x.isPrimary);
          if (!destinationCol) {
            destinationCol = destinationNode.nodeColumns[0];
          }
          this.tableData.push({
            SourceId: sourceNode.id,
            SourceName: sourceName,
            DestinationId: destinationNode.id,
            DestinationName: destinationName,
            SourceValue: sourceValue,
            SourceNodeDataId: sourceNodeDataId,
            SourceCol: sourceCol.name,
            DestinationValue: [destinationValue],
            DestinationNodeDataId: [destinationNodeDataId],
            DestinationCol: destinationCol.name,
            CreateDate: createDate
          });
        }
        // tslint:disable-next-line:prefer-for-of
        for (let r = 0; r < res.ids[i].length; r++) {
          const lableName = res.lables[i][r];
          const sourceNode = this.sourceDropdownList.find(x => x.name === lableName);
          let sourceCol = sourceNode.nodeColumns.find(x => x.isPrimary);
          if (!sourceCol) {
            sourceCol = sourceNode.nodeColumns[0];
          }

          sourceValue = res.dataList[i][r][sourceCol.name];

          const duplicateLable = this.lables.find(x => x.lable === lableName);
          let lableObj = {
            lable: lableName,
            color: this.getRandomColor()
          };

          if (duplicateLable == null) {
            this.lables.push(lableObj);
          } else {
            lableObj = duplicateLable;
          }
          if (source == null) {
            const display = lableName + ': ' + sourceValue;
            source = new Node(res.ids[i][r].id, res.dataList[i][r], res.lables[i][r], lableObj.color, display, sourceNode.id);
            const duplicateSource = this.nodes.find(x => x.id === res.ids[i][r].id);
            if (duplicateSource == null) {
              this.nodes.push(source);
            } else {
              source = duplicateSource;
            }
          } else {
            const display = lableName + ': ' + sourceValue;
            destination = new Node(res.ids[i][r].id, res.dataList[i][r], res.lables[i][r], lableObj.color, display, sourceNode.id);
            const duplicateDestination = this.nodes.find(x => x.id === res.ids[i][r].id);
            if (duplicateDestination == null) {
              this.nodes.push(destination);
            } else {
              destination = duplicateDestination;
            }
            this.links.push(new Link(source, destination));
            source = destination;
          }
        }
      }
      this.advanceTotal = this.tableData.length;
      this.isSearch = true;
      this.loadingScreenService.loadingScreen.emit(false);
    });
  }

  onFileChange(event) {
    const target = event.target as HTMLInputElement;
    if (target.files && target.files[0]) {
      if (!this.validateFile(target.files[0].name)) {
        this.toastr.error('Selected file format is not supported');
        return false;
      }
      this.file = target.files[0];
      this.fileName = target.files[0].name;
    }
  }

  onDownloadResult() {
    if (this.file) {
      const data = new FormData();
      data.append('File', this.file);
      this.isSuccess = false;
      this.nodeDataService.excelSearch(data).subscribe((res: any) => {
        this.toastr.success('Batch search success.');
        this.isSuccess = true;
        importedSaveAs(res, 'MappingTemplate');
      }, error => {
        this.toastr.error(error.error);
      });
    }
  }

  onDownloadTemplate() {
    this.errorSelectSourceNode = false;
    this.errorSelectSourceProperty = false;
    this.errorSelectDestinationNode = false;
    this.errorSelectDestinationProperty = false;
    if (this.sourceSelectedItems.length === 0) {
      this.errorSelectSourceNode = true;
    } else if (this.selectSourceProperties.length === 0) {
      this.errorSelectSourceProperty = true;
    }

    if (this.destinationSelectedItems.length === 0) {
      this.errorSelectDestinationNode = true;
    } else if (this.destinationSelectedItems.some(x => x.selectedProperties.length === 0)) {
      this.errorSelectDestinationProperty = true;
    }

    // tslint:disable-next-line:max-line-length
    if (!this.errorSelectSourceNode && !this.errorSelectSourceProperty && !this.errorSelectDestinationNode && !this.errorSelectDestinationProperty) {
      const searchCriteria = {
        Text: this.normalSearchCriteria,
        Source: this.sourceSelectedItems[0],
        SourceSelectedProperties: this.selectSourceProperties,
        Destinations: this.destinationSelectedItems
      };
      this.nodeDataService.downloadTemplate(searchCriteria).subscribe((res: any) => {
        importedSaveAs(res, 'Template');
      }, error => {
        this.toastr.error(error.error);
      });
    }
  }

  validateFile(name) {
    const ext = name.substring(name.lastIndexOf('.') + 1);
    if (this.allowFileTypes.indexOf(ext.toLowerCase()) > -1) {
        return true;
    } else {
        return false;
    }
  }

  selectSourceDetail(nodeId, isClear = true) {
    this.sourceDetailSelected = nodeId;
    if (isClear) {
      this.sourceDataDatailSelected = null;
    }
    this.isSourceDetailReady = false;
    this.isSourceDetailColumnReady = false;
    this.isSourceDetailDataReady = false;
    this.sourceDetailColumns = [];
    this.nodeService.get(nodeId).subscribe(nodeRes => {
      this.sourceDetailNode = nodeRes;
      this.isSourceDetailReady = true;
      this.sourceDetailNode.nodeColumns.forEach(element => {
        if (this.selectSourceProperties.find(x => x === element.name)) {
          const col = new ColumnTable(element.name, element.name, 'col', 'node-text', false);
          this.sourceDetailColumns.push(col);
        }
      });
      this.isSourceDetailColumnReady = true;
      const citeria = {
        id: nodeId,
        nodeDataIds: [this.sourceDataDatailSelected]
      };
      this.nodeDataService.getDataDetail(citeria).subscribe((data: any) => {
        this.sourceDataDetail = data;
        this.sourceDetailTotal = this.sourceDataDetail.length;
        this.isSourceDetailDataReady = true;
        this.showDetail = true;
      });
    });
  }

  onSourceDetailTablePageChange(event) {
    this.sourceDetailLimit = event.pageSize;
    this.sourceDetailPageIndex = event.pageIndex;
  }

  selectDestinationDetail(nodeId, isClear = true) {
    this.destinationDetailSelected = nodeId;
    if (isClear) {
      this.destinationDataDetailSelected = [];
    }
    this.isDestinationDetailReady = false;
    this.isDestinationDetailColumnReady = false;
    this.isDestinationDataDetailReady = false;
    this.destinationDetailColumns = [];
    this.nodeService.get(nodeId).subscribe((nodeRes: any) => {
      this.destinationDetailNode = nodeRes;
      this.isDestinationDetailReady = true;
      this.destinationDetailNode.nodeColumns.forEach(element => {
        const destinationSelectedItem = this.destinationSelectedItems.find(x => x.name === nodeRes.name);
        if (destinationSelectedItem.selectedProperties.find(x => x === element.name)) {
          const col = new ColumnTable(element.name, element.name, 'col', 'node-text', false);
          this.destinationDetailColumns.push(col);
        }
      });
      this.isDestinationDetailColumnReady = true;
    });
    const citeria = {
      id: nodeId,
      nodeDataIds: this.destinationDataDetailSelected
    };
    this.nodeDataService.getDataDetail(citeria).subscribe((data: any) => {
      this.destinationDetailData = data;
      this.destinationDetailTotal = this.destinationDetailData.length;
      this.isDestinationDataDetailReady = true;
      this.showDetail = true;
    });
  }

  onDestinationDetailTablePageChange(event) {
    this.destinationDetailLimit = event.pageSize;
    this.destinationDetailPageIndex = event.pageIndex;
  }

  getRandomColor() {
    const color = Math.floor(0x1000000 * Math.random()).toString(16);
    return '#' + ('000000' + color).slice(-6);
  }

  // signIn() {
  //   const ngbModalOptions: NgbModalOptions = {
  //     backdrop : true,
  //     keyboard : false,
  //   };
  //   const modalRef = this.modalService.open(
  //     LoginComponent, ngbModalOptions
  //   );
  //   modalRef.result.then((result) => {
  //   }, (reason) => {
  //     if (reason === 'Success') {
  //       this.userService.getAll().subscribe(res => {
  //       });
  //     }
  //   });
 //}
  // signUp() {
  //   const ngbModalOptions: NgbModalOptions = {
  //     backdrop : true,
  //     keyboard : false,
  //   };
  //   const modalRef = this.modalService.open(
  //     SignUpComponent, ngbModalOptions
  //   );
  //   modalRef.result.then((result) => {
  //   }, (reason) => {
  //     if (reason === 'Success') {
  //       this.userService.getAll().subscribe(res => {
  //       });
  //     }
  //   });
  // }

  // searchFromFile() {
  //   const ngbModalOptions: NgbModalOptions = {
  //     backdrop : true,
  //     keyboard : false,
  //   };
  //   const modalRef = this.modalService.open(
  //     SearchFileModalComponent, ngbModalOptions
  //   );
  //   modalRef.result.then((result) => {
  //   }, (reason) => {
  //     if (reason === 'Success') {
  //       this.userService.getAll().subscribe(res => {
  //       });
  //     }
  //   });
  // }

  // redirectSearchFromFile(){
  //   sessionStorage.setItem('isRedirectSearchFromFile', 'true');
  // }
}

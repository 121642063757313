import { MappingSchemaService } from './../../../services/mapping-schema.service';
import { NodeService } from './../../../services/node.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup, FormArray, AbstractControl, ValidationErrors } from '@angular/forms';
import { AuthService } from 'angularx-social-login';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { ColumnTypeService } from 'src/app/services/columnType.service';
import { Observable } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { UserService } from 'src/app/services/user.service';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmDialogComponent } from 'src/app/base-components/dialogs/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-node-add',
  templateUrl: './node-add.component.html',
  styleUrls: ['./node-add.component.scss']
})
export class NodeAddComponent implements OnInit {

  form: FormGroup;
  columns: any;
  columnTypes: any[];
  nodeId: any;
  title = 'New data node';
  isUpdate = false;
  mappingSchema: any;
  rawData: any;
  hasPrimary:boolean = true;

  constructor(private fb: FormBuilder,
              private toastr: ToastrService,
              private columnTypeService: ColumnTypeService,
              private nodeService: NodeService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              public userService: UserService,
              private mappingSchemaService: MappingSchemaService,
              private modalService: NgbModal) {
    this.activatedRoute.queryParams.subscribe(params => {
      this.nodeId = activatedRoute.snapshot.params.id;
      if (this.nodeId) {
        this.title = 'Edit data node';
        this.isUpdate = true;
      }
    });
  }

  ngOnInit() {
    sessionStorage.setItem('isHome', 'false');
    sessionStorage.setItem('isRedirectSearchFromFile', 'false');

    this.form = this.fb.group({
      Id : [0, Validators.required],
      Name: ['', Validators.required, this.validateName.bind(this)],
      Info: [''],
      NodeColumns: this.fb.array([], Validators.required),
    });
    if (this.nodeId) {
      this.mappingSchemaService.getBySourceOrDestination(this.nodeId).subscribe(res => {
        this.mappingSchema = res;
      });
      this.nodeService.get(this.nodeId).subscribe((res: any) => {
        this.rawData = res;
        this.form.patchValue({
          Id : res.id,
          Name: res.name,
          Info: res.info
        });
        res.nodeColumns.forEach((column, index) => {
          this.addColumn(column, index);
        });

        if (!this.userService.checkRole(['Admin'])) {
          this.form.disable();
        }
      });
    } else {
      this.addColumn(null, 0);
    }

    this.initalDDL();
  }

  initalDDL() {
    this.columnTypeService.getAll().subscribe((res: []) => {
      this.columnTypes = res;
    });
  }

  addColumn(data?, index?): void {
    this.columns = this.form.get('NodeColumns') as FormArray;
    this.columns.push(this.createColumn(data, index));
  }

  up(index) {
    this.columns = this.form.get('NodeColumns') as FormArray;
    const temp = this.columns.controls[index];
    this.columns.controls[index] = this.columns.controls[index - 1];
    this.columns.controls[index - 1] = temp;
    this.resetIndex();
  }

  down(index) {
    this.columns = this.form.get('NodeColumns') as FormArray;
    const temp = this.columns.controls[index];
    this.columns.controls[index] = this.columns.controls[index + 1];
    this.columns.controls[index + 1] = temp;
    this.resetIndex();
  }

  removeColumn(index) {
    this.columns.removeAt(index);
    this.resetIndex();
  }

  resetIndex() {
    this.columns = this.form.get('NodeColumns') as FormArray;
    this.columns.controls.forEach((element, index) => {
      element.patchValue({
        Index: index
      });
    });
  }

  createColumn(data?, index?): FormGroup {
    if (data) {
      return this.fb.group({
        Id: [data.id, [Validators.required]],
        Name: [data.name, [Validators.required, RxwebValidators.unique()]],
        Description: [data.description],
        ColumnTypeId: [data.columnTypeId, [Validators.required]],
        Index: [data.index],
        IsPrimary: [data.isPrimary],
        IsRequire: [data.isRequire],
      });
    } else {
      return this.fb.group({
        Name: ['', [Validators.required]],
        ColumnTypeId: ['', [Validators.required]],
        Description: [''],
        Index: [index],
        IsPrimary: [false],
        IsRequire: [false],
      });
    }
  }

  onSubmit() {

    this.columns = this.form.get('NodeColumns') as FormArray;
    let isPrimary = false;
    this.columns.controls.forEach(element => {
      if (element.value.IsPrimary) {
        isPrimary = true;
      }
    });

    if (isPrimary) {
      this.hasPrimary = true;
      if (this.nodeId) {
        if (this.form.valid) {
          const data = this.form.value;
          let isChange = false;
          let changeField = '';
          const removeSchema = [];
          this.mappingSchema.forEach(element => {
            if (+element.nodeSourceId === +this.nodeId && element.sourceCondition != null) {
              const sourceCondition = element.sourceCondition.split(',');
              sourceCondition.forEach(con => {
                const sourceCol = data.NodeColumns.find(x => x.Name === con);
                if (!sourceCol) {
                  isChange = true;
                  changeField += con + ',';
                  removeSchema.push(con);
                }
              });
            } else if (+element.nodeDestinationId === +this.nodeId && element.destinationCondition != null) {
              const destinationCondition = element.destinationCondition.split(',');
              destinationCondition.forEach(con => {
                const destinationCol = data.NodeColumns.find(x => x.Name === con);
                if (!destinationCol) {
                  isChange = true;
                  changeField += con + ',';
                  removeSchema.push(con);
                }
              });
            }
          });
          data.RemoveMapping = isChange;
          data.RemoveSchema = removeSchema;
          if (isChange) {
            changeField = changeField.substring(0, changeField.length - 1);
            const modalRef = this.modalService.open(ConfirmDialogComponent);
            modalRef.componentInstance.type = 'custom';
            // tslint:disable-next-line:max-line-length
            modalRef.componentInstance.text = changeField + ' ถูกใช้ใน Semantic mapping หากมีการแก้ไขข้อมูลในยาที่จับคู่ไปแล้ว คู่ยานั้นจะถูกยกเลิก !';
            modalRef.result.then((result) => {
              if (result) {
                this.nodeService.update(data).subscribe(res => {
                  this.toastr.success('Update Success');
                  this.router.navigateByUrl('node-management');
                });
              }
            }, (reason) => {
            });
          } else {
            this.nodeService.update(this.form.value).subscribe(res => {
              this.toastr.success('Update Success');
              this.router.navigateByUrl('node-management');
            });
          }
        }
      } else {
        if (this.form.valid) {
          this.nodeService.add(this.form.value).subscribe(res => {
            this.toastr.success('Add Success');
            this.router.navigateByUrl('node-management');
          });
        }
      }
    } else {
      this.hasPrimary = false;
    }
  }

  onChange(e, index) {
    if (e.target.checked) {
      const optionString = this.columnTypes.find(x => x.type === 'String');
      this.columns = this.form.get('NodeColumns') as FormArray;
      this.columns.controls[index].patchValue({
        ColumnTypeId: optionString.id
      });
    }
  }

  validateName({value}: AbstractControl): Observable<ValidationErrors | null> {
    const data = {
      Name: value
    };

    return this.nodeService.checkNameDuplicate(data)
      .pipe(debounceTime(500), map((emailDuplicate: boolean) => {
        if ( this.isUpdate ) {
          return null;
        }

        if (emailDuplicate) {
            return {
                isDuplicate: true
            };
        }
        return null;
    }));
  }

}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SettingService {

  private actionUrl = 'api/Setting/';

  constructor(private http: HttpClient) {}

  public get<T>(name): Observable<T> {
    return this.http.post<T>(this.actionUrl + 'GetSetting', name);
  }

  public create<T>(data): Observable<T> {
    return this.http.post<T>(this.actionUrl, data);
  }

}

import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[noneSpacial]'
})
export class NoneSpacialDirective {

  // tslint:disable-next-line:no-input-rename
  @Input('decimals') decimals = 0;

  private specialKeys = [
    'Backspace',
    'Tab',
    'End',
    'Home',
    'ArrowLeft',
    'ArrowRight',
    'Delete'
  ];

  constructor(private el: ElementRef) {
  }

  private check(value: string) {
      const regExpString = '[!@#$%^&*(),.?":{}|<> ]';
      return !String(value).match(new RegExp(regExpString));
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }
    // Do not use event.keycode this is deprecated.
    // See: https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/keyCode
    const current: string = this.el.nativeElement.value;
    const next: string = current.concat(event.key);
    if (next && !this.check(next)) {
      event.preventDefault();
    }
  }
}

import { map } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NodeService } from 'src/app/services/node.service';
import { NodeDataService } from 'src/app/services/node-data.service';
import { MappingNodeService } from 'src/app/services/mapping-node.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UserService } from 'src/app/services/user.service';
import { ColumnTable } from 'src/app/models/column-table/column-table.component';
import { MappingResultModalComponent } from '../mapping-schemas/mapping-result-modal/mapping-result-modal.component';
import { ConfirmDialogComponent } from 'src/app/base-components/dialogs/confirm-dialog/confirm-dialog.component';
import { Ng2Column, Ng2Setting } from 'src/app/models/ng2-smart-table-model';
import { Ng2CheckboxComponent } from 'src/app/base-components/ng2-smart-table-custom-field/ng2-smart-table-checkbox.component';

@Component({
  selector: 'app-mapping-waiting-approve-modal',
  templateUrl: './mapping-waiting-approve-modal.component.html',
  styleUrls: ['./mapping-waiting-approve-modal.component.css']
})
export class MappingWaitingApproveModalComponent implements OnInit {

  data: any;
  title = 'Mapping Node';
  nodes = [];
  isSourceReady: boolean;
  isSourceColumnReady: boolean;
  isSourceDataReady: boolean;
  sourceNode: any;
  sourceColumns: any;
  sourceData: any;
  sourceTotal: any;
  sourceLimit = 10;
  sourcePageIndex = 1;
  sourceSelected = 0;
  sourceDataSelected: any;
  isDestinationReady: boolean;
  isDestinationColumnReady: boolean;
  isDestinationDataReady: boolean;
  destinationColumns: any[];
  destinationNode: any;
  destinationLimit = 10;
  destinationPageIndex = 1;
  destinationTotal: any;
  destinationData: any;
  destinationSelected = 0;
  destinationDataSelected = [];
  sourceDataInvalid: boolean;
  destinationDataInvalid: boolean;
  mappingNodeId: any;
  vertical = true;
  updateAt = "";

  settings: Ng2Setting = {
                    actions:{
                        add: false,
                        edit: false,
                        delete: false
                      }
                  };
  dataDemo: any = [];

  disabled = false;
  mode: any;
  isSuggestMapping: any;
  isManualMapping: any;
  isImportMapping: any;
  createBy: any;
  sourceDisabled = [];
  destinationDisabled = [];
  sourceDataFilter: any;
  destinationDataFilter: any;

  isSearch = false;
  sourceSortBy = '';
  sourceOrderBy = 'asc';
  destinationSortBy = '';
  destinationOrderBy = 'asc';
  sourceSearchText = '';
  destinationSearchText = '';

  constructor(
    private nodeService: NodeService,
    private nodeDataService: NodeDataService,
    private mappingNodeService: MappingNodeService,
    private activatedRoute: ActivatedRoute,
    private toastr: ToastrService,
    private router: Router,
    private modalService: NgbModal,
    public activeModal: NgbActiveModal) {
      this.nodeService.getDropDown().subscribe((resNode: any) => {
        this.nodes = resNode;
        if (this.mappingNodeId) {
          this.selectDestination(this.mappingNodeId);
        }
      });
  }
  ngOnInit() {

  }

  closeModal(action: string) {
    this.activeModal.close(action);
  }

  selectSource(nodeId, isClear = true) {
    this.sourceSortBy = '';
    this.sourceSelected = nodeId;
    if (isClear) {
      this.sourceDataSelected = null;
    }
    this.sourceColumns = [];
    this.sourceNode = this.nodes.find(x => x.id === +nodeId);
    this.isSourceColumnReady = false;
    this.sourceNode.nodeColumns.forEach(element => {
      if (this.sourceSortBy === '') {
        this.sourceSortBy = element.name;
      }
      const col = new ColumnTable(element.name, element.name, 'col', 'node-text', true, element.name);
      this.sourceColumns.push(col);
    });
    this.isSourceColumnReady = true;
    if (this.mappingNodeId) {
      const citeria = {
        NodeDataIds: [this.sourceDataSelected],
        Text: this.sourceSearchText,
        Page: this.sourcePageIndex,
        Limit: this.sourceLimit,
        NodeId: this.sourceSelected,
        OrderBy: this.sourceOrderBy,
        SortBy: this.sourceSortBy,
        SourceId: this.sourceSelected,
        DestinationId: this.destinationSelected
      };
      this.nodeDataService.getData(citeria).subscribe((data: any) => {
        this.sourceData = data.data;
        this.sourceTotal = data.total;
        this.isSourceDataReady = true;
      });
    } else {
      this.loadDataForMapping(true, true);
    }
  }

  onSourceTablePageChange(event) {
    this.sourceLimit = event.pageSize;
    this.sourcePageIndex = event.pageIndex;
    if (this.mappingNodeId) {
      const citeria = {
        NodeDataIds: [this.sourceDataSelected],
        Text: this.sourceSearchText,
        Page: this.sourcePageIndex,
        Limit: this.sourceLimit,
        NodeId: this.sourceSelected,
        OrderBy: this.sourceOrderBy,
        SortBy: this.sourceSortBy,
        SourceId: this.sourceSelected,
        DestinationId: this.destinationSelected
      };
      this.nodeDataService.getData(citeria).subscribe((data: any) => {
        this.sourceData = data.data;
        this.sourceTotal = data.total;
        this.isSourceDataReady = true;
      });
    } else {
      this.loadDataForMapping(true, false);
    }
  }

  onSourceSorted(event) {
    this.sourceSortBy = event.sortBy;
    this.sourceOrderBy = event.orderBy;
    if (this.mappingNodeId) {
      const citeria = {
        NodeDataIds: [this.sourceDataSelected],
        Text: this.sourceSearchText,
        Page: this.sourcePageIndex,
        Limit: this.sourceLimit,
        NodeId: this.sourceSelected,
        OrderBy: this.sourceOrderBy,
        SortBy: this.sourceSortBy,
        SourceId: this.sourceSelected,
        DestinationId: this.destinationSelected
      };
      this.nodeDataService.getData(citeria).subscribe((data: any) => {
        this.sourceData = data.data;
        this.sourceTotal = data.total;
        this.isSourceDataReady = true;
      });
    } else {
      this.loadDataForMapping(true, false);
    }
  }

  onSourceSearch(event) {
    this.sourcePageIndex = 1;
    this.sourceSearchText = event.text;
    if (this.mappingNodeId) {
      const citeria = {
        NodeDataIds: [this.sourceDataSelected],
        Text: this.sourceSearchText,
        Page: this.sourcePageIndex,
        Limit: this.sourceLimit,
        NodeId: this.sourceSelected,
        OrderBy: this.sourceOrderBy,
        SortBy: this.sourceSortBy,
        SourceId: this.sourceSelected,
        DestinationId: this.destinationSelected
      };
      this.nodeDataService.getData(citeria).subscribe((data: any) => {
        this.sourceData = data.data;
        this.sourceTotal = data.total;
        this.isSourceDataReady = true;
      });
    } else {
      this.loadDataForMapping(true, false);
    }
  }

  selectDestination(mappingNodeId) {
    this.isDestinationColumnReady = true;
    if (mappingNodeId) {
      this.nodeDataService
        .DirectQueryByMappingNodeId(mappingNodeId, true)
        .subscribe((data: any) => {
          if(data && data.dataResultJson && data.dataResultJson.length > 0)
          {
            this.dataDemo = JSON.parse(data.dataResultJson);
            //get date mappingNode
            let mappingNode = data.mappingNode;
            mappingNode.dataList = data.dataList;
            this.updateAt = new Date(data.mappingNode.updatedAt).toLocaleDateString("en-GB", { 
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            });
            this.data = mappingNode;
            this.isSuggestMapping = mappingNode.isSuggestMapping;
            this.isManualMapping = mappingNode.isManualMapping;
            this.isImportMapping = mappingNode.isImportMapping;
            this.createBy = mappingNode.createBy;
            this.sourceSelected = mappingNode.sourceId;
            this.sourceDataSelected = +mappingNode.sourceDataId;
            this.destinationDataSelected = mappingNode.destinationDataId.split(',');
            this.selectSource(this.sourceSelected, false);
            this.destinationSelected = mappingNode.destinationId;

            this.destinationNode = this.nodes.find((x) => x.id === +this.destinationSelected);

            let column: Ng2Column={};

            this.destinationNode.nodeColumns.forEach((element) => {
              column[element.name] = {
                  title: element.name,
                  filter:true
                };
            });

            this.settings = {
                columns :column,
                actions:{
                  add: false,
                edit: false,
                delete: false},
                pager:{
                  perPage: 10,
                  paginateSize: 10
                }
              }
          }
          this.isDestinationDataReady = true;
        });
    } else {
      this.loadDataForMapping(true, true);
    }
  }

  onDestinationTablePageChange(event) {
    this.destinationLimit = event.pageSize;
    this.destinationPageIndex = event.pageIndex;
    if (this.mappingNodeId) {
      const citeria = {
        NodeDataIds: this.destinationDataSelected,
        Text: this.destinationSearchText,
        Page: this.destinationPageIndex,
        Limit: this.destinationLimit,
        NodeId: this.destinationSelected,
        OrderBy: this.destinationOrderBy,
        SortBy: this.destinationSortBy,
        SourceId: this.sourceSelected,
        DestinationId: this.destinationSelected
      };
      this.nodeDataService.getData(citeria).subscribe((data: any) => {
        this.destinationData = data.data;
        this.destinationTotal = data.total;
        this.isDestinationDataReady = true;
      });
    } else {
      this.loadDataForMapping(false, true);
    }
  }

  onDestinationSorted(event) {
    this.destinationSortBy = event.sortBy;
    this.destinationOrderBy = event.orderBy;
    if (this.mappingNodeId) {
      const citeria = {
        NodeDataIds: this.destinationDataSelected,
        Text: this.destinationSearchText,
        Page: this.destinationPageIndex,
        Limit: this.destinationLimit,
        NodeId: this.destinationSelected,
        OrderBy: this.destinationOrderBy,
        SortBy: this.destinationSortBy,
        SourceId: this.sourceSelected,
        DestinationId: this.destinationSelected
      };
      this.nodeDataService.getData(citeria).subscribe((data: any) => {
        this.destinationData = data.data;
        this.destinationTotal = data.total;
        this.isDestinationDataReady = true;
      });
    } else {
      this.loadDataForMapping(false, true);
    }
  }

  onDestinationSearch(event) {
    this.sourcePageIndex = 1;
    this.sourceSearchText = event.text;
    if (this.mappingNodeId) {
      const citeria = {
        NodeDataIds: this.destinationDataSelected,
        Text: this.destinationSearchText,
        Page: this.destinationPageIndex,
        Limit: this.destinationLimit,
        NodeId: this.destinationSelected,
        OrderBy: this.destinationOrderBy,
        SortBy: this.destinationSortBy,
        SourceId: this.sourceSelected,
        DestinationId: this.destinationSelected
      };
      this.nodeDataService.getData(citeria).subscribe((data: any) => {
        this.destinationData = data.data;
        this.destinationTotal = data.total;
        this.isDestinationDataReady = true;
      });
    } else {
      this.loadDataForMapping(false, true);
    }
  }

  onSelectNodeChange(data) {
    this.sourceDataSelected = data;
  }

  loadDataForMapping(loadSource = false, loadDestination = false) {
    if (this.sourceSelected !== 0 && this.destinationSelected !== 0 && loadSource) {
      const criteria = {
        Text: this.sourceSearchText,
        Page: this.sourcePageIndex,
        Limit: this.sourceLimit,
        NodeId: this.sourceSelected,
        OrderBy: this.sourceOrderBy,
        SortBy: this.sourceSortBy,
        SourceId: this.sourceSelected,
        DestinationId: this.destinationSelected
      };
      this.isSourceDataReady = false;
      this.nodeDataService.searchSource(criteria).subscribe((res: any) => {
        this.sourceData = res.data;
        this.sourceTotal = res.total;
        this.isSourceDataReady = true;
      });
    }
    if (this.sourceSelected !== 0 && this.destinationSelected !== 0 && loadDestination) {
      const criteriaDes = {
        Text: this.destinationSearchText,
        Page: this.destinationPageIndex,
        Limit: this.destinationLimit,
        NodeId: this.destinationSelected,
        OrderBy: this.destinationOrderBy,
        SortBy: this.destinationSortBy,
        SourceId: this.sourceSelected,
        DestinationId: this.destinationSelected
      };
      this.isDestinationDataReady = false;
      this.nodeDataService.searchDestination(criteriaDes).subscribe((res: any) => {
        this.destinationData = res.data;
        this.destinationTotal = res.total;
        this.isDestinationDataReady = true;
      });
    }

    if (this.sourceSelected !== 0 && this.destinationSelected !== 0 && loadSource && loadDestination) {
      this.getDisableInvisibalData();
    }
  }

  create() {
    this.sourceDataInvalid = false;
    if (!this.sourceDataSelected) {
      this.sourceDataInvalid = true;
    }
    this.destinationDataInvalid = false;
    if (this.destinationDataSelected.length === 0) {
      this.destinationDataInvalid = true;
    }
    if (!this.sourceDataInvalid  && !this.destinationDataInvalid) {
      let sourceCol = this.nodes.find(x => x.id === +this.sourceSelected).nodeColumns.find(x => x.isPrimary === true);
      if (!sourceCol) {
        sourceCol = this.nodes.find(x => x.id === +this.sourceSelected).nodeColumns[0];
      }
      let destinationCol = this.nodes.find(x => x.id === +this.destinationSelected).nodeColumns.find(x => x.isPrimary === true);
      if (!destinationCol) {
        destinationCol = this.nodes.find(x => x.id === +this.destinationSelected).nodeColumns[0];
      }

      // tslint:disable-next-line:max-line-length
      const sourceValue = this.sourceData.find(x => x.id === +this.sourceDataSelected).nodeDataDetail.find(x => x.property === sourceCol.name).value;

      const data = {
        SourceDataId: this.sourceDataSelected,
        SourceId: this.sourceSelected,
        SourceValue: sourceValue,
        DestinationId: this.destinationSelected,
        DestinationDataId: '',
        DestinationValue: ''
      };
      let destinationDataId = '';
      let destinationValue = '';
      this.destinationDataSelected.forEach((element, index, array) => {
        // tslint:disable-next-line:max-line-length
        const value = this.destinationData.find(x => x.id === +element).nodeDataDetail.find(x => x.property === destinationCol.name).value;
        if (index === array.length - 1) {
          destinationDataId += element;
          destinationValue += value;
        } else {
          destinationDataId += element + ',';
          destinationValue += value + ',';
        }
      });
      data.DestinationDataId = destinationDataId;
      data.DestinationValue = destinationValue;
      this.sourceDataSelected = null;
      this.destinationDataSelected = [];
      this.mappingNodeService.add(data).subscribe((res: any) => {
        const modalRef = this.modalService.open(
          MappingResultModalComponent, {size: 'lg'}
        );
        modalRef.componentInstance.data = data;
        modalRef.result.then((modalResult: any) => {
          this.getDisableInvisibalData();
        }, (reason) => {
          this.getDisableInvisibalData();
        });
      });
    }
  }

  update() {
    this.sourceDataInvalid = false;
    if (!this.sourceDataSelected) {
      this.sourceDataInvalid = true;
    }
    this.destinationDataInvalid = false;
    if (this.destinationDataSelected.length === 0) {
      this.destinationDataInvalid = true;
    }
    if (!this.sourceDataInvalid  && !this.destinationDataInvalid) {
      let sourceCol = this.nodes.find(x => x.id === +this.sourceSelected).nodeColumns.find(x => x.isPrimary === true);
      if (!sourceCol) {
        sourceCol = this.nodes.find(x => x.id === +this.sourceSelected).nodeColumns[0];
      }
      let destinationCol = this.nodes.find(x => x.id === +this.destinationSelected).nodeColumns.find(x => x.isPrimary === true);
      if (!destinationCol) {
        destinationCol = this.nodes.find(x => x.id === +this.destinationSelected).nodeColumns[0];
      }

      // tslint:disable-next-line:max-line-length
      const sourceValue = this.sourceData.find(x => x.id === +this.sourceDataSelected).nodeDataDetail.find(x => x.property === sourceCol.name).value;

      const data = {
        Id: this.mappingNodeId,
        SourceDataId: this.sourceDataSelected,
        SourceId: this.sourceSelected,
        SourceValue: sourceValue,
        DestinationId: this.destinationSelected,
        DestinationDataId: '',
        DestinationValue: ''
      };
      let destinationDataId = '';
      let destinationValue = '';
      this.destinationDataSelected.forEach((element, index, array) => {
        // tslint:disable-next-line:max-line-length
        const value = this.destinationData.find(x => x.id === +element).nodeDataDetail.find(x => x.property === destinationCol.name).value;
        if (index === array.length - 1) {
          destinationDataId += element;
          destinationValue += value;
        } else {
          destinationDataId += element + ',';
          destinationValue += value + ',';
        }
      });
      data.DestinationDataId = destinationDataId;
      data.DestinationValue = destinationValue;
      this.mappingNodeService.update(data).subscribe(res => {
        this.toastr.success('Update Success');
        this.activeModal.close('close');
      });
    }
  }

  delete() {
    const modalRef = this.modalService.open(ConfirmDialogComponent);
    modalRef.result.then((result) => {
      if (result) {
        this.mappingNodeService.delete(this.mappingNodeId).subscribe(() => {
          this.toastr.success('Delete Success');
          this.activeModal.close('close');
        });
      }
    }, (reason) => {
    });
  }

  deleteApprove() {
    const modalRef = this.modalService.open(ConfirmDialogComponent);
    modalRef.result.then((result) => {
      if (result) {
        this.mappingNodeService.deleteApprove(this.mappingNodeId).subscribe(() => {
          this.toastr.success('Delete Success');
          this.activeModal.close('close');
        });
      }
    }, (reason) => {
    });
  }

  approve() {
    const ids = this.data.dataList.map(x => x.id);
    const approveData = {SourceId: this.data.sourceId, DestinationId: this.data.destinationId , MappingIds: ids};
    const modalRef = this.modalService.open(ConfirmDialogComponent);
    modalRef.componentInstance.type = 'approve';
    modalRef.result.then((result) => {
      if (result) {
        this.mappingNodeService.approve(approveData).subscribe(() => {
          this.toastr.success('Approve Success');
          this.closeModal('approve');
        });
      }
    }, (reason) => {
    });
  }

  reject() {
    const ids = this.data.dataList.map(x => x.id);
    const rejectData = {SourceId: this.data.sourceId, DestinationId: this.data.destinationId , MappingIds: ids};
    const modalRef = this.modalService.open(ConfirmDialogComponent);
    modalRef.componentInstance.type = 'reject';
    modalRef.result.then((result) => {
      if (result) {
        this.mappingNodeService.reject(rejectData).subscribe(() => {
          this.toastr.success('Reject Success');
          this.closeModal('reject');
        });
      }
    }, (reason) => {
    });
  }

  getDisableInvisibalData() {
    if (this.sourceSelected && this.sourceSelected !== 0 && this.destinationSelected && this.destinationSelected !== 0) {
      this.mappingNodeService.getDisableInvisibalData(this.sourceSelected, this.destinationSelected).subscribe((res: any) => {
        this.sourceDisabled = res.disableData.map(x => x.sourceDataId);
        this.destinationDisabled = [];
        res.disableData.forEach(element => {
          element.destinationDataId.split(',').forEach(des => {
            this.destinationDisabled.push(+des);
          });
        });
      });
    }
  }

}

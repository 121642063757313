import { Component, Input, OnInit } from '@angular/core';
import { ViewCell } from 'ng2-smart-table';

@Component({
  template: `
    <input
      type="checkbox"
      (click)="changeBoolean()"
      [checked]="this.checked">
  `
})
export class Ng2CheckboxComponent implements ViewCell, OnInit {

  @Input() value: any;
  @Input() rowData: any;

  checked: boolean;

  constructor() { }

  ngOnInit() {
    this.checked = JSON.parse(this.value);
  }

  changeBoolean() {
    this.checked = !this.checked;
    this.rowData.selected = this.checked? true: false;
  }

}

import { UserService } from 'src/app/services/user.service';
import { MappingImportSuggestModalComponent } from './../mapping-import-suggest-modal/mapping-import-suggest-modal.component';
import { MappingSuggessModalComponent } from './../mapping-suggess-modal/mapping-suggess-modal.component';
import { MappingNodeService } from './../../../services/mapping-node.service';
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { MappingImportModalComponent } from '../mapping-import-modal/mapping-import-modal.component';

@Component({
  selector: 'app-mapping-node',
  templateUrl: './mapping-node.component.html',
  styleUrls: ['./mapping-node.component.scss']
})
export class MappingNodeComponent implements OnInit {
  data: any;

  constructor(
    private mappingNodeService: MappingNodeService,
    public userService: UserService,
    private toastr: ToastrService,
    private modalService: NgbModal) { }

  ngOnInit() {
    sessionStorage.setItem('isHome', 'false');
    sessionStorage.setItem('isRedirectSearchFromFile', 'false');
    
    this.mappingNodeService.getAll().subscribe(res => {
      this.data = res;
    });
  }

  suggessMapping() {
    const ngbModalOptions: NgbModalOptions = {
      backdrop : 'static',
      keyboard : false,
    };
    const modalImport = this.modalService.open(MappingSuggessModalComponent, ngbModalOptions);
    modalImport.result.then((result) => {
      this.mappingNodeService.getAll().subscribe(res => {
        this.data = res;
      });
    });
  }
  importSuggessMapping() {
    const ngbModalOptions: NgbModalOptions = {
      backdrop : 'static',
      keyboard : false,
    };
    const modalImport = this.modalService.open(MappingImportSuggestModalComponent, ngbModalOptions);
    modalImport.result.then((result) => {
      this.mappingNodeService.getAll().subscribe(res => {
        this.data = res;
      });
    });
  }

}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class OrganizationService {
  private actionUrl = 'api/Organization/';

  constructor(private http: HttpClient) {}

  public getAll<T>(): Observable<T> {
    return this.http.get<T>(this.actionUrl);
  }

  public add<T>(data): Observable<T> {
    return this.http.post<T>(this.actionUrl + 'Create', data);
  }

  public update<T>(data): Observable<T> {
    return this.http.post<T>(this.actionUrl + 'Update', data);
  }

  public checkDuplicate<T>(data): Observable<T> {
    return this.http.post<T>(this.actionUrl + 'CheckDuplicate', data);
  }
}

import { OrganizationService } from './../../services/organization.service';
import { UserService } from './../../services/user.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, AbstractControl, ValidationErrors } from '@angular/forms';
import { AuthService, GoogleLoginProvider, FacebookLoginProvider } from 'angularx-social-login';
import { ToastrService } from 'ngx-toastr';
import { catchError, tap, map, debounceTime } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import { LoginComponent } from '../login/login.component';
@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.css']
})
export class SignUpComponent implements OnInit {

  form: any;
  user: any;
  organizations: unknown;
  constructor(
    private fb: FormBuilder,
    private userService: UserService,
    private authService: AuthService,
    private toastr: ToastrService,
    public modal: NgbActiveModal,
    private modalService: NgbModal,
    private organizationService: OrganizationService,
    private router: Router,
  ) {
    this.form = this.fb.group({
      FirstName: ['', [Validators.required]],
      LastName: ['', [Validators.required]],
      OrganizationId: ['', Validators.required],
      OrganizationOther: [''],
      Email: ['', [Validators.required, Validators.email], this.validateEmailDuplicate.bind(this)],
      Password: ['', [Validators.required, Validators.minLength(6)]],
    });
  }

  ngOnInit() {
    this.authService.authState.subscribe((user) => {
      if (user) {
        this.authService.signOut(true);
      }
    });
    this.initDLL();
  }

  initDLL() {
    this.organizationService.getAll().subscribe(res => {
      this.organizations = res;
    });
  }

// tslint:disable-next-line:use-lifecycle-interface
  ngAfterViewInit() {
  }

  submit() {
    if (this.form.valid) {
      this.userService.signUp(this.form.value).subscribe(res => {
        this.toastr.success('Sign-up Success');
        this.user = res;
        this.signIn();
        //this.router.navigateByUrl('sign-in');
        this.modal.dismiss('Success');
      });
    } 
  }

  signInWithGoogle(): void {
    this.authService.signIn(GoogleLoginProvider.PROVIDER_ID).then((user) => {
      if (user) {
        const data = {
          Email: user.email,
          FirstName: user.name,
        };
        this.userService.checkEmailDuplicate(data).subscribe(res => {
          if (!res) {
            this.userService.signUp(data).subscribe(result => {
              this.toastr.success('Sign-up Success');
              this.router.navigateByUrl('sign-in');
            });
          } else {
            this.toastr.error('This email is already to sign in');
          }
        }).add(() => {
          this.authService.signOut(true);
        });
      }
    });
  }

  signInWithFB(): void {
    this.authService.signIn(FacebookLoginProvider.PROVIDER_ID).then((user) => {
      if (user) {
        const data = {
          Email: user.email,
          FirstName: user.name,
        };
        this.userService.checkEmailDuplicate(data).subscribe(res => {
          if (!res) {
            this.userService.signUp(data).subscribe(result => {
              this.toastr.success('Sign-up Success');
              this.router.navigateByUrl('sign-in');
            });
          } else {
            this.toastr.error('This email is already to sign in');
          }
        }).add(() => {
          this.authService.signOut(true);
        });
      }
    });
  }

  validateEmailDuplicate({value}: AbstractControl): Observable<ValidationErrors | null> {
    const data = {
      Email: value
    };
    return this.userService.checkEmailDuplicate(data)
      .pipe(debounceTime(500), map((emailDuplicate: boolean) => {
        if (emailDuplicate) {
            return {
                isDuplicate: true
            };
        }
        return null;
    }));
  }
  signIn() {
    const ngbModalOptions: NgbModalOptions = {
      backdrop : true,
      keyboard : false,
    };
    const modalRef = this.modalService.open(
      LoginComponent, ngbModalOptions
    );
    modalRef.result.then((result) => {
    }, (reason) => {
      if (reason === 'Success') {
        this.userService.getAll().subscribe(res => {
        });
      }
    });
    this.modal.dismiss('Success');
  }
}

import { MappingViewdetailComponent } from './pages/mapping-schemas/mapping-viewdetail/mapping-viewdetail.component';
import { MappingPreviewComponent } from './pages/mapping-schemas/mapping-preview/mapping-preview.component'
import { SettingService } from './services/setting.service';
import { SettingFileSizeComponent } from './pages/setting/setting-file-size/setting-file-size.component';
import { AdvanceSearchResultComponent } from './pages/advance-search-result/advance-search-result.component';
import { BasicSearchResultComponent } from './pages/basic-search-result/basic-search-result.component';
import { MappingWaitingApproveModalComponent } from './pages/mapping-waiting-approve-modal/mapping-waiting-approve-modal.component';
import { TableNewComponent } from './base-components/table-new/table-new.component';
import { UserFeedbackService } from './services/user-feedback.service';
import { NotificationComponent } from './pages/notification/notification.component';
import { ForgotPasswordModalComponent } from './pages/forgot-password-modal/forgot-password-modal.component';
import { ContactUsService } from './services/contact-us.service';
import { ContactUsComponent } from './pages/contact-us/contact-us.component';
import { SettingOrganizationComponent } from './pages/setting/setting-organization/setting-organization.component';
import { SettingMainComponent } from './pages/setting/setting-main/setting-main.component';
import { NotifyService } from './services/notify.service';
// tslint:disable-next-line:max-line-length
import { MappingWaitingApproveListComponent } from './pages/mapping-schemas/mapping-waiting-approve-list/mapping-waiting-approve-list.component';
import { MappingSuggessModalComponent } from './pages/mapping-schemas/mapping-suggess-modal/mapping-suggess-modal.component';
import { MappingImportModalComponent } from './pages/mapping-schemas/mapping-import-modal/mapping-import-modal.component';
import { MappingListComponent } from './pages/mapping-schemas/mapping-list/mapping-list.component';
import { MappingNodeService } from './services/mapping-node.service';
import { MappingManualComponent } from './pages/mapping-schemas/mapping-manual/mapping-manual.component';
import { MappingNodeComponent } from './pages/mapping-schemas/mapping-node/mapping-node.component';
import { NodeDataImportModalComponent } from './pages/node-managements/node-data-import-modal/node-data-import-modal.component';
import { NodeDataService } from './services/node-data.service';
import { NodeDataAddModalComponent } from './pages/node-managements/node-data-add-modal/node-data-add-modal.component';
import { NodeDataListComponent } from './pages/node-managements/node-data-list/node-data-list.component';
import { OrganizationService } from './services/organization.service';
import { ConfirmDialogComponent } from './base-components/dialogs/confirm-dialog/confirm-dialog.component';
import { ColumnTypeService } from './services/columnType.service';
import { NodeManagementComponent } from './pages/node-managements/node-management/node-management.component';
import { SignUpComponent } from './pages/sign-up/sign-up.component';
import { AuthGuard } from './services/auth-guard.service';
import { WINDOW_PROVIDERS } from './services/window.service';
import { NavBarComponent } from './base-components/nav-bar/nav-bar.component';
import { LoadingComponent } from './base-components/loading/loading.component';
import { LoginComponent } from './pages/login/login.component';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { JwtModule } from '@auth0/angular-jwt';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { UserService } from './services/user.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgbModule, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LoadingScreenInterceptor } from './interceptors/loading.interceptor';
import { SocialLoginModule, AuthServiceConfig, LoginOpt } from 'angularx-social-login';
import { GoogleLoginProvider, FacebookLoginProvider } from 'angularx-social-login';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { HomeComponent } from './pages/home/home.component';
import { NewUserModalComponent } from './pages/new-user-modal/new-user-modal.component';
import { RemainingItemModalComponent } from './pages/mapping-schemas/remaining-item-modal/remaining-item-modal.component'
import { ManageUserComponent } from './pages/manage-user/manage-user.component';
import { TableComponent } from './base-components/table/table.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { SideBarComponent } from './base-components/side-bar/side-bar.component';
import { NodeAddComponent } from './pages/node-managements/node-add/node-add.component';
import { NumericDirective } from './directives/numeric.directive';
import { NoneSpacialDirective } from './directives/noneSpacial.directive';
import { UserFeedbackComponent } from './pages/user-feedback/user-feedback.component';
import { MappingAddComponent } from './pages/mapping-schemas/mapping-add/mapping-add.component';
import { MappingManagementComponent } from './pages/mapping-schemas/mapping-management/mapping-management.component';
import { MappingSchemaService } from './services/mapping-schema.service';
import { NumbersOnlyDirective } from './directives/number-only.directive';
import { MappingWaitingApproveComponent } from './pages/mapping-schemas/mapping-waiting-approve/mapping-waiting-approve.component';
import { MappingApprovedComponent } from './pages/mapping-schemas/mapping-approved/mapping-approved.component';
import { MappingApprovedListComponent } from './pages/mapping-schemas/mapping-approved-list/mapping-approved-list.component';
import { OrganizationModalComponent } from './pages/setting/organization-modal/organization-modal.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { ContactUsListComponent } from './pages/contact-us-list/contact-us-list.component';

import { GraphComponent } from './visuals/graph/graph.component';
import { SHARED_VISUALS } from './visuals/shared';
import { D3Service, D3_DIRECTIVES } from './d3';
import { UserFeedbackModalComponent } from './pages/user-feedback-modal/user-feedback-modal.component';
import { UserFeedbackDetailModalComponent } from './pages/user-feedback-detail-modal/user-feedback-detail-modal.component';
import { MappingResultModalComponent } from './pages/mapping-schemas/mapping-result-modal/mapping-result-modal.component';
// tslint:disable-next-line:max-line-length
import { MappingImportSuggestModalComponent } from './pages/mapping-schemas/mapping-import-suggest-modal/mapping-import-suggest-modal.component';
import { SettingTokenComponent } from './pages/setting/setting-token/setting-token.component';
import { FacebookService, InitParams, FacebookModule } from 'ngx-facebook';
import { Ng2FileSizeModule } from 'ng2-file-size';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { Ng2CheckboxComponent } from './base-components/ng2-smart-table-custom-field/ng2-smart-table-checkbox.component';
import { Ng2RadioComponent } from './base-components/ng2-smart-table-custom-field/ng2-smart-table-radio.component';
import { Ng2TooltipComponent } from 'src/app/base-components/ng2-smart-table-custom-field/ng2-smart-table-tooltips.component';
import { SearchFileModalComponent } from './pages/search-file-modal/search-file-modal.component';
import { SearchFromFileComponent } from './pages/search-from-file/search-from-file.component';
import { ChangelogComponent } from './pages/changelog/changelog.component';
import { MatFormFieldModule, MatInputModule, MatAutocompleteModule} from '@angular/material';
import { NodeDetailComponent } from './pages/node-managements/node-detail/node-detail.component';
import { FilterSearchModalComponent } from './pages/filter-search-modal/filter-search-modal.component';
import { MappingCreateComponent } from './pages/mapping-schemas/mapping-create/mapping-create.component';
import { MatTooltipModule } from '@angular/material';

export function tokenGetter() {
  return localStorage.getItem('jwt');
}

const fbLoginOptions: LoginOpt = {
  scope: 'email',
  return_scopes: true,
  enable_profile_selector: true
}; // https://developers.facebook.com/docs/reference/javascript/FB.login/v2.11

const googleLoginOptions: LoginOpt = {
  scope: 'profile email',
  return_scopes: true,
  enable_profile_selector: true
}; // https://developers.google.com/api-client-library/javascript/reference/referencedocs#gapiauth2clientconfig

const config = new AuthServiceConfig([
  {
    id: GoogleLoginProvider.PROVIDER_ID,
    provider: new GoogleLoginProvider('174530802451-cvag8oublr1qp1n7eqmc1ah678r6v412.apps.googleusercontent.com', googleLoginOptions)
  },
  {
    id: FacebookLoginProvider.PROVIDER_ID,
    provider: new FacebookLoginProvider('540478816544480', fbLoginOptions)
  }
]);

export function provideConfig() {
  return config;
}


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    LoadingComponent,
    NavBarComponent,
    SignUpComponent,
    DashboardComponent,
    HomeComponent,
    NodeManagementComponent,
    NodeAddComponent,
    ManageUserComponent,
    TableComponent,
    TableNewComponent,
    SideBarComponent,
    ConfirmDialogComponent,
    NumericDirective,
    NoneSpacialDirective,
    NumbersOnlyDirective,
    NewUserModalComponent,
    RemainingItemModalComponent,
    ManageUserComponent,
    TableComponent,
    SideBarComponent,
    UserFeedbackComponent,
    NodeDataListComponent,
    NodeDataAddModalComponent,
    NodeDataImportModalComponent,
    MappingAddComponent,
    MappingManagementComponent,
    MappingNodeComponent,
    MappingManualComponent,
    MappingListComponent,
    MappingImportModalComponent,
    MappingSuggessModalComponent,
    MappingWaitingApproveComponent,
    MappingWaitingApproveListComponent,
    MappingApprovedComponent,
    MappingApprovedListComponent,
    SettingMainComponent,
    SettingOrganizationComponent,
    OrganizationModalComponent,
    ContactUsComponent,
    ForgotPasswordModalComponent,
    ContactUsListComponent,
    NotificationComponent,
    GraphComponent,
    ...SHARED_VISUALS,
    ...D3_DIRECTIVES,
    UserFeedbackModalComponent,
    UserFeedbackDetailModalComponent,
    MappingResultModalComponent,
    MappingImportSuggestModalComponent,
    SettingTokenComponent,
    MappingWaitingApproveModalComponent,
    BasicSearchResultComponent,
    AdvanceSearchResultComponent,
    SettingFileSizeComponent,
    MappingViewdetailComponent,
    MappingPreviewComponent,
    Ng2CheckboxComponent,
    Ng2RadioComponent,
    Ng2TooltipComponent,
    SearchFileModalComponent,
    SearchFromFileComponent,
    ChangelogComponent,
    NodeDetailComponent,
    FilterSearchModalComponent,
    MappingCreateComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    SocialLoginModule,
    NgbModule,
    CommonModule,
    NgMultiSelectDropDownModule.forRoot(),
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot(), // ToastrModule added
    JwtModule.forRoot({
      config: {
        // tslint:disable-next-line:object-literal-shorthand
        tokenGetter: tokenGetter,
        whitelistedDomains: ['localhost:5001'],
        blacklistedRoutes: []
      }
    }),
    NgxPaginationModule,
    FacebookModule.forRoot(),
    Ng2FileSizeModule,
    Ng2SmartTableModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    MatInputModule,
    MatTooltipModule
  ],
  providers: [
    UserService,
    ColumnTypeService,
    NodeDataService,
    OrganizationService,
    NgbActiveModal,
    MappingSchemaService,
    MappingNodeService,
    NotifyService,
    ContactUsService,
    UserFeedbackService,
    SettingService,
    D3Service,
    AuthGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingScreenInterceptor,
      multi: true
    },
    {
      provide: AuthServiceConfig,
      useFactory: provideConfig
    },
    WINDOW_PROVIDERS
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    NewUserModalComponent,
    ConfirmDialogComponent,
    NodeDataAddModalComponent,
    NodeDataImportModalComponent,
    MappingImportModalComponent,
    MappingSuggessModalComponent,
    OrganizationModalComponent,
    ForgotPasswordModalComponent,
    UserFeedbackModalComponent,
    UserFeedbackDetailModalComponent,
    MappingResultModalComponent,
    MappingImportSuggestModalComponent,
    MappingWaitingApproveModalComponent,
    MappingViewdetailComponent,
    MappingPreviewComponent,
    Ng2CheckboxComponent,
    Ng2RadioComponent,
    Ng2TooltipComponent,
    SearchFileModalComponent,
    FilterSearchModalComponent,
    MappingCreateComponent,
    RemainingItemModalComponent
  ]
})
export class AppModule {}

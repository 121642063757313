import { Component, Input, OnInit } from '@angular/core';
import { ViewCell } from 'ng2-smart-table';

@Component({
  template: `
    <input
      type="checkbox" id="radio"
      (click)="changeBoolean()"
      [checked]="this.checked">
  `
})
export class Ng2RadioComponent implements ViewCell, OnInit {

  @Input() value: any;
  @Input() rowData: any;

  checked: boolean;

  constructor() { }

  ngOnInit() {
    this.checked = JSON.parse(this.value);
  }

  changeBoolean() {
    var input = document.querySelectorAll('#radio');
    input.forEach(element => {
        element['checked'] = false; 
    });

    this.checked = !this.checked;
    this.rowData.selected = this.checked? true: false;
  }

}

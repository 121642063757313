import { ViewEncapsulation } from '@angular/core';
import { Component, Input, OnInit } from '@angular/core';
import { ViewCell } from 'ng2-smart-table';

@Component({
  template: `
    <div matTooltip="{{tooltipText}}" matTooltipClass="tooltipStyle">{{rawData}}</div>
  `,
  styleUrls: ['./ng2-smart-table-tooltips.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class Ng2TooltipComponent implements ViewCell, OnInit {

  @Input() value: any;
  @Input() rowData: any;

  rawData;
  tooltipText;

  constructor() { }

  ngOnInit() {
    this.rawData = this.value;
    if (this.rawData.length > 50) {
        this.tooltipText = this.rawData;
        let res = this.rawData.substr(0, 50)
        this.rawData = res + '...'
    } else {
    } 
  }

}

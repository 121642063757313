import { ContactUsService } from './../../services/contact-us.service';
import { Component, OnInit } from '@angular/core';
import { Users } from 'src/app/models/users';
import { FormBuilder, Validators, AbstractControl, ValidationErrors } from '@angular/forms';
import { UserService } from 'src/app/services/user.service';
import { AuthService } from 'angularx-social-login';
import { ToastrService } from 'ngx-toastr';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OrganizationService } from 'src/app/services/organization.service';
import { Observable } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';

@Component({
  selector: 'app-forgot-password-modal',
  templateUrl: './forgot-password-modal.component.html',
  styleUrls: ['./forgot-password-modal.component.css']
})
export class ForgotPasswordModalComponent implements OnInit {

  form = this.fb.group({
    Email: ['', [Validators.required, Validators.email]],
  });

  constructor(
    private fb: FormBuilder,
    private userService: UserService,
    private authService: AuthService,
    private toastr: ToastrService,
    public modal: NgbActiveModal,
    private modalService: NgbModal,
    private organizationService: OrganizationService,
    private contactUsService: ContactUsService
  ) {
    this.form = this.fb.group({
      Email: ['', [Validators.required, Validators.email], this.validateEmailDuplicate.bind(this)],
    });
  }

  ngOnInit() {
  }

  validateEmailDuplicate({value}: AbstractControl): Observable<ValidationErrors | null> {
    const data = {
      Email: value
    };

    return this.userService.checkEmailDuplicate(data)
      .pipe(debounceTime(500), map((emailDuplicate: boolean) => {
        if (!emailDuplicate) {
            return {
                isDuplicate: true
            };
        }
        return null;
    }));
  }


  submit() {
    if (this.form.valid) {
      this.contactUsService.forgotPassword(this.form.value).subscribe(res => {
        this.toastr.success('Send request to admin success.');
        this.modal.dismiss('Success');
      });
    }
  }

}

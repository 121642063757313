import { filter } from 'rxjs/operators';
import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ColumnTable } from 'src/app/models/column-table/column-table.component';
import { ActionModel } from 'src/app/models/table-action-model';
import { ToastrService } from 'ngx-toastr';
import {saveAs as importedSaveAs} from 'file-saver';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.css']
})
export class TableComponent implements OnInit {
  @Input()
  tableid: string;
  @Input()
  datas: any[];
  @Input()
  disableData = [];
  @Input()
  colunmTable: ColumnTable[];
  @Input()
  actions: ActionModel[];
  @Input()
  selectAction: ActionModel;
  @Input()
  actionDelete: ActionModel;
  @Input()
  actionEdit: ActionModel;
  @Input()
  sortBy: string;
  @Input()
  orderBy = 'asc';
  @Input()
  isShowResult: boolean;
  @Input()
  isShowPage = true;
  @Input()
  isSearch = false;
  @Input()
  selectNode = '';
  @Input()
  selectNodes = [];
  @Input()
  showMappingStatus = false;
  @Input()
  isMapping = false;
  @Input()
  isSelfSort = false;
  @Input()
  isSelfSearch = false;
  @Input()
  isSearchDate = false;
  @Input()
  isDeleteAll = false;
  @Input()
  isLoading = true;

  rawData: any;
  dateSelectFrom: any;
  dateSelectTo: any;

  // MatPaginator Inputs
  @Input()
  total = 100;
  @Input()
  pageIndex = 1;
  @Input()
  pageSize = 10;
  @Input()
  rowClass = '';

  pageSizeOptions: number[] = [5, 10, 25, 100];
  // tslint:disable-next-line:no-output-on-prefix
  @Output()
  onPageChange: EventEmitter<any> = new EventEmitter();
  // tslint:disable-next-line:no-output-on-prefix
  @Output()
  onItemSelected: EventEmitter<any> = new EventEmitter();
  // tslint:disable-next-line:no-output-on-prefix
  @Output()
  onSorted: EventEmitter<any> = new EventEmitter();
  // tslint:disable-next-line:no-output-on-prefix
  @Output()
  onSearch: EventEmitter<any> = new EventEmitter();
  @Output()
  sortChange = new EventEmitter<string>();
  @Output()
  colChange = new EventEmitter<string>();
  // tslint:disable-next-line:no-output-on-prefix
  @Output()
  onSelectNodeChange: EventEmitter<string> = new EventEmitter<string>();
  // tslint:disable-next-line:no-output-on-prefix
  @Output()
  onDataCheck: EventEmitter<any> = new EventEmitter();
  textSearch = '';
  // tslint:disable-next-line:no-output-on-prefix
  @Output()
  onDeleteAll: EventEmitter<any> = new EventEmitter();

  constructor(
    public domSanitizer: DomSanitizer,
    private toastr: ToastrService) {}

  ngOnInit() {
    this.rawData = this.datas;
  }

  pageChange(event) {
    const changeEvent = {
      pageSize: this.pageSize,
      pageIndex: event
    };
    this.onPageChange.emit(changeEvent);
  }

  pageSizeChange(event) {
    const changeEvent = {
      pageSize: event.value,
      pageIndex: this.pageIndex
    };
    this.onPageChange.emit(changeEvent);
  }

  itemSelected(data) {
    this.onItemSelected.emit(data);
  }

  sort(col: string) {
    if (this.sortBy.toLowerCase() === col.toLowerCase() && this.orderBy === 'asc') {
      this.orderBy = 'desc';
    } else {
      this.orderBy = 'asc';
    }

    if (this.isSelfSort) {
      this.sortBy = col;
      this.onSorted.emit({orderBy: this.orderBy, sortBy: this.sortBy});
    } else if (this.isMapping) {
      this.sortBy = col;
      this.datas = this.datas.sort((a, b) => {
        if (a[this.sortBy] < b[this.sortBy]) {
          return this.orderBy === 'asc' ? -1 : 1;
        } else if (a[this.sortBy] > b[this.sortBy])  {
          return this.orderBy === 'asc' ? 1 : -1;
        } else {
          return 0;
        }
      });
    } else {
      this.sortBy = col.toLowerCase();
      this.datas = this.datas.sort((a, b) => {
        // tslint:disable-next-line:max-line-length
        if (a.nodeDataDetail.find(x => x.property.toLowerCase() === this.sortBy).value.toLowerCase() < b.nodeDataDetail.find(x =>  x.property.toLowerCase() === this.sortBy).value.toLowerCase()) {
          return this.orderBy === 'asc' ? -1 : 1;
        // tslint:disable-next-line:max-line-length
        } else if (a.nodeDataDetail.find(x =>  x.property.toLowerCase() === this.sortBy).value.toLowerCase() > b.nodeDataDetail.find(x =>  x.property.toLowerCase() === this.sortBy).value.toLowerCase())  {
          return this.orderBy === 'asc' ? 1 : -1;
        } else {
          return 0;
        }
      });
    }
  }

  search(event) {
    this.textSearch = event.target.value;
    const values = event.target.value;
    if (this.isSelfSearch) {
      this.onSearch.emit({text: values, dateFrom: this.dateSelectFrom, dateTo: this.dateSelectTo});
    } else {
      if (!this.rawData) {
        this.rawData = this.datas;
      }
      if (this.isMapping) {
        // tslint:disable-next-line:max-line-length
        this.datas = this.rawData.filter(x => x.sourceValue.indexOf(values) > -1 || x.mappingNodeDetails.some(s => s.destinationValue.indexOf(values) > -1) || !values);
        this.total = this.datas.length;
      } else {
        // tslint:disable-next-line:max-line-length
        this.datas = this.rawData.filter(x => x.nodeDataDetail.some(s => s.value ? s.value.toLowerCase().indexOf(values.toLowerCase()) > -1 : false || !values  ));
        this.total = this.datas.length;
      }
    }
  }

  dataSelectedSingle(data) {
    this.selectNode = data;
    this.onSelectNodeChange.emit(this.selectNode);
  }

  dataSelected(event, value) {
    if (event.target.checked) {
      this.selectNodes.push(value);
    } else {
      // tslint:disable-next-line:variable-name
      const index_remove = this.selectNodes.findIndex(ele => {
        return ele === value;
      });
      this.selectNodes.splice(index_remove, 1);
    }
    this.onDataCheck.emit(this.selectNodes);
  }

  selectedAll(event) {
    if (event.target.checked) {
      this.datas.forEach(element => {
        if (!this.selectNodes.find(x => x.id === element.id)) {
          this.selectNodes.push(element.id + '');
        }
      });
    } else {
      this.selectNodes = [];
    }
  }

  generateArray(obj) {
    // tslint:disable-next-line:only-arrow-functions
    return Object.keys(obj).map(function(elem) {
      return {key: elem, value: obj[elem]};
    });
  }

  getNodeValue(data, key) {
    const detail = data.nodeDataDetail.find(x => x.property === key);
    return (detail) ? detail.value : '';
  }

  dateChange() {
    this.onSearch.emit({text: this.textSearch, dateFrom: this.dateSelectFrom, dateTo: this.dateSelectTo});
  }

  deleteAll() {
    this.onDeleteAll.emit(this.selectNodes);
  }

  copyMessage(val: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.toastr.success('Copy Success');
  }

  loadFile(data) {
    const byteArray = new Uint8Array(data);
    const blob = this.FileContenttoBlob(data, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
    importedSaveAs(blob, 'Result.xlsx');
  }

  public base64toBlob(base64Data) {
    const sliceSize = 1024;
    const byteCharacters = atob(base64Data);
    const bytesLength = byteCharacters.length;
    const slicesCount = Math.ceil(bytesLength / sliceSize);
    const byteArrays = new Array(slicesCount);

    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      const begin = sliceIndex * sliceSize;
      const end = Math.min(begin + sliceSize, bytesLength);

      const bytes = new Array(end - begin);
      for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays);
  }

  public  FileContenttoBlob(fileContent, fileType) {
    const sliceSize = 1024;
    const byteCharacters = atob(fileContent);
    const bytesLength = byteCharacters.length;
    const slicesCount = Math.ceil(bytesLength / sliceSize);
    const byteArrays = new Array(slicesCount);

    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      const begin = sliceIndex * sliceSize;
      const end = Math.min(begin + sliceSize, bytesLength);

      const bytes = new Array(end - begin);
      for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, { type: fileType});
  }
}

import {
  Component,
  Input,
  ChangeDetectorRef,
  HostListener,
  ChangeDetectionStrategy,
  OnInit,
  AfterViewInit,
  OnChanges
} from '@angular/core';
import { D3Service, ForceDirectedGraph } from '../../d3';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { NodeDataAddModalComponent } from 'src/app/pages/node-managements/node-data-add-modal/node-data-add-modal.component';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'graph',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <svg #svg [attr.width]="o_options.width" [attr.height]="o_options.height">
      <g [zoomableOf]="svg">
        <g class="links">
          <g [linkVisual]="link" *ngFor="let link of links"></g>
        </g>
        <g class="nodes">
          <g
            [nodeVisual]="node"
            *ngFor="let node of nodes"
            [draggableNode]="node"
            [draggableInGraph]="graph"
            (click)="openNodeDetails(node)"
          ></g>
        </g>
      </g>
    </svg>
  `,
  styleUrls: ['./graph.component.css']
})
export class GraphComponent implements OnInit, OnChanges, AfterViewInit {
  // tslint:disable-next-line:no-input-rename
  @Input('nodes') nodes;
  // tslint:disable-next-line:no-input-rename
  @Input('links') links;
  graph: ForceDirectedGraph;
  // tslint:disable-next-line:variable-name
  o_options: { width; height } = { width: 800, height: 600 };

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.graph.initSimulation(this.options);
  }

  constructor(private d3Service: D3Service, private ref: ChangeDetectorRef, private modalService: NgbModal) {}

  ngOnInit() {
    /** Receiving an initialized simulated graph from our custom d3 service */
    // this.graph = this.d3Service.getForceDirectedGraph(
    //   this.nodes,
    //   this.links,
    //   this.options
    // );

    /** Binding change detection check on each tick
     * This along with an onPush change detection strategy should enforce checking only when relevant!
     * This improves scripting computation duration in a couple of tests I've made, consistently.
     * Also, it makes sense to avoid unnecessary checks when we are dealing only with simulations data binding.
     */
    // this.graph.ticker.subscribe(d => {
    //   this.ref.markForCheck();
    // });
  }

  ngOnChanges() {
    this.graph = this.d3Service.getForceDirectedGraph(
      this.nodes,
      this.links,
      this.options
    );

    this.graph.ticker.subscribe(d => {
      this.ref.markForCheck();
    });
  }

  ngAfterViewInit() {
    this.graph.initSimulation(this.options);
  }

  get options() {
    return (this.o_options = {
      width: window.innerWidth,
      height: window.innerHeight
    });
  }

  mouseEnter(nodeData: any) {
    const nodes = document.getElementsByClassName('node');
    // tslint:disable-next-line:prefer-for-of
    for (let index = 0; index < nodes.length; index++) {
        const node = nodes[index];
        const nodeId = node.getAttribute('nodeId');
        if (nodeId !== nodeData.id) {
            node.classList.remove('show');
            node.classList.add('blur');
        }
    }

    const nodeNames = document.getElementsByClassName('node-name');
    // tslint:disable-next-line:prefer-for-of
    for (let index = 0; index < nodes.length; index++) {
        const nodeName = nodeNames[index];
        const nodeId = nodeName.getAttribute('nodeId');
        if (nodeId !== nodeData.id) {
            nodeName.classList.remove('show');
            nodeName.classList.add('blur');
        }
    }

    const links = document.getElementsByClassName('link');
    // tslint:disable-next-line:prefer-for-of
    for (let index = 0; index < links.length; index++) {
        const link = links[index];
        const sourceId = link.getAttribute('sourceId');
        const targetId = link.getAttribute('targetId');
        if (sourceId !== nodeData.id && targetId !== nodeData.id) {
            link.classList.remove('show');
            link.classList.add('blur');
        } else {
            const targetNode = document.querySelectorAll('[nodeId="' + targetId + '"]');
            if (targetNode) {
                targetNode.forEach(element => {
                    element.classList.remove('blur');
                    element.classList.add('show');
                });
            }
            if (targetNode) {
                const sourceNode = document.querySelectorAll('[nodeId="' + sourceId + '"]');
                sourceNode.forEach(element => {
                    element.classList.remove('blur');
                    element.classList.add('show');
                });
            }
        }
    }

  }

  mouseLeave(nodeData: any) {
    const nodes = document.getElementsByClassName('node');
    // tslint:disable-next-line:prefer-for-of
    for (let index = 0; index < nodes.length; index++) {
        const node = nodes[index];
        const nodeId = node.getAttribute('nodeId');
        if (nodeId !== nodeData.id) {
            node.classList.remove('blur');
            node.classList.add('show');
        }
    }

    const nodeNames = document.getElementsByClassName('node-name');
    // tslint:disable-next-line:prefer-for-of
    for (let index = 0; index < nodes.length; index++) {
        const nodeName = nodeNames[index];
        const nodeId = nodeName.getAttribute('nodeId');
        if (+nodeId !== nodeData.id) {
            nodeName.classList.remove('blur');
            nodeName.classList.add('show');
        }
    }

    const links = document.getElementsByClassName('link');
    // tslint:disable-next-line:prefer-for-of
    for (let index = 0; index < links.length; index++) {
        const link = links[index];
        const sourceId = link.getAttribute('sourceId');
        const targetId = link.getAttribute('targetId');
        if (+sourceId !== nodeData.id) {
            link.classList.remove('blur');
            link.classList.add('show');
        }
    }
  }

  openNodeDetails(graphNode){
    if (graphNode.type == 'destination') {
      let locationUrl = location.origin;
      let displayName = graphNode.display.split(':');
      let dbName = displayName[0];
      let primarykey = displayName[1];
      let url = encodeURI(locationUrl + '/node-detail/' + dbName + '/' + primarykey).replace(/\(/g,'%28').replace(/\)/g,'%29');

      window.open(url,"_blank")
    }
  }

  selectedNode(data) {
    const ngbModalOptions: NgbModalOptions = {
      backdrop : 'static',
      keyboard : false,
    };
    const modalRef = this.modalService.open(NodeDataAddModalComponent, ngbModalOptions);
    modalRef.componentInstance.isView = true;
    modalRef.componentInstance.nodeId = data.nodeId;
    modalRef.componentInstance.customTitle = data.lable;
    delete data.detail.NodeDataId;
    modalRef.componentInstance.detail = data.detail;
    modalRef.result.then((result) => {

    });
  }
}

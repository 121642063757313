import { filter } from 'rxjs/operators';
import { Component, OnInit } from "@angular/core";
import { NodeService } from "src/app/services/node.service";
import { NodeDataService } from "src/app/services/node-data.service";
import { MappingNodeService } from "src/app/services/mapping-node.service";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { UserService } from "src/app/services/user.service";
import {
  NgbModal,
  NgbModalOptions,
  NgbActiveModal,
} from "@ng-bootstrap/ng-bootstrap";
import { ColumnTable } from "src/app/models/column-table/column-table.component";
import { MappingResultModalComponent } from "../mapping-result-modal/mapping-result-modal.component";
import { ConfirmDialogComponent } from "src/app/base-components/dialogs/confirm-dialog/confirm-dialog.component";
import { ActionModel } from "src/app/models/table-action-model";
import { UserFeedbackModalComponent } from "../../user-feedback-modal/user-feedback-modal.component";
import { Ng2CheckboxComponent } from 'src/app/base-components/ng2-smart-table-custom-field/ng2-smart-table-checkbox.component';
import { Ng2Column, Ng2Setting } from 'src/app/models/ng2-smart-table-model';
import { UserFeedbackService } from 'src/app/services/user-feedback.service';

@Component({
  selector: "app-mapping-viewdetail",
  templateUrl: "./mapping-viewdetail.component.html",
  styleUrls: ["./mapping-viewdetail.component.css"],
})
export class MappingViewdetailComponent implements OnInit {
  data: any;
  title = "Mapping Node";
  nodes = [];
  isSourceReady: boolean;
  isSourceColumnReady: boolean;
  isSourceDataReady: boolean;
  sourceNode: any;
  sourceColumns: any;
  sourceData: any;
  sourceTotal: any;
  sourceLimit = 10;
  sourcePageIndex = 1;
  sourceSelected = 0;
  sourceDataSelected: any;
  isDestinationReady: boolean;
  isDestinationColumnReady: boolean;
  isDestinationDataReady: boolean;
  destinationColumns: any[];
  destinationNode: any;
  destinationLimit = 10;
  destinationPageIndex = 1;
  destinationTotal: any;
  destinationData: any;
  destinationSelected = 0;
  destinationDataSelected = [];
  sourceDataInvalid: boolean;
  destinationDataInvalid: boolean;
  mappingNodeId: any;
  vertical = true;

  disabled = false;
  mode: any;
  isSuggestMapping: any;
  isManualMapping: any;
  isImportMapping: any;
  createBy: any;
  sourceDisabled = [];
  destinationDisabled = [];
  sourceDataFilter: any;
  destinationDataFilter: any;

  isSearch = false;
  sourceSortBy = "";
  sourceOrderBy = "asc";
  destinationSortBy = "";
  destinationOrderBy = "asc";
  sourceSearchText = "";
  destinationSearchText = "";
  actionsMapping = [];
  updateAt = "";
  destinationItemCount = 0;
  hasFeedback:boolean = false;
  feedbackCount = 0;
  locationUrl: string = "";

  settings: Ng2Setting = {};
  dataDemo: any = [];
  //TODO: remove this param when implemented new table
  hideOldTable = false;
  tableScroll = false;
  splitMapping = false;

  constructor(
    private nodeService: NodeService,
    private nodeDataService: NodeDataService,
    private mappingNodeService: MappingNodeService,
    private activatedRoute: ActivatedRoute,
    private toastr: ToastrService,
    private router: Router,
    public userService: UserService,
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    private userFeedbackService: UserFeedbackService
  ) {
    this.disabled = true;
    this.hideOldTable = false;
    this.locationUrl = location.origin;

    if (this.mappingNodeId) {
      this.title = "Edit Mapping Node";
      this.disabled = true;
    }
    this.activatedRoute.data.subscribe((v) => (this.mode = v.mode));
    this.nodeService.getDropDown().subscribe((resNode: any) => {
      this.nodes = resNode;
      if (this.mode === "approve") {
        if (this.mappingNodeId) {
          this.mappingNodeService
            .getForApprove(this.mappingNodeId)
            .subscribe((res: any) => {
              this.data = res;
              this.isSuggestMapping = res.isSuggestMapping;
              this.isManualMapping = res.isManualMapping;
              this.isImportMapping = res.isImportMapping;
              this.createBy = res.createBy;
              this.sourceSelected = res.sourceId;
              this.sourceDataSelected = +res.sourceDataId;
              this.destinationDataSelected = res.destinationDataId.split(",");
              this.selectSource(this.sourceSelected, false);
              this.destinationSelected = res.destinationId;
              this.selectDestination(this.destinationSelected, false);
            });
        }
      } else if (this.mode === "viewDetail") {
        if (this.mappingNodeId) {
          this.disabled = true;
          this.mappingNodeService
            .getApprove(this.mappingNodeId)
            .subscribe((res: any) => {
              this.data = res;
              this.isSuggestMapping = res.isSuggestMapping;
              this.isManualMapping = res.isManualMapping;
              this.isImportMapping = res.isImportMapping;
              this.createBy = res.createBy;
              this.sourceSelected = res.sourceId;
              this.sourceDataSelected = +res.sourceDataId;
              this.destinationDataSelected = res.destinationDataId.split(",");
              this.updateAt = new Date(res.updatedAt).toLocaleDateString("en-GB", { 
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
              });
              this.selectSource(this.sourceSelected, false);
              this.destinationSelected = res.destinationId;
              this.selectDestination(this.destinationSelected, false);
              this.getFeedback();
            });
        }
      } else if (this.mode === "advanceDetail") {
        // this.actionsMapping.push(new ActionModel({
        //   callBack: (data) => this.feedback(data),
        //   type: 'message'
        // }));
        this.disabled = true;
        this.sourceSelected = this.data.SourceId;
        this.sourceDataSelected = this.data.SourceNodeDataId;
        this.destinationDataSelected = this.data.DestinationNodeDataId;
        this.selectSource(this.sourceSelected, false);
        this.destinationSelected = this.data.DestinationId;
        this.selectDestination(this.destinationSelected, false);
      } else {
        this.tableScroll = true;
        if (this.mappingNodeId) {
          this.selectDestinationForEdit(this.mappingNodeId, false);
        }
      }
    });
  }

  ngOnInit() {}

  feedback() {
    const data = this.destinationData[0];
    const ngbModalOptions: NgbModalOptions = {
      backdrop: "static",
      keyboard: false,
    };
    const modalRef = this.modalService.open(
      UserFeedbackModalComponent,
      ngbModalOptions
    );
    modalRef.componentInstance.sourceId = this.data.SourceId;
    modalRef.componentInstance.destinationId = this.data.DestinationId;
    modalRef.componentInstance.sourceNodeDataId = this.data.SourceNodeDataId;
    modalRef.componentInstance.destinationNodeDataId = data.id;
    modalRef.result.then(
      (res: any) => {
        this.toastr.success("Send contact successful");
      },
      (reason) => {}
    );
  }

  getFeedback() {
    this.userFeedbackService.getSpecific(this.data.sourceId, this.data.sourceDataId, this.data.destinationId, this.data.destinationDataId).subscribe((res: any) => {
      this.feedbackCount = res.length;
      if (res.length > 0) {
        this.hasFeedback = true;
      } else {
        this.hasFeedback = false;
      }
    });
  }

  openFeedbackPage() {
    let url = encodeURI(this.locationUrl + '/user-feedback/' + this.data.sourceId + '/' + this.data.sourceDataId + '/' + this.data.destinationId + '/' + this.data.destinationDataId);

    window.open(url,"_blank")  
  }

  closeModal() {
     this.activeModal.close("close");
  }

  selectSource(nodeId, isClear = true) {
    this.sourceSortBy = "";
    this.sourceSelected = nodeId;
    if (isClear) {
      this.sourceDataSelected = null;
    }
    this.sourceColumns = [];
    this.sourceNode = this.nodes.find((x) => x.id === +nodeId);
    this.isSourceColumnReady = false;
    if (!this.mode) {
      this.sourceColumns.push(new ColumnTable("", "", "col", "radio", false));
    }
    this.sourceNode.nodeColumns.forEach((element) => {
      if (this.sourceSortBy === "") {
        this.sourceSortBy = element.name;
      }
      const col = new ColumnTable(
        element.name,
        element.name,
        "col",
        "node-text",
        true,
        element.name
      );
      this.sourceColumns.push(col);
    });
    this.isSourceColumnReady = true;
    if (this.mappingNodeId) {
      this.isSourceDataReady = false;
      const citeria = {
        NodeDataIds: [this.sourceDataSelected],
        Text: this.sourceSearchText,
        Page: this.sourcePageIndex,
        Limit: this.sourceLimit,
        NodeId: this.sourceSelected,
        OrderBy: this.sourceOrderBy,
        SortBy: this.sourceSortBy,
        SourceId: this.sourceSelected,
        DestinationId: this.destinationSelected,
      };
      this.nodeDataService.getData(citeria).subscribe((data: any) => {
        this.sourceData = data.data;
        this.sourceTotal = data.total;
        this.isSourceDataReady = true;
      });
    } else {
      this.loadDataForMapping(true, true);
    }
  }

  onSourceTablePageChange(event) {
    this.isSourceDataReady = false;
    this.sourceLimit = event.pageSize;
    this.sourcePageIndex = event.pageIndex;
    if (this.mappingNodeId) {
      const citeria = {
        NodeDataIds: [this.sourceDataSelected],
        Text: this.sourceSearchText,
        Page: this.sourcePageIndex,
        Limit: this.sourceLimit,
        NodeId: this.sourceSelected,
        OrderBy: this.sourceOrderBy,
        SortBy: this.sourceSortBy,
        SourceId: this.sourceSelected,
        DestinationId: this.destinationSelected,
      };
      this.nodeDataService.getData(citeria).subscribe((data: any) => {
        this.sourceData = data.data;
        this.sourceTotal = data.total;
        this.isSourceDataReady = true;
      });
    } else {
      this.loadDataForMapping(true, false);
    }
  }

  onSourceSorted(event) {
    this.isSourceDataReady = false;
    this.sourceSortBy = event.sortBy;
    this.sourceOrderBy = event.orderBy;
    if (this.mappingNodeId) {
      const citeria = {
        NodeDataIds: [this.sourceDataSelected],
        Text: this.sourceSearchText,
        Page: this.sourcePageIndex,
        Limit: this.sourceLimit,
        NodeId: this.sourceSelected,
        OrderBy: this.sourceOrderBy,
        SortBy: this.sourceSortBy,
        SourceId: this.sourceSelected,
        DestinationId: this.destinationSelected,
      };
      this.nodeDataService.getData(citeria).subscribe((data: any) => {
        this.sourceData = data.data;
        this.sourceTotal = data.total;
        this.isSourceDataReady = true;
      });
    } else {
      this.loadDataForMapping(true, false);
    }
  }

  onSourceSearch(event) {
    this.isSourceDataReady = false;
    this.sourcePageIndex = 1;
    this.sourceSearchText = event.text;
    if (this.mappingNodeId) {
      const citeria = {
        NodeDataIds: [this.sourceDataSelected],
        Text: this.sourceSearchText,
        Page: this.sourcePageIndex,
        Limit: this.sourceLimit,
        NodeId: this.sourceSelected,
        OrderBy: this.sourceOrderBy,
        SortBy: this.sourceSortBy,
        SourceId: this.sourceSelected,
        DestinationId: this.destinationSelected,
      };
      this.nodeDataService.getData(citeria).subscribe((data: any) => {
        this.sourceData = data.data;
        this.sourceTotal = data.total;
        this.isSourceDataReady = true;
      });
    } else {
      this.loadDataForMapping(true, false);
    }
  }

  selectDestination(nodeId, isClear = true) {
    this.destinationSortBy = "";
    this.destinationSelected = nodeId;
    this.destinationColumns = [];
    this.destinationNode = this.nodes.find((x) => x.id === +nodeId);
    this.isDestinationColumnReady = false;
    if (!this.mode) {
      this.destinationColumns.push(
        new ColumnTable("", "", "col", "checkbox", false)
      );
    }
    this.destinationNode.nodeColumns.forEach((element) => {
      if (this.destinationSortBy === "") {
        this.destinationSortBy = element.name;
      }
      const col = new ColumnTable(
        element.name,
        element.name,
        "col",
        "node-text",
        true,
        element.name
      );
      this.destinationColumns.push(col);
    });
    this.isDestinationColumnReady = true;
    if (this.mappingNodeId) {
      this.isDestinationDataReady = false;
      const citeria = {
        NodeDataIds: this.destinationDataSelected,
        Text: this.destinationSearchText,
        Page: this.destinationPageIndex,
        Limit: this.destinationLimit,
        NodeId: this.destinationSelected,
        OrderBy: this.destinationOrderBy,
        SortBy: this.destinationSortBy,
        SourceId: this.sourceSelected,
        DestinationId: this.destinationSelected,
      };
      this.nodeDataService.getData(citeria).subscribe((data: any) => {
        this.destinationData = data.data;
        this.destinationTotal = data.total;
        this.isDestinationDataReady = true;
      });
    } else {
      this.loadDataForMapping(true, true);
    }
  }

  selectDestinationForEdit(mappingNodeId: number, isClear = true) {
    this.hideOldTable= true;

    this.destinationSortBy = "";

    this.isDestinationColumnReady = true;
    if (this.mappingNodeId) {
      this.isDestinationDataReady = false;
      this.nodeDataService
        .DirectQueryByMappingNodeId(mappingNodeId)
        .subscribe((data: any) => {
          if(data && data.dataResultJson && data.dataResultJson.length > 0)
          {
            this.dataDemo = JSON.parse(data.dataResultJson);

            //TODO: set selected from blackend
            this.dataDemo.forEach(element => {
              element["selected"] = false;
            });
            //get date mappingNode
            let mappingNode = data.mappingNode;
            this.updateAt = new Date(data.mappingNode.updatedAt).toLocaleDateString("en-GB", { 
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            });
            this.destinationItemCount = data.mappingNode.destinationDataId.split(',').length;
            this.data = mappingNode;
            this.isSuggestMapping = mappingNode.isSuggestMapping;
            this.isManualMapping = mappingNode.isManualMapping;
            this.isImportMapping = mappingNode.isImportMapping;
            this.createBy = mappingNode.createBy;
            this.sourceSelected = mappingNode.sourceId;
            this.sourceDataSelected = +mappingNode.sourceDataId;
            this.destinationDataSelected = [];
            this.selectSource(this.sourceSelected, false);
            this.destinationSelected = +mappingNode.destinationId;

            this.destinationNode = this.nodes.find((x) => x.id === +this.destinationSelected);

            let column: Ng2Column={};

            if (!this.mode) {
              column["selected"] = {
                title: '',
                type:'custom',
                renderComponent:Ng2CheckboxComponent,
                filter: false
              };
            }

            this.destinationNode.nodeColumns.forEach((element) => {
              column[element.name] = {
                  title: element.name,
                  filter:true
                };

          });
          this.settings = {
            columns :column,
            actions:{
              add: false,
            edit: false,
            delete: false},
            pager:{
              perPage: 10,
              paginateSize: 10
            }
          };
        }
          this.isDestinationDataReady = true;
        });
    } else {
      this.loadDataForMapping(true, true);
    }
  }

  onDestinationTablePageChange(event) {
    this.isDestinationDataReady = false;
    this.destinationLimit = event.pageSize;
    this.destinationPageIndex = event.pageIndex;
    if (this.mappingNodeId) {
      const citeria = {
        NodeDataIds: this.destinationDataSelected,
        Text: this.destinationSearchText,
        Page: this.destinationPageIndex,
        Limit: this.destinationLimit,
        NodeId: this.destinationSelected,
        OrderBy: this.destinationOrderBy,
        SortBy: this.destinationSortBy,
        SourceId: this.sourceSelected,
        DestinationId: this.destinationSelected,
      };
      this.nodeDataService.getData(citeria).subscribe((data: any) => {
        this.destinationData = data.data;
        this.destinationTotal = data.total;
        this.isDestinationDataReady = true;
      });
    } else {
      this.loadDataForMapping(false, true);
    }
  }

  onDestinationSorted(event) {
    this.isDestinationDataReady = false;
    this.destinationSortBy = event.sortBy;
    this.destinationOrderBy = event.orderBy;
    if (this.mappingNodeId) {
      const citeria = {
        NodeDataIds: this.destinationDataSelected,
        Text: this.destinationSearchText,
        Page: this.destinationPageIndex,
        Limit: this.destinationLimit,
        NodeId: this.destinationSelected,
        OrderBy: this.destinationOrderBy,
        SortBy: this.destinationSortBy,
        SourceId: this.sourceSelected,
        DestinationId: this.destinationSelected,
      };
      this.nodeDataService.getData(citeria).subscribe((data: any) => {
        this.destinationData = data.data;
        this.destinationTotal = data.total;
        this.isDestinationDataReady = true;
      });
    } else {
      this.loadDataForMapping(false, true);
    }
  }

  onDestinationSearch(event) {
    this.isDestinationDataReady = false;
    this.destinationPageIndex = 1;
    this.destinationSearchText = event.text;
    if (this.mappingNodeId) {
      const citeria = {
        NodeDataIds: this.destinationDataSelected,
        Text: this.destinationSearchText,
        Page: this.destinationPageIndex,
        Limit: this.destinationLimit,
        NodeId: this.destinationSelected,
        OrderBy: this.destinationOrderBy,
        SortBy: this.destinationSortBy,
        SourceId: this.sourceSelected,
        DestinationId: this.destinationSelected,
      };
      this.nodeDataService.getData(citeria).subscribe((data: any) => {
        this.destinationData = data.data;
        this.destinationTotal = data.total;
        this.isDestinationDataReady = true;
      });
    } else {
      this.loadDataForMapping(false, true);
    }
  }

  onSelectNodeChange(data) {
    this.sourceDataSelected = data;
  }

  loadDataForMapping(loadSource = false, loadDestination = false) {
    if (
      this.sourceSelected !== 0 &&
      this.destinationSelected !== 0 &&
      loadSource
    ) {
      this.isSourceDataReady = false;
      const criteria = {
        Text: this.sourceSearchText,
        Page: this.sourcePageIndex,
        Limit: this.sourceLimit,
        NodeId: this.sourceSelected,
        OrderBy: this.sourceOrderBy,
        SortBy: this.sourceSortBy,
        SourceId: this.sourceSelected,
        DestinationId: this.destinationSelected,
      };
      this.isSourceDataReady = false;
      this.nodeDataService.searchSource(criteria).subscribe((res: any) => {
        this.sourceData = res.data;
        this.sourceTotal = res.total;
        this.isSourceDataReady = true;
      });
    }
    if (
      this.sourceSelected !== 0 &&
      this.destinationSelected !== 0 &&
      loadDestination
    ) {
      this.isDestinationDataReady = false;
      const criteriaDes = {
        Text: this.destinationSearchText,
        Page: this.destinationPageIndex,
        Limit: this.destinationLimit,
        NodeId: this.destinationSelected,
        OrderBy: this.destinationOrderBy,
        SortBy: this.destinationSortBy,
        SourceId: this.sourceSelected,
        DestinationId: this.destinationSelected,
      };
      this.nodeDataService
        .searchDestination(criteriaDes)
        .subscribe((res: any) => {
          this.destinationData = res.data;
          this.destinationTotal = res.total;
          this.isDestinationDataReady = true;
        });
    }

    if (
      this.sourceSelected !== 0 &&
      this.destinationSelected !== 0 &&
      loadSource &&
      loadDestination
    ) {
      this.getDisableInvisibalData();
    }
  }

  create() {
    this.sourceDataInvalid = false;
    if (!this.sourceDataSelected) {
      this.sourceDataInvalid = true;
    }
    this.destinationDataInvalid = false;
    if (this.destinationDataSelected.length === 0) {
      this.destinationDataInvalid = true;
    }
    if (!this.sourceDataInvalid && !this.destinationDataInvalid) {
      let sourceCol = this.nodes
        .find((x) => x.id === +this.sourceSelected)
        .nodeColumns.find((x) => x.isPrimary === true);
      if (!sourceCol) {
        sourceCol = this.nodes.find((x) => x.id === +this.sourceSelected)
          .nodeColumns[0];
      }
      let destinationCol = this.nodes
        .find((x) => x.id === +this.destinationSelected)
        .nodeColumns.find((x) => x.isPrimary === true);
      if (!destinationCol) {
        destinationCol = this.nodes.find(
          (x) => x.id === +this.destinationSelected
        ).nodeColumns[0];
      }

      // tslint:disable-next-line:max-line-length
      const sourceValue = this.sourceData
        .find((x) => x.id === +this.sourceDataSelected)
        .nodeDataDetail.find((x) => x.property === sourceCol.name).value;

      const data = {
        SourceDataId: this.sourceDataSelected,
        SourceId: this.sourceSelected,
        SourceValue: sourceValue,
        DestinationId: this.destinationSelected,
        DestinationDataId: "",
        DestinationValue: "",
      };
      let destinationDataId = "";
      let destinationValue = "";
      this.destinationDataSelected.forEach((element, index, array) => {
        // tslint:disable-next-line:max-line-length
        const value = this.destinationData
          .find((x) => x.id === +element)
          .nodeDataDetail.find((x) => x.property === destinationCol.name).value;
        if (index === array.length - 1) {
          destinationDataId += element;
          destinationValue += value;
        } else {
          destinationDataId += element + ",";
          destinationValue += value + ",";
        }
      });
      data.DestinationDataId = destinationDataId;
      data.DestinationValue = destinationValue;
      this.sourceDataSelected = null;
      this.destinationDataSelected = [];
      this.mappingNodeService.add(data).subscribe((res: any) => {
        const ngbModalOptions: NgbModalOptions = {
          backdrop: "static",
          keyboard: false,
          size: "lg",
        };
        const modalRef = this.modalService.open(
          MappingResultModalComponent,
          ngbModalOptions
        );
        modalRef.componentInstance.data = data;
        modalRef.result.then(
          (modalResult: any) => {
            this.getDisableInvisibalData();
          },
          (reason) => {
            this.getDisableInvisibalData();
          }
        );
      });
    }
  }

  update() {
    this.sourceDataInvalid = false;
    if (!this.sourceDataSelected) {
      this.sourceDataInvalid = true;
    }
    this.destinationDataInvalid = false;

    if (this.splitMapping) 
    {
      this.destinationDataSelected = this.dataDemo.map(d => d.NodeDataId);
    } else if (this.hideOldTable) {
      this.destinationDataSelected = this.dataDemo.filter(d=> d.selected).map(d=> d.NodeDataId);
    }

    if (this.destinationDataSelected.length === 0) {
      this.destinationDataInvalid = true;
    }
    if (!this.sourceDataInvalid && !this.destinationDataInvalid) {
      let sourceCol = this.nodes
        .find((x) => x.id === +this.sourceSelected)
        .nodeColumns.find((x) => x.isPrimary === true);
      if (!sourceCol) {
        sourceCol = this.nodes.find((x) => x.id === +this.sourceSelected)
          .nodeColumns[0];
      }
      let destinationCol = this.nodes
        .find((x) => x.id === +this.destinationSelected)
        .nodeColumns.find((x) => x.isPrimary === true);
      if (!destinationCol) {
        destinationCol = this.nodes.find(
          (x) => x.id === +this.destinationSelected
        ).nodeColumns[0];
      }

      // tslint:disable-next-line:max-line-length
      const sourceValue = this.sourceData
        .find((x) => x.id === +this.sourceDataSelected)
        .nodeDataDetail.find((x) => x.property === sourceCol.name).value;

      const data = {
        Id: this.mappingNodeId,
        SourceDataId: this.sourceDataSelected,
        SourceId: this.sourceSelected,
        SourceValue: this.data.sourceValue,
        DestinationId: this.destinationSelected,
        DestinationDataId: "",
        DestinationValue: "",
      };
      let destinationDataId = "";
      let destinationValue = "";
      this.destinationDataSelected.forEach((element, index, array) => {
        // const des = this.destinationData.find((x) => x.id === +element);
        const oldDesId = this.data.destinationDataId.split(",");
        const oldDesValue = this.data.destinationValue.split(",");
        const desIndex = oldDesId.indexOf(element);
        if (index === array.length - 1) {
          destinationDataId += element;
          destinationValue += oldDesValue[desIndex];
        } else {
          destinationDataId += element + ",";
          destinationValue += oldDesValue[desIndex] + ",";
        }
      });
      data.DestinationDataId = destinationDataId;
      data.DestinationValue = destinationValue;

      if (this.splitMapping) {
        this.mappingNodeService.splitMappingDetails(data).subscribe((res) => {
          this.toastr.success("Split Mapping Details Success");
          this.activeModal.close("update");
        });
        
      } else {
        this.mappingNodeService.update(data).subscribe((res) => {
          this.toastr.success("Update Success");
          this.activeModal.close("update");
        });
      }
    }
  }

  delete() {
    const modalRef = this.modalService.open(ConfirmDialogComponent);
    modalRef.result.then(
      (result) => {
        if (result) {
          this.mappingNodeService.delete(this.mappingNodeId).subscribe(() => {
            this.toastr.success("Delete Success");
            this.activeModal.close("delete");
          });
        }
      },
      (reason) => {}
    );
  }

  deleteApprove() {
    const modalRef = this.modalService.open(ConfirmDialogComponent);
    modalRef.result.then(
      (result) => {
        if (result) {
          this.mappingNodeService
            .deleteApprove(this.mappingNodeId)
            .subscribe(() => {
              this.toastr.success("Delete Success");
              this.activeModal.close("delete");
            });
        }
      },
      (reason) => {}
    );
  }

  approve() {
    const modalRef = this.modalService.open(ConfirmDialogComponent);
    modalRef.componentInstance.type = "approve";
    modalRef.result.then(
      (result) => {
        if (result) {
          this.mappingNodeService.approve(this.data).subscribe(() => {
            this.toastr.success("Approve Success");
            this.activeModal.close("close");
          });
        }
      },
      (reason) => {}
    );
  }

  reject() {
    const modalRef = this.modalService.open(ConfirmDialogComponent);
    modalRef.componentInstance.type = "reject";
    modalRef.result.then(
      (result) => {
        if (result) {
          this.mappingNodeService.reject(this.data).subscribe(() => {
            this.toastr.success("Reject Success");
            this.activeModal.close("close");
          });
        }
      },
      (reason) => {}
    );
  }

  getDisableInvisibalData() {
    if (
      this.sourceSelected &&
      this.sourceSelected !== 0 &&
      this.destinationSelected &&
      this.destinationSelected !== 0
    ) {
      this.mappingNodeService
        .getDisableInvisibalData(this.sourceSelected, this.destinationSelected)
        .subscribe((res: any) => {
          this.sourceDisabled = res.disableData.map((x) => x.sourceDataId);
          this.destinationDisabled = [];
          res.disableData.forEach((element) => {
            element.destinationDataId.split(",").forEach((des) => {
              this.destinationDisabled.push(+des);
            });
          });
        });
    }
  }

}

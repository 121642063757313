import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-filter-search-modal',
  templateUrl: './filter-search-modal.component.html',
  styleUrls: ['./filter-search-modal.component.css']
})
export class FilterSearchModalComponent implements OnInit {

  textCurrentFilter: string = "";
  sources: any[];
  constructor(
    public modal: NgbActiveModal,
  ) {
  }

  ngOnInit() {
  }

  submitFilter() {
    this.closeModal("OK");
  }

  clearFilter() {
    this.sources.forEach(source=>{
        source.isSelected = false;
      });
  }

  selectFilter(source:any) {
    source.isSelected = !source.isSelected;
  }

  closeModal(responseText: string = "close") {
    this.modal.dismiss(responseText);
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class MappingSchemaService {
  private actionUrl = 'api/MappingSchema/';

  constructor(private http: HttpClient) {}

  public getAll<T>(): Observable<T> {
    return this.http.get<T>(this.actionUrl);
  }

  public get<T>(id): Observable<T> {
    return this.http.get<T>(this.actionUrl + id);
  }

  // tslint:disable-next-line:variable-name
  public getBySourceDestination<T>(source_id, destination_id): Observable<T> {
    return this.http.get<T>(this.actionUrl + 'sourceAndDestination?source_id=' + source_id + '&destination_id=' + destination_id);
  }

  // tslint:disable-next-line:variable-name
  public getBySourceOrDestination<T>(nodeId): Observable<T> {
    return this.http.get<T>(this.actionUrl + 'sourceOrDestination/' + nodeId);
  }

  public add<T>(data): Observable<T> {
    return this.http.post<T>(this.actionUrl, data);
  }

  public update<T>(data): Observable<T> {
    return this.http.put<T>(this.actionUrl , data);
  }

  public filterBySource<T>(id): Observable<T> {
    return this.http.get<T>(this.actionUrl + 'filterBySource?sourceId=' + id);
  }
  // tslint:disable-next-line:variable-name
  public delete<T>(source_id, destination_id): Observable<T> {
    return this.http.delete<T>(this.actionUrl + '?source_id=' + source_id + '&destination_id=' + destination_id);
  }
}

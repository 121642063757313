import { filter } from 'rxjs/operators';
import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ColumnTable } from 'src/app/models/column-table/column-table.component';
import { ActionModel } from 'src/app/models/table-action-model';
import {saveAs as importedSaveAs} from 'file-saver';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'table-new',
  templateUrl: './table-new.component.html',
  styleUrls: ['./table-new.component.scss']
})
export class TableNewComponent implements OnInit, OnChanges {
  @Input()
  tableid: string;
  @Input()
  datas: any[];
  @Input()
  disableData = [];
  @Input()
  colunmTable: ColumnTable[];
  @Input()
  actions: ActionModel[];
  @Input()
  selectAction: ActionModel;
  @Input()
  actionDelete: ActionModel;
  @Input()
  actionEdit: ActionModel;
  @Input()
  sortBy: string;
  @Input()
  orderBy = 'asc';
  @Input()
  isShowResult: boolean;
  @Input()
  isShowPage = true;
  @Input()
  isSearch = false;
  @Input()
  selectNode = '';
  @Input()
  selectNodes = [];
  @Input()
  showMappingStatus = false;
  @Input()
  isMapping = false;
  @Input()
  isSelfSort = false;
  @Input()
  isSelfSearch = false;
  @Input()
  isSearchDate = false;
  @Input()
  isDeleteAll = false;
  @Input()
  mappingType = 0;
  @Input()
  isSearchMappingType = false;
  @Input()
  mappingStatus = '';
  @Input()
  isSearchMappingStatus = false;
  @Input()
  tableScroll = false;
  @Input()
  isMappingApproveList = false;

  rawData: any;
  dateSelectFrom: any;
  dateSelectTo: any;
  @Input()
  textSearch = 'asds';

  // MatPaginator Inputs
  @Input()
  total = 100;
  @Input()
  pageIndex = 1;
  @Input()
  pageSize = 10;
  @Input()
  rowClass = '';

  pageSizeOptions: number[] = [5, 10, 25, 100];
  // tslint:disable-next-line:no-output-on-prefix
  @Output()
  onPageChange: EventEmitter<any> = new EventEmitter();
  // tslint:disable-next-line:no-output-on-prefix
  @Output()
  onItemSelected: EventEmitter<any> = new EventEmitter();
  // tslint:disable-next-line:no-output-on-prefix
  @Output()
  onSorted: EventEmitter<any> = new EventEmitter();
  // tslint:disable-next-line:no-output-on-prefix
  @Output()
  onSearch: EventEmitter<any> = new EventEmitter();
  @Output()
  sortChange = new EventEmitter<string>();
  @Output()
  colChange = new EventEmitter<string>();
  // tslint:disable-next-line:no-output-on-prefix
  @Output()
  onSelectNodeChange: EventEmitter<string> = new EventEmitter<string>();
  // tslint:disable-next-line:no-output-on-prefix
  @Output()
  onDataCheck: EventEmitter<any> = new EventEmitter();
  // tslint:disable-next-line:no-output-on-prefix
  @Output()
  onDeleteAll: EventEmitter<any> = new EventEmitter();
  // tslint:disable-next-line:no-output-on-prefix
  @Output()
  onMappingTypeChange: EventEmitter<any> = new EventEmitter();
  // tslint:disable-next-line:no-output-on-prefix
  @Output()
  onDownLoad: EventEmitter<any> = new EventEmitter();
  // tslint:disable-next-line:no-output-on-prefix
  @Output()
  onMappinStatusChange: EventEmitter<any> = new EventEmitter();
  isCheckedAll: boolean;

  constructor(public domSanitizer: DomSanitizer) {}

  ngOnInit() {
    this.rawData = this.datas;
    this.isCheckAll();
  }

  pageChange(event) {
    const changeEvent = {
      pageSize: this.pageSize,
      pageIndex: event
    };
    this.onPageChange.emit(changeEvent);
  }

  pageSizeChange(event) {
    const changeEvent = {
      pageSize: event.value,
      pageIndex: this.pageIndex
    };
    this.onPageChange.emit(changeEvent);
  }

  itemSelected(data) {
    this.onItemSelected.emit(data);
  }

  mappingTypeChange() {
    this.onMappingTypeChange.emit(this.mappingType);
  }

  mappingStatusChange() {
    this.onMappinStatusChange.emit(this.mappingStatus);
  }

  sort(col: string) {
    if (this.sortBy.toLowerCase() === col.toLowerCase() && this.orderBy === 'asc') {
      this.orderBy = 'desc';
    } else {
      this.orderBy = 'asc';
    }
    this.onSorted.emit({orderBy: this.orderBy, sortBy: col});
  }

  search() {
    this.onSearch.emit({text: this.textSearch, dateFrom: this.dateSelectFrom, dateTo: this.dateSelectTo});
  }

  dataSelectedSingle(data) {
    this.selectNode = data;
    this.onSelectNodeChange.emit(this.selectNode);
  }

  dataSelected(event, value) {
    if (event.target.checked) {
      this.selectNodes.push(value);
    } else {
      // tslint:disable-next-line:variable-name
      const index_remove = this.selectNodes.findIndex(ele => {
        return +ele === +value;
      });
      this.selectNodes.splice(index_remove, 1);
    }
    this.onDataCheck.emit(this.selectNodes);
    this.isCheckAll();
  }

  selectedAll(event) {
    if (event.target.checked) {
      this.datas.forEach(element => {
        if (!this.selectNodes.find(x => x == element.id)) {
          this.selectNodes.push(element.id + '');
        }
      });
    } else {
      this.datas.forEach(element => {
        const obj = this.selectNodes.find(x => x == element.id);
        const index = this.selectNodes.indexOf(obj);
        if (index > -1) {
          this.selectNodes.splice(index, 1);
       }
      });
    }
  }

  generateArray(obj) {
    // tslint:disable-next-line:only-arrow-functions
    return Object.keys(obj).map(function(elem) {
      return {key: elem, value: obj[elem]};
    });
  }

  getNodeValue(data, key) {
    const detail = data.nodeDataDetail.find(x => x.property === key);
    let value = (detail) ? detail.value : '';
    if (value) {
      if (value.length > 100) {
        value = value.slice(0, 100) + '...';
      }
    }
    return value;
  }

  onDownloadFile(data, key) {
    const detail = data.nodeDataDetail.find(x => x.property === key);
    const blob = this.FileContenttoBlob(detail.source, detail.fileType);
    importedSaveAs(blob, detail.value);
  }

  dateChange() {
    this.onSearch.emit({text: this.textSearch, dateFrom: this.dateSelectFrom, dateTo: this.dateSelectTo});
  }

  deleteAll() {
    this.onDeleteAll.emit(this.selectNodes);
    this.selectNodes = [];
  }

  splitText(value: string, char: string) {
    return value.split(char);
  }

  download() {
    this.onDownLoad.emit();
  }

  loadFile(data) {
    const byteArray = new Uint8Array(data);
    const blob = this.FileContenttoBlob(data, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
    importedSaveAs(blob, 'Result.xlsx');
  }

  public base64toBlob(base64Data) {
    const sliceSize = 1024;
    const byteCharacters = atob(base64Data);
    const bytesLength = byteCharacters.length;
    const slicesCount = Math.ceil(bytesLength / sliceSize);
    const byteArrays = new Array(slicesCount);

    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      const begin = sliceIndex * sliceSize;
      const end = Math.min(begin + sliceSize, bytesLength);

      const bytes = new Array(end - begin);
      for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays);
  }

  public  FileContenttoBlob(fileContent, fileType) {
    const sliceSize = 1024;
    const byteCharacters = atob(fileContent);
    const bytesLength = byteCharacters.length;
    const slicesCount = Math.ceil(bytesLength / sliceSize);
    const byteArrays = new Array(slicesCount);

    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      const begin = sliceIndex * sliceSize;
      const end = Math.min(begin + sliceSize, bytesLength);

      const bytes = new Array(end - begin);
      for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, { type: fileType});
  }

  ngOnChanges(changes: any) {
    this.isCheckAll();
  }
  isCheckAll() {
    this.isCheckedAll = this.selectNodes.length > 0 && this.datas.every(x => this.selectNodes.findIndex(s => x.id == s) > -1);
  }

}

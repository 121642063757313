import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from './../../services/user.service';
import { Component, OnInit } from '@angular/core';
import * as jwt_decode from 'jwt-decode';
import { Router } from '@angular/router';
import { NewUserModalComponent } from 'src/app/pages/new-user-modal/new-user-modal.component';
import { LoginComponent } from '../../pages/login/login.component';
import { SignUpComponent } from '../../pages/sign-up/sign-up.component';
import { SearchFileModalComponent } from '../../pages/search-file-modal/search-file-modal.component';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.css']
})
export class NavBarComponent implements OnInit {
  // tslint:disable-next-line:ban-types
  currentUser: any;
  isHome: boolean;
  isRedirectSearchFromFile: boolean;
  isRedirectSearch: boolean;

  constructor(
    private userService: UserService,
    private router: Router,
    private modalService: NgbModal
  ) {
    this.userService.currentUser.subscribe(x => this.currentUser = x);
  }

  ngOnInit() {
    this.isHome = sessionStorage.getItem('isHome') == 'true';
    this.isRedirectSearchFromFile = sessionStorage.getItem('isRedirectSearchFromFile') == 'true';
    this.isRedirectSearch = sessionStorage.getItem('isRedirectSearch') == 'true';

  }

  logout() {
    this.userService.logout();
    this.router.navigate(['']);
  }

  profile() {
    const ngbModalOptions: NgbModalOptions = {
      backdrop : 'static',
      keyboard : false,
      centered: true,
      windowClass: 'modal-invite-new-people',
    };
    const modalRef = this.modalService.open(
      NewUserModalComponent,
      ngbModalOptions
    );

    modalRef.componentInstance.id = this.currentUser.id;
    modalRef.componentInstance.isUpdate = true;
    modalRef.componentInstance.isAdmin = false;
    modalRef.componentInstance.title = 'User Profile';
    modalRef.result.then((res: any) => {
    }, (reason) => {
        if (reason !== 'close') {
           
        }
    });
  }

  checkRole(roles) {
    const token = this.currentUser.token;
    const decoded = jwt_decode(token);
    const userRole = decoded.role;
    return roles.indexOf(userRole) !== -1;
  }

  redirectSearch(){
    sessionStorage.setItem('isRedirectSearchFromFile', 'false');
    sessionStorage.setItem('isRedirectSearch', 'true');
  }
  redirectSearchFromFile(){
    sessionStorage.setItem('isRedirectSearchFromFile', 'true');
    sessionStorage.setItem('isRedirectSearch', 'false');
  }

  signIn() {
    const ngbModalOptions: NgbModalOptions = {
      backdrop : true,
      keyboard : false,
    };
    const modalRef = this.modalService.open(
      LoginComponent, ngbModalOptions
    );
    modalRef.result.then((result) => {
    }, (reason) => {
      if (reason === 'Success') {
        this.userService.getAll().subscribe(res => {
        });
      }
    });
  }
  signUp() {
    const ngbModalOptions: NgbModalOptions = {
      backdrop : true,
      keyboard : false,
    };
    const modalRef = this.modalService.open(
      SignUpComponent, ngbModalOptions
    );
    modalRef.result.then((result) => {
    }, (reason) => {
      if (reason === 'Success') {
        this.userService.getAll().subscribe(res => {
        });
      }
    });
  }

  searchFromFile() {
    const ngbModalOptions: NgbModalOptions = {
      backdrop : true,
      keyboard : false,
    };
    const modalRef = this.modalService.open(
      SearchFileModalComponent, ngbModalOptions
    );
    modalRef.result.then((result) => {
    }, (reason) => {
      if (reason === 'Success') {
        this.userService.getAll().subscribe(res => {
        });
      }
    });
  }
}

import { Component, OnInit } from "@angular/core";
import { NodeService } from "src/app/services/node.service";
import { NodeDataService } from "src/app/services/node-data.service";
import { UserService } from "src/app/services/user.service";
import { MappingNodeService } from 'src/app/services/mapping-node.service';
import {
  NgbModal,
  NgbModalOptions,
  NgbActiveModal,
} from "@ng-bootstrap/ng-bootstrap";
import { Ng2Column, Ng2Setting } from 'src/app/models/ng2-smart-table-model';
import { element } from "protractor";

@Component({
  selector: "app-mapping-preview",
  templateUrl: "./mapping-preview.component.html",
  styleUrls: ["./mapping-preview.component.css"],
})
export class MappingPreviewComponent implements OnInit {
  isDestinationColumnReady: boolean;
  isDestinationDataReady: boolean;
  isSourceColumnReady: boolean;
  isSourceDataReady: boolean;
  nodeId: any;
  vertical = true;
  destinationNode: any;
  sourceDropdown = [];
  nodeDetails;
  desNodeDetails;
  isSourceReady: boolean;
  sourceSelected = 0;
  sourceCondition: any;
  destinationCondition: any;
  settings: object = {};
  settings2: object = {};
  destinationData: any;
  sourceData: any;
  nodes = [];
  desNodes = [];
  destinationCount = 0;
  betweenCodition;
  datas = [];
  mapData;
  isSplitMapping:boolean = false;

  constructor(
    private nodeService: NodeService,
    private nodeDataService: NodeDataService,
    public userService: UserService,
    private mappingNodeService: MappingNodeService,
    public activeModal: NgbActiveModal
  ) {
    this.isSourceDataReady = false;
  }

  ngOnInit() {
    this.getSourceDropdown();
  }

  closeModal() {
    this.activeModal.close("close");
  }

  getSourceDropdown() {
      this.sourceDropdown = this.datas;
      if (this.sourceDropdown) {
        this.isSourceDataReady = true;
        this.sourceSelected = this.sourceDropdown[0].sourcePrimaryKeyData;
        this.selectSource();
      }
  }

  selectSource() {
    let node = this.sourceDropdown.find((x) => x.sourcePrimaryKeyData == this.sourceSelected);
    this.nodeService.getDropDown().subscribe((resNode: any) => {
      this.nodes = resNode;
      this.nodeDetails = this.nodes.find((x) => x.id === +this.nodeId);

      this.nodeDataService.getAllByNodeDataId(this.nodeId, node.sourceDataId).subscribe((res: any) => {
        this.sourceData = res;
        let column: Ng2Column = {};

        this.nodeDetails.nodeColumns.forEach((element) => {
          column[element.name] = {
            title: element.name,
            filter: false
          };
        });

        this.settings = {
          mode: 'external',
          columns: column,
          actions: {
            add: false,
            edit: false,
            delete: false,
            position: 'right'
          },
          pager: {
            perPage: 10,
            paginateSize: 10
          }
        };
        this.isSourceDataReady = true;
      });
      this.generateSemanticMapping(node.destinationDataId)
    });
  }

  generateSemanticMapping(nodeDataId) {
    this.nodeService.getDropDown().subscribe((respNode: any) => {
      this.desNodes = respNode;
      this.desNodeDetails = this.desNodes.find((x) => x.id === +this.destinationNode)

      this.nodeDataService.generatePreview(this.destinationNode, nodeDataId).subscribe((res: any) => {
        this.destinationData = res;
        if (res) {
          this.destinationCount = this.destinationData.length;
        }

        let column2: Ng2Column = {};

        this.desNodeDetails.nodeColumns.forEach((element) => {
          column2[element.name] = {
            title: element.name,
            filter: false
          };
        });

        this.settings2 = {
          mode: 'external',
          columns: column2,
          actions: {
            add: false,
            edit: false,
            delete: false,
            position: 'right'
          },
          pager: {
            perPage: 10,
            paginateSize: 10
          }
        };
        this.isDestinationDataReady = true;
        this.isDestinationColumnReady = true;
      });
    });
  }

}

import { NotifyService } from './services/notify.service';
import { Component } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Injectable } from '@angular/core';
import * as jwt_decode from 'jwt-decode';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  showSidebar$: Observable<boolean>;
  private defaultShowSidebar = false;
  toggleSidebar = true;
  constructor(
    private notifyService: NotifyService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private jwtHelper: JwtHelperService,
  ) {
    this.subscribeToEvents();
    this.showSidebar$ = this.router.events.pipe(
      filter(e => e instanceof NavigationEnd),
      map(() => activatedRoute),
      map(route => {
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      }),
      mergeMap(route => route.data),
      map(data => data.hasOwnProperty('showSideBar') ? data.showSideBar : this.defaultShowSidebar),
    );
  }

  private subscribeToEvents(): void {
    this.notifyService.getAll();
    this.notifyService.showSidebar.subscribe(res => {
      if (res === 'in') {
        this.toggleSidebar = false;
      } else {
        this.toggleSidebar = true;
      }
    });
  }
}

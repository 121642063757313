import { Component, OnInit } from '@angular/core';
import { MappingNodeService } from 'src/app/services/mapping-node.service';
import { ToastrService } from 'ngx-toastr';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { MappingSuggessModalComponent } from '../mapping-suggess-modal/mapping-suggess-modal.component';
import { NotifyService } from 'src/app/services/notify.service';

@Component({
  selector: 'app-mapping-waiting-approve',
  templateUrl: './mapping-waiting-approve.component.html',
  styleUrls: ['./mapping-waiting-approve.component.scss']
})
export class MappingWaitingApproveComponent implements OnInit {
  data: [];

  constructor(
    private mappingNodeService: MappingNodeService,
    private toastr: ToastrService,
    private modalService: NgbModal,
    private notifyService: NotifyService) { }

  ngOnInit() {
    sessionStorage.setItem('isHome', 'false');
    sessionStorage.setItem('isRedirectSearchFromFile', 'false');

    this.mappingNodeService.getAllWaitingApprove().subscribe((res: []) => {
      this.data = res;
    });
  }

  suggessMapping() {
    const ngbModalOptions: NgbModalOptions = {
      backdrop : 'static',
      keyboard : false,
    };
    const modalImport = this.modalService.open(MappingSuggessModalComponent, ngbModalOptions);
    modalImport.result.then((result) => {
      this.mappingNodeService.getAllWaitingApprove().subscribe((res: []) => {
        this.data = res;
      });
    });
  }

}

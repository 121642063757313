import { MappingWaitingApproveModalComponent } from './../../mapping-waiting-approve-modal/mapping-waiting-approve-modal.component';
import { Component, OnInit } from '@angular/core';
import { ColumnTable } from 'src/app/models/column-table/column-table.component';
import { MappingNodeService } from 'src/app/services/mapping-node.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ActionModel } from 'src/app/models/table-action-model';
import { ConfirmDialogComponent } from 'src/app/base-components/dialogs/confirm-dialog/confirm-dialog.component';
import { MappingImportModalComponent } from '../mapping-import-modal/mapping-import-modal.component';
import { MappingSuggessModalComponent } from '../mapping-suggess-modal/mapping-suggess-modal.component';
import { saveAs as importedSaveAs } from 'file-saver';
import { Ng2Column } from 'src/app/models/ng2-smart-table-model';
import { LoadingScreenService } from 'src/app/services/loading-screen.service';
import { NodeDataService } from 'src/app/services/node-data.service'; 

@Component({
  selector: 'app-mapping-waiting-approve-list',
  templateUrl: './mapping-waiting-approve-list.component.html',
  styleUrls: ['./mapping-waiting-approve-list.component.css']
})
export class MappingWaitingApproveListComponent implements OnInit {

  sourceId: any;
  destinationId: any;
  data: any;
  columns: ColumnTable[];
  total: any;
  limit = 10;
  pageIndex = 1;
  isReady: boolean;
  actions = [];
  source: any;
  destination: any;
  sortBy = 'source';
  orderBy = 'asc';
  isSearch = true;
  isMapping = true;
  searchText: any;
  mappingType = '0';
  settings: Object = {};
  dataTable = [];
  isPaginate: boolean;
  dataSelected;

  constructor(
    private mappingNodeService: MappingNodeService,
    private activatedRoute: ActivatedRoute,
    private nodeDataService: NodeDataService,
    private router: Router,
    private toastr: ToastrService,
    private modalService: NgbModal,
    private loadingScreenService: LoadingScreenService) {
      this.activatedRoute.queryParams.subscribe(params => {
        this.sourceId = activatedRoute.snapshot.params.source_id;
        this.destinationId = activatedRoute.snapshot.params.destination_id;
      });
    }

  ngOnInit() {
    sessionStorage.setItem('isHome', 'false');
    sessionStorage.setItem('isRedirectSearchFromFile', 'false');

    this.loadingScreenService.loadingScreen.emit(true);
    this.loadSmartTable();
  }

  onUserRowSelect(event){
    this.dataSelected = event.selected;
  }

  approveAllCheck(){
    let approveDataList = [];
    if (this.dataSelected && this.dataSelected.length > 0) {
      this.dataSelected.forEach(element => {
        this.nodeDataService
          .DirectQueryByMappingNodeId(element.id, true)
          .subscribe((data: any) => {
            if(data && data.dataResultJson && data.dataResultJson.length > 0)
            {
              let mappingNode = data.mappingNode;
              mappingNode.dataList = data.dataList;
              let dataApprove = mappingNode;
              
              const ids = dataApprove.dataList.map(x => x.id);
              const approveData = {SourceId: dataApprove.sourceId, DestinationId: dataApprove.destinationId , MappingIds: ids};
              approveDataList.push(approveData);
            }
          });
      });

      const modalRef = this.modalService.open(ConfirmDialogComponent);
      modalRef.componentInstance.type = 'approveAllCheck';
      modalRef.result.then((result) => {
        if (result) {
          this.mappingNodeService.approveAllCheck(approveDataList).subscribe(() => {
            this.toastr.success('Approve Success');

            let newDataTable = this.dataTable;
            this.loadingScreenService.loadingScreen.emit(true);
            approveDataList.forEach(element => {
              let index = newDataTable.findIndex(prop => prop.id == element.MappingIds[0]);
              newDataTable.splice(index, 1);
            })
            this.reloadTable(newDataTable);
            this.dataSelected = [];
          });
        }
      }, (reason) => {
      });
    } else {
      this.toastr.info('Please select at least 1 item');
    }
  }

  approveAll() {
    const approveModel = { SourceId: this.sourceId, DestinationId: this.destinationId };
    const modalRef = this.modalService.open(ConfirmDialogComponent);
    modalRef.componentInstance.type = 'approveAll';
    modalRef.result.then((result) => {
      if (result) {
        this.loadingScreenService.loadingScreen.emit(true);
        this.mappingNodeService.approveAllInDB(approveModel).subscribe(() => {
          this.toastr.success('Approve Success');
          this.loadSmartTable();
          this.loadingScreenService.loadingScreen.emit(false);
        });
      }
    }, (reason) => {
      this.loadingScreenService.loadingScreen.emit(false);
    });
  }

  loadSmartTable() {
    this.dataTable = [];
    this.isReady = false;

    const criteria = {
      Text: this.searchText,
      Page: this.pageIndex,
      Limit: this.limit,
      OrderBy: this.orderBy,
      SortBy: this.sortBy,
      SourceId: this.sourceId,
      DestinationId: this.destinationId,
      IsSuggest: false,
      IsImport: false,
      IsManual: false,
    };
    if (this.mappingType === '1') {
      criteria.IsSuggest = true;
    } else if (this.mappingType === '2') {
      criteria.IsImport = true;
    } else if (this.mappingType === '3') {
      criteria.IsManual = true;
    } else {
      criteria.IsSuggest = true;
      criteria.IsImport = true;
      criteria.IsManual = true;
    }

    this.mappingNodeService.SearchBySourceAndDestinationWaitingApprove(criteria).subscribe((res: any) => {
      this.data = res.data.data;
      this.source = res.data.source;
      this.destination = res.data.destination;
      this.total = res.total;
      if (this.total > 10) 
      {
        this.isPaginate = true;
      }
      else 
      {
        this.isPaginate = false;
      }
      let column = {
        source: {
          title: 'Source',
          filter: false
        },destination: {
          title: 'Destination',
          filter: false
        },mappingtype: {
          title: 'Mapping Type',
          filter: false,
          class: 'text-center',
          type: 'html',
          valuePrepareFunction: (data) => { 
            return '<div class="text-center">' + data + '</div>'; 
          }
        },
      };
  
      res.data.data.forEach(element => {
        let icon = '';
        element.dataList.forEach(val => {
          icon += val.isImportMapping ? '<i class="fas fa-file-import"></i> ': val.isManualMapping ? '<i class="fas fa-user-check"></i> ': '<i class="fas fa-robot"></i> '
        });

        // let destinationCount = element.destinationDataId.split(",");
        // let date_ob = new Date(element.dataList[0].createdAt);
        // let date = ("0" + date_ob.getDate()).slice(-2);
        // let month = date_ob.toLocaleDateString('default', { month: 'short' })
        // let year = date_ob.getFullYear();
        // let hours = date_ob.getHours();
        // let minutes = (date_ob.getMinutes()<10?'0':'') + date_ob.getMinutes();

        this.dataTable.push({
          source: element.source.name + ': ' +  element.sourceValue,
          destination: element.destinationValue ? 
            element.source.name + ': '  + element.destinationValue + ': '+ element.destinationPrimaryValue  :
            element.destinationPrimaryValue,
          mappingtype: icon,
          // createdate: date + " " + month + " " + year + " " + hours + ":" + minutes,
          id : element.id
        })
      });
  
      this.settings = {
        selectMode : 'multi',
        mode: 'external',
        columns: column,
        actions: {
          columnTitle: ' ',
          add: false,
          edit: false,
          delete: false,
          custom: [
            {
              name: 'edit',
              title: '<label Class = "icon-view"><i class="fas fa-file-alt text-warning"></i> View </label>'
            },
            {
              name: 'approve',
              title: '<label Class = "icon-approve"><i class="fas fa-check-circle icon-approve-color"></i> Approve </label>'
            },
            {
              name: 'reject',
              title: '<label Class = "icon-reject"><i class="fas fa-times-circle icon-reject-color"></i> Reject</label>'
            }
          ],
          position: 'right'
        },
        pager: {
          perPage: 10,
          paginateSize: 10
        }
      };
      this.isReady = true;
      this.loadingScreenService.loadingScreen.emit(false);
    });
    
  }

  reloadTable(data: any) {
    this.dataTable = [];
    this.isReady = false;

    const criteria = {
      Text: this.searchText,
      Page: this.pageIndex,
      Limit: this.limit,
      OrderBy: this.orderBy,
      SortBy: this.sortBy,
      SourceId: this.sourceId,
      DestinationId: this.destinationId,
      IsSuggest: false,
      IsImport: false,
      IsManual: false,
    };
    if (this.mappingType === '1') {
      criteria.IsSuggest = true;
    } else if (this.mappingType === '2') {
      criteria.IsImport = true;
    } else if (this.mappingType === '3') {
      criteria.IsManual = true;
    } else {
      criteria.IsSuggest = true;
      criteria.IsImport = true;
      criteria.IsManual = true;
    }

    this.total = data.length;
    if (this.total > 10) {
      this.isPaginate = true;
    }
    else {
      this.isPaginate = false;
    }
    let column = {
      source: {
        title: 'Source',
        filter: false
      }, destination: {
        title: 'Destination',
        filter: false
      }, mappingtype: {
        title: 'Mapping Type',
        filter: false,
        class: 'text-center',
        type: 'html',
        valuePrepareFunction: (data) => {
          return '<div class="text-center">' + data + '</div>';
        }
      },
    };

    this.dataTable = data;

    this.settings = {
      selectMode : 'multi',
      mode: 'external',
      columns: column,
      actions: {
        columnTitle: ' ',
        add: false,
        edit: false,
        delete: false,
        custom: [
          {
            name: 'edit',
            title: '<label Class = "icon-view"><i class="fas fa-file-alt text-warning"></i> View </label>'
          },
          {
            name: 'approve',
            title: '<label Class = "icon-approve"><i class="fas fa-check-circle icon-approve-color"></i> Approve </label>'
          },
          {
            name: 'reject',
            title: '<label Class = "icon-reject"><i class="fas fa-times-circle icon-reject-color"></i> Reject</label>'
          }
        ],
        position: 'right'
      },
      pager: {
        perPage: 10,
        paginateSize: 10
      }
    };
    this.isReady = true;
    this.loadingScreenService.loadingScreen.emit(false);
  }

  approve(dataApprove: any) {
    const ids = dataApprove.dataList.map(x => x.id);
    const approveData = {SourceId: dataApprove.sourceId, DestinationId: dataApprove.destinationId , MappingIds: ids};
    const modalRef = this.modalService.open(ConfirmDialogComponent);
    modalRef.componentInstance.type = 'approve';
    modalRef.result.then((result) => {
      if (result) {
        this.mappingNodeService.approve(approveData).subscribe(() => {
          this.toastr.success('Approve Success');

          this.loadingScreenService.loadingScreen.emit(true);
          let index = this.dataTable.findIndex(prop => prop.id == dataApprove.id);
          let newDataTable = this.dataTable
          newDataTable.splice(index, 1);
          this.reloadTable(newDataTable);
        });
      }
    }, (reason) => {
    });
  }

  reject(dataReject: any) {
    const ids = dataReject.dataList.map(x => x.id);
    const rejectData = {SourceId: dataReject.sourceId, DestinationId: dataReject.destinationId , MappingIds: ids};
    const modalRef = this.modalService.open(ConfirmDialogComponent);
    modalRef.componentInstance.type = 'reject';
    modalRef.result.then((result) => {
      if (result) {
        this.mappingNodeService.reject(rejectData).subscribe(() => {
          this.toastr.success('Reject Success');

          this.loadingScreenService.loadingScreen.emit(true);
          let index = this.dataTable.findIndex(prop => prop.id == dataReject.id);
          let newDataTable = this.dataTable
          newDataTable.splice(index, 1);
          this.reloadTable(newDataTable);
        });
      }
    }, (reason) => {
    });
  }

  viewDetail(data: any) {
    const ngbModalOptions: NgbModalOptions = {
      backdrop : 'static',
      keyboard : false,
      size: 'lg',
      windowClass: 'waiting-approve-view-detail',
    };
    const modalImport = this.modalService.open(MappingWaitingApproveModalComponent, ngbModalOptions);
    modalImport.componentInstance.mappingNodeId = data.data.id;
    modalImport.componentInstance.mode = 'approve';
    modalImport.result.then((result) => {
      if (result == 'reject' || result == 'approve') 
      {
        this.loadingScreenService.loadingScreen.emit(true);
        let index = this.dataTable.findIndex(prop => prop.id == data.data.id);
        let newDataTable = this.dataTable
        newDataTable.splice(index, 1);
        this.reloadTable(newDataTable);
      }
    });
  }

  suggessMapping() {
    const modalImport = this.modalService.open(MappingSuggessModalComponent);
    modalImport.componentInstance.sourceId = this.sourceId;
    modalImport.componentInstance.destinationId = this.destinationId;
    modalImport.result.then((result) => {
      this.loadSmartTable();
    });
  }

  onDownLoad() {
    const criteria = {
      Text: this.searchText,
      Page: this.pageIndex,
      Limit: this.limit,
      OrderBy: this.orderBy,
      SortBy: this.sortBy,
      SourceId: this.sourceId,
      DestinationId: this.destinationId,
      IsSuggest: false,
      IsImport: false,
      IsManual: false,
    };
    if (this.mappingType === '1') {
      criteria.IsSuggest = true;
    } else if (this.mappingType === '2') {
      criteria.IsImport = true;
    } else if (this.mappingType === '3') {
      criteria.IsManual = true;
    } else {
      criteria.IsSuggest = true;
      criteria.IsImport = true;
      criteria.IsManual = true;
    }

    this.mappingNodeService.downloadBySourceAndDestinationWaitingApprove(criteria).subscribe((res: any) => {
      importedSaveAs(res, 'MappingWaitingAprove');
    });
  }

  onCustomAction(data: any){
    if (data.action == 'edit') {
      this.viewDetail(data);
    } else if (data.action == 'approve') {
      this.nodeDataService
        .DirectQueryByMappingNodeId(data.data.id, true)
        .subscribe((data: any) => {
          if(data && data.dataResultJson && data.dataResultJson.length > 0)
          {
            //get date mappingNode
            let mappingNode = data.mappingNode;
            mappingNode.dataList = data.dataList;
            let dataApprove = mappingNode;
            this.approve(dataApprove);
          }
        });
      
    } else if (data.action == 'reject') {
      this.nodeDataService
        .DirectQueryByMappingNodeId(data.data.id, true)
        .subscribe((data: any) => {
          if(data && data.dataResultJson && data.dataResultJson.length > 0)
          {
            //get date mappingNode
            let mappingNode = data.mappingNode;
            mappingNode.dataList = data.dataList;
            let dataReject = mappingNode;
            this.reject(dataReject);
          }
        });
      
    }
  }
}

import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NodeService } from 'src/app/services/node.service';
import { NodeDataService } from 'src/app/services/node-data.service';
import { Ng2TooltipComponent } from 'src/app/base-components/ng2-smart-table-custom-field/ng2-smart-table-tooltips.component';

@Component({
  selector: 'app-new-user-modal',
  templateUrl: './remaining-item-modal.component.html',
  styleUrls: ['./remaining-item-modal.component.css']
})
export class RemainingItemModalComponent implements OnInit {
  sourceNodeId;
  destinationNodeId;
  sourceDropDown;
  isSourceColumnReady;
  isSourceDataReady;
  sourceData;
  sourceNode;
  nodes;
  sourceSetting;
  sourceDetailSelected;

  constructor(
    public modal: NgbActiveModal,
    private nodeService: NodeService,
    private nodeDataService: NodeDataService
  ) {
    this.nodeService.getDropDown().subscribe((resNode: any) => {
      this.nodes = resNode;
      this.getSourceTable();
    });
  }

  ngOnInit() {
  }


  closeModal(event: string) {
    this.modal.close(event);
  }

  getSourceTable() {
    this.isSourceColumnReady = false;
    this.isSourceDataReady = false;
    this.sourceDetailSelected = this.sourceNodeId;
    this.nodeDataService.getRemainingSource(this.sourceNodeId, this.destinationNodeId).subscribe((res: any) => {
      this.sourceData = res.sort((a, b) => (a.NodeDataId > b.NodeDataId ? 1 : ((b.NodeDataId > a.NodeDataId) ? -1 : 0)));
      this.isSourceDataReady = true;
      this.sourceNode = this.nodes.find(x => x.id === +this.sourceNodeId);

     
      let column = {};

      this.sourceNode.nodeColumns.forEach((element) => {
        column[element.name] = {
          title: element.name,
          filter: true,
          type: 'custom',
          renderComponent:Ng2TooltipComponent,
          }
      });

      this.sourceSetting = {
        mode: 'external',
        columns: column,
        actions: {
          add: false,
          edit: false,
          delete: false,
          position: 'right'
        },
        pager: {
          perPage: 10,
          paginateSize: 10
        }
      };
      this.isSourceColumnReady = true;
    });
  }

}

import { MappingSchemaService } from 'src/app/services/mapping-schema.service';
import { UserService } from 'src/app/services/user.service';
import { ToastrService } from 'ngx-toastr';
import { NodeDataService } from './../../../services/node-data.service';
import { Component, OnInit } from '@angular/core';
import { NodeService } from 'src/app/services/node.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal, NgbActiveModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormControl, Validators, AbstractControl, ValidationErrors } from '@angular/forms';
import { Observable } from 'rxjs';
import { debounceTime, map, filter, skip } from 'rxjs/operators';
import { ConfirmDialogComponent } from 'src/app/base-components/dialogs/confirm-dialog/confirm-dialog.component';
import { SettingService } from 'src/app/services/setting.service';
import { Location } from '@angular/common';
import APP_CONFIG from './../../../app.config';
import { Node, Link } from './../../../d3';
import { UserFeedbackModalComponent } from "../../user-feedback-modal/user-feedback-modal.component";
import { LoadingScreenService } from 'src/app/services/loading-screen.service';

@Component({
  selector: 'app-node-detail',
  templateUrl: './node-detail.component.html',
  styleUrls: ['./node-detail.component.css']
})
export class NodeDetailComponent implements OnInit {

  DBName: any;
  PrimaryKey: any;
  form: FormGroup;
  isReady = false;
  title = '';
  primaryKeyName = [];
  primaryKeyId = [];
  detail: any;
  detailCol = [];
  nodeDescription = {};
  nodeColumndetail: any;
  displayPrimaryKeyName = '';
  isInitGraph: boolean;
  nodes: any[];
  links: any[];
  lables: any[];
  mappingData: any;
  resultData: any;
  nodeDataId: any;
  currentPage = 1;
  locationUrl: string = "";
  degree = 1;

  constructor(private nodeService: NodeService,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              private mappingSchemaService: MappingSchemaService,
              private modalService: NgbModal,
              public activeModal: NgbActiveModal,
              private nodeDataService: NodeDataService,
              private toastr: ToastrService,
              private userService: UserService,
              private settingService: SettingService,
              private loadingScreenService: LoadingScreenService,
              // tslint:disable-next-line:variable-name
              private _location: Location) {
    this.activatedRoute.queryParams.subscribe(params => {
        this.DBName = activatedRoute.snapshot.params.name;
        this.PrimaryKey = activatedRoute.snapshot.params.id;
    });
  }

  ngOnInit() {
    sessionStorage.setItem('isHome', 'true');
    sessionStorage.setItem('isRedirectSearchFromFile', 'false');
    
    this.locationUrl = location.origin;
    this.PrimaryKey = this.PrimaryKey.replace(/\%2F/g, '/');

    this.title = 'Node data detail';
    if (this.DBName) {
          this.title = this.DBName;
        }
    if (this.PrimaryKey) {
          this.primaryKeyId = this.PrimaryKey.split('-');
        }

    const NodeDataDetails = {
      DBName: this.DBName,
      PrimaryKey: this.PrimaryKey
    };

    const group = {};
    this.form = new FormGroup(group);
    this.isReady = true;
    this.form.disable();

    const criteriaNodeColumns = {
      DBName: this.DBName
    };
    this.nodeDataService.GetNodeColumns(criteriaNodeColumns).subscribe((res: any) => {
      this.nodeColumndetail = res;

      this.nodeColumndetail.forEach(element => {
        this.nodeDescription[element.name] = element.description;
      });
      this.isReady = false;
      // tslint:disable-next-line:no-shadowed-variable
      const group = {};

      // tslint:disable-next-line:no-shadowed-variable
      this.nodeDataService.GetNodeDataDetails(NodeDataDetails).subscribe((res: any) => {
        if (res) {
          this.detail = res;
          this.nodeDataId = res[0].nodeDataId;
          this.loadDataRelation(this.currentPage, 10);
          this.loadGraphRelation();
        } else {
          this.detail = {};
        }

        this.nodeColumndetail.forEach(element => {

          if (element.isPrimary) {
            this.primaryKeyName.push(element.name);
          }

          const nodeDatail =  this.detail.find(x => x.property === element.name);
          if (nodeDatail) {
            group[nodeDatail.property] = new FormControl(nodeDatail.value);
            this.detailCol.push({value : nodeDatail.property, isLongText: nodeDatail.property.length >= 50});
          }
        });

        for (let i = 0; i < this.primaryKeyName.length; i++) {
          if (i > 0) {
            this.displayPrimaryKeyName += ', ';
          }
          this.displayPrimaryKeyName += this.primaryKeyName[i] + ': ' + this.primaryKeyId[i];
        }

        this.form = new FormGroup(group);
        this.isReady = true;
        this.form.disable();

      });
    });
  }

  loadDataRelation(pageIndex, pageSize = 10) {
    this.resultData = null;

    const criteria = {
      Page: pageIndex,
      Limit: pageSize,
      SourceId: this.nodeDataId
    };

    this.nodeDataService.searchEngineRelation(criteria).subscribe((res: any) => {
      if (res) {
        this.resultData = res;
      } else {
        this.resultData = {};
      }
    }, (err: any) => {
      this.resultData = {};
    });
  }

  onTablePageChange(event) {
    this.loadDataRelation(event);
  }

  onSubmit() {
  }

  backClicked() {
    this._location.back();
  }

  loadGraphRelation() {
    this.loadingScreenService.loadingScreen.emit(true);
    const criteria = {
      NodeId: this.nodeDataId,
      Degree: this.degree
    };
    this.nodeDataService.searchgraphRelation(criteria).subscribe((res: any) => {
      if (res) {
        this.mappingData = res;
        this.initGraph();
      } else {
        this.mappingData = [];
      }
    }, (err: any) => {
      this.mappingData = [];
    });
  }

  initGraph() {
    this.isInitGraph = false;
    const N = APP_CONFIG.N;
    this.nodes = [];
    this.links = [];
    this.lables = [];
    const nodeNames = [];
    let sourceColor = '#047500';
    let nodeDatabaseColor = '#007bff';
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.mappingData.length; i++) {
      const data = this.mappingData[i];
      let destinationIds = data.destinationDataId.split(',');
      let destinationValues = data.destinationValue.split(',');
      let sourceLable = this.lables.find(x => x.lable === data.sourceId);
      let destinationLable = this.lables.find(x => x.lable === data.destinationId);
      if (!sourceLable) {
        sourceLable = {
          lable: data.sourceId,
          color: this.getRandomColor(),
        };
      }
      if (!destinationLable) {
        destinationLable = {
          lable: data.destinationId,
          color: this.getRandomColor(),
        };
      }
      // tslint:disable-next-line:triple-equals
      let source = this.nodes.find(x => x.id == data.sourceDataId);
      if (!source) {
        // tslint:disable-next-line:max-line-length
        source = new Node(data.sourceDataId, data, data.sourceValue, sourceColor, data.source.name + ':' + data.sourceValue, data.sourceId, 'source');
        this.nodes.push(source);
      }
      let nodeDestination = nodeNames.find(x => x.name == data.destination.name);
      if (!nodeDestination) {
        // tslint:disable-next-line:max-line-length
        nodeDestination = new Node(data.destination.id, data.destination, data.destination.name, nodeDatabaseColor, data.destination.name, data.destination.id, 'destinationNode');
        nodeNames.push(nodeDestination);
        this.nodes.push(nodeDestination);
      }
      this.links.push(new Link(source, nodeDestination));

      for (let k = 0; k < destinationIds.length; k++) {
        let destination = new Node(destinationIds[k], data, destinationValues[k], sourceColor, data.destination.name + ':' + destinationValues[k], data.destinationId, 'destination')
        this.nodes.push(destination);
        this.links.push(new Link(nodeDestination, destination));
      }

      //2Degree section
      if (this.degree >= 2) {
        for (let j = 0; j < data.mappingDegree.length; j++) {
          const data2Degree = data.mappingDegree[j];
          let destinationIds = data2Degree.destinationDataId.split(',');
          let destinationValues = data2Degree.destinationValue.split(',');

          let source2Degree = this.nodes.find(x => x.id == data.destinationDataId);
          if (!source2Degree) {
            // tslint:disable-next-line:max-line-length
            source2Degree = new Node(data2Degree.sourceDataId, data.mappingDegree[j], data2Degree.sourceValue, sourceColor, data2Degree.source.name + ':' + data2Degree.sourceValue, data2Degree.sourceId, 'destination');
            this.nodes.push(source);
          }
          let nodeDestination2Degree = nodeNames.find(x => x.detail.name == data2Degree.destination.name);
          if (!nodeDestination2Degree) {
            // tslint:disable-next-line:max-line-length
            nodeDestination2Degree = new Node(data2Degree.destination.id, data2Degree.destination, data2Degree.destination.name, nodeDatabaseColor, data2Degree.destination.name, data2Degree.destination.id, 'destinationNode');
            nodeNames.push(nodeDestination2Degree);
            this.nodes.push(nodeDestination2Degree);
          }

          this.links.push(new Link(source2Degree, nodeDestination2Degree));

          for (let k = 0; k < destinationIds.length; k++) {
            let destination2Degree = this.nodes.find(x => x.id == data2Degree.destinationDataId);
            if (!destination2Degree) {
              destination2Degree = new Node(destinationIds[k], data.mappingDegree[j], destinationValues[k], sourceColor, data2Degree.destination.name + ':' + destinationValues[k], data2Degree.destinationId, 'destination')
              this.nodes.push(destination2Degree);
            }
            
            this.links.push(new Link(nodeDestination2Degree, destination2Degree));
          }

          //3Degree section
          if (this.degree == 3) {
            for (let z = 0; z < data2Degree.mappingDegree.length; z++) {
              const data3Degree = data2Degree.mappingDegree[z];
              let destinationIds = data3Degree.destinationDataId.split(',');
              let destinationValues = data3Degree.destinationValue.split(',');

              let source3Degree = this.nodes.find(x => x.id == data2Degree.destinationDataId);
              if (!source3Degree) {
                // tslint:disable-next-line:max-line-length
                source3Degree = new Node(data3Degree.sourceDataId, data3Degree, data3Degree.sourceValue, sourceColor, data3Degree.source.name + ':' + data3Degree.sourceValue, data3Degree.sourceId, 'destination');
                this.nodes.push(source);
              }
              let nodeDestination3Degree = nodeNames.find(x => x.detail.name == data3Degree.destination.name);
              if (!nodeDestination3Degree) {
              nodeDestination3Degree = new Node(data3Degree.destination.id, data3Degree.destination, data3Degree.destination.name, nodeDatabaseColor, data3Degree.destination.name, data3Degree.destination.id, 'destinationNode');
              nodeNames.push(nodeDestination3Degree);
              this.nodes.push(nodeDestination3Degree);
              }
              this.links.push(new Link(source3Degree, nodeDestination3Degree));

              for (let k = 0; k < destinationIds.length; k++) {
                let destination3Degree = this.nodes.find(x => x.id == data3Degree.destinationDataId);
                if (!destination3Degree) {
                  destination3Degree = new Node(destinationIds[k], data3Degree, destinationValues[k], sourceColor, data3Degree.destination.name + ':' + destinationValues[k], data3Degree.destinationId, 'destination')
                  this.nodes.push(destination3Degree);
                }
                this.links.push(new Link(nodeDestination3Degree, destination3Degree));

              }
            }
          }
        }
      }
    }
    this.isInitGraph = true;
    this.loadingScreenService.loadingScreen.emit(false);
  }

  getRandomColor() {
    const color = Math.floor(0x1000000 * Math.random()).toString(16);
    return '#' + ('000000' + color).slice(-6);
  }

  goToLink(url:string){

    let split_url = url.split('/')
    let dbname = split_url.shift();
    let primarykey = split_url.join('%2F')

    url = encodeURI(this.locationUrl + '/node-detail/' + dbname + '/' + primarykey).replace(/\(/g,'%28').replace(/\)/g,'%29');

    window.open(url,"_blank")

  }

  feedback(data) {
    let indexNumber = this.mappingData.findIndex(prop => prop.destinationDataId == data.NodeDataId);
    if (indexNumber == -1) {
      this.mappingData.forEach((value, index) => {
        let destinationIds = value.destinationDataId.split(',');
        destinationIds.forEach(element => {
          if (element == data.NodeDataId) {
            indexNumber = index;
          }
        });
      });
    }
    let currentUser = JSON.parse(localStorage.getItem("currentUser"));
    let userEmail = currentUser.email;
    let fullName = currentUser.firstName + " " + currentUser.lastName;
    let phoneNumber = currentUser.phone;
    const ngbModalOptions: NgbModalOptions = {
      backdrop: "static",
      keyboard: false,
    };
    const modalRef = this.modalService.open(UserFeedbackModalComponent,ngbModalOptions);
    modalRef.componentInstance.sourceId = this.mappingData[indexNumber].sourceId;
    modalRef.componentInstance.destinationId = this.mappingData[indexNumber].destinationId;
    modalRef.componentInstance.sourceNodeDataId = this.mappingData[indexNumber].sourceDataId;
    modalRef.componentInstance.destinationNodeDataId = data.NodeDataId;
    modalRef.componentInstance.name = fullName;
    modalRef.componentInstance.email = userEmail;
    modalRef.componentInstance.phoneNumber = phoneNumber;
    modalRef.result.then(
      (res: any) => {
        this.toastr.success("Send feedback successful");
      },
      (reason) => {}
    );
  }
}

import { map } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { MappingNodeService } from 'src/app/services/mapping-node.service';
import { MappingSchemaService } from 'src/app/services/mapping-schema.service';
import { NodeDataService } from 'src/app/services/node-data.service';
import { NotifyService } from 'src/app/services/notify.service';

@Component({
  selector: 'app-mapping-suggess-modal',
  templateUrl: './mapping-suggess-modal.component.html',
  styleUrls: ['./mapping-suggess-modal.component.css']
})
export class MappingSuggessModalComponent implements OnInit {
  data = [];
  nodeData = [];
  suggestMapping = [];
  mappingSuggestReady: boolean;
  countMapping = 0;
  success = 0;
  isSuggesstioning = false;

  sourceId: any;
  destinationId: any;

  isLoading = false;
  successProgress = 0;
  selectCount: number;

  constructor(
    public activeModal: NgbActiveModal,
    private toastr: ToastrService,
    private mappingSchemaService: MappingSchemaService,
    private nodeDataService: NodeDataService,
    private mappingNodeService: MappingNodeService,
    private notifyService: NotifyService) { }


  ngOnInit() {
    this.mappingSchemaService.getAll().subscribe((res: any) => {
      if (!this.sourceId && !this.destinationId) {
        // tslint:disable-next-line:forin
        for (const key in res) {
          const result = {
            Source_id: res[key].nodeSource.id,
            Destination_id: res[key].nodeDestination.id,
            Source: res[key].nodeSource.name,
            Destination: res[key].nodeDestination.name,
            Checked: false,
            Score: res[key].score,
            Data: res[key]
          };
          this.data.push(result);
        }
      } else {
        // tslint:disable-next-line:forin
        for (const key in res) {
          if (res[key].nodeSource.id === +this.sourceId && res[key].nodeDestination.id === +this.destinationId) {
            const result = {
              Source_id: res[key].nodeSource.id,
              Destination_id: res[key].nodeDestination.id,
              Source: res[key].nodeSource.name,
              Destination: res[key].nodeDestination.name,
              Data: res[key],
              Checked: true
            };
            this.data.push(result);
          }
        }
      }
    });
    this.notifyService.suggessMapping.subscribe(res => {
      this.success = res;
    });
  }

  closeModal() {
    this.activeModal.close('close');
  }

  async mapping() {
    this.isSuggesstioning = true;
    this.suggestMapping = [];
    this.mappingSuggestReady = false;
    const mapData = this.data.filter(x => x.Checked === true).map(x => x.Data);
    this.success = 0;
    this.selectCount = mapData.length;
    this.countMapping = 0;
    this.mappingNodeService.suggestMapping(mapData).subscribe((res: number) => {
      this.countMapping = res;
      this.mappingSuggestReady = true;
    }, error => {
      if (+error.status === 504) { // timeout
        // tslint:disable-next-line:max-line-length
      this.toastr.info('Server กำลังประมวลผล ซึ่งอาจใช้เวลานาน กรุณาปิดหน้านี้แล้วรอแจ้งผลการทำงานในเมนู Notification', '', {disableTimeOut: true})
        .onHidden.subscribe(x => {
          this.closeModal();
        });
      }
    });
  }

}

import { Component, OnInit } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { UserService } from 'src/app/services/user.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NodeDataService } from 'src/app/services/node-data.service';
import { NodeService } from 'src/app/services/node.service';
import { ToastrService } from 'ngx-toastr';
import { MappingNodeService } from 'src/app/services/mapping-node.service';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { LoadingScreenService } from 'src/app/services/loading-screen.service';
import APP_CONFIG from './../../app.config';
import { Node, Link } from './../../d3';
import { ColumnTable } from 'src/app/models/column-table/column-table.component';
import { ActionModel } from 'src/app/models/table-action-model';
import { UserFeedbackModalComponent } from '../user-feedback-modal/user-feedback-modal.component';
import { MappingViewdetailComponent } from '../mapping-schemas/mapping-viewdetail/mapping-viewdetail.component';
@Component({
  selector: 'app-advance-search-result',
  templateUrl: './advance-search-result.component.html',
  styleUrls: ['./advance-search-result.component.css']
})
export class AdvanceSearchResultComponent implements OnInit {

  encryptSecretKey = 'SearchCriteria';
  currentUser: any;
  nodes: any[];
  links: any[];
  lables: any[];
  tableData: any[];
  sourceDropdownList: any;
  criteria: any;

  advanceColumns: ColumnTable[];
  advanceData: any[];
  advanceTotal = 100;
  advanceLimit = 10;
  advancePageIndex = 1;

  isSearch: boolean;
  tableResult = true;
  actions = [];
  actionsMapping = [];
  detail: any;
  sourceDetailSelected: any;
  sourceDataDatailSelected: any;
  destinationDataDetailSelected: any;
  destinationDetailSelected: any;
  isSourceDetailReady: boolean;
  isSourceDetailColumnReady: boolean;
  isSourceDetailDataReady: boolean;
  sourceDetailColumns: any[];
  sourceDetailNode: any;
  selectSourceProperties: any;
  sourceDataDetail: any;
  sourceDetailTotal: any;
  showDetail: boolean;
  sourceDetailLimit = 10;
  sourceDetailPageIndex: any;
  isDestinationDetailReady: boolean;
  isDestinationDetailColumnReady: boolean;
  isDestinationDataDetailReady: boolean;
  destinationDetailColumns: any[];
  destinationDetailNode: any;
  destinationSelectedItems: any;
  destinationDetailData: any;
  destinationDetailTotal: any;
  destinationDetailLimit = 10;
  destinationDetailPageIndex: any;
  constructor(
    private userService: UserService,
    private router: Router,
    private nodeDataService: NodeDataService,
    private nodeService: NodeService,
    private toastr: ToastrService,
    private mappingNodeService: MappingNodeService,
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private loadingScreenService: LoadingScreenService,

  ) {
    this.userService.currentUser.subscribe(x => this.currentUser = x);
  }

  ngOnInit() {
    sessionStorage.setItem('isHome', 'false');
    sessionStorage.setItem('isRedirectSearchFromFile', 'false');

    this.advanceColumns = [
      new ColumnTable('Source', 'nodeSource', 'col', 'sourceData', true),
      new ColumnTable('Destination', 'nodeDestination', 'col', 'destinationData', true),
      new ColumnTable('Create Date', 'CreateDate', 'col', 'createDate', true),
    ];

    this.actions.push(new ActionModel({
      text: 'Edit',
      icon: 'fas fa-edit',
      callBack: (data) => this.viewDetail(data),
      class: 'text-warning',
      type: 'viewDetail'
    }));

    this.actionsMapping.push(new ActionModel({
      callBack: (data) => this.feedback(data),
      type: 'message'
    }));

    this.route.queryParams.subscribe((params: any) => {
      this.criteria = this.decryptData(params.param);
      this.nodeService.getDropDown().subscribe((node: any[]) => {
        this.sourceDropdownList = node;
        this.initGraph();
      });
    });
  }

  feedback(data: any) {
    const ngbModalOptions: NgbModalOptions = {
      backdrop : 'static',
      keyboard : false,
    };
    const modalRef = this.modalService.open(
      UserFeedbackModalComponent, ngbModalOptions
    );
    modalRef.componentInstance.sourceId = this.detail.SourceId;
    modalRef.componentInstance.destinationId = this.detail.DestinationId;
    modalRef.componentInstance.sourceNodeDataId = this.detail.SourceNodeDataId;
    modalRef.componentInstance.destinationNodeDataId = data.id;
    modalRef.result.then((res: any) => {
      this.toastr.success('Send contact successful');
    }, (reason) => {
    });

  }

  viewDetail(data: any) {
    const ngbModalOptions: NgbModalOptions = {
      backdrop : 'static',
      keyboard : false,
      size: 'lg',
      windowClass: 'waiting-approve-view-detail',
    };
    const modalImport = this.modalService.open(MappingViewdetailComponent, ngbModalOptions);
    modalImport.componentInstance.data = data;
    modalImport.componentInstance.mode = 'advanceDetail';
    modalImport.componentInstance.mappingNodeId = 1;
    modalImport.result.then((result) => {

    });
  }

  selectSourceDetail(nodeId, isClear = true) {
    this.sourceDetailSelected = nodeId;
    if (isClear) {
      this.sourceDataDatailSelected = null;
    }
    this.isSourceDetailReady = false;
    this.isSourceDetailColumnReady = false;
    this.isSourceDetailDataReady = false;
    this.sourceDetailColumns = [];
    this.nodeService.get(nodeId).subscribe(nodeRes => {
      this.sourceDetailNode = nodeRes;
      this.isSourceDetailReady = true;
      this.sourceDetailNode.nodeColumns.forEach(element => {
        if (this.criteria.SourceSelectedProperties.find(x => x === element.name)) {
          const col = new ColumnTable(element.name, element.name, 'col', 'node-text', false);
          this.sourceDetailColumns.push(col);
        }
      });
      this.isSourceDetailColumnReady = true;
      const citeria = {
        id: nodeId,
        nodeDataIds: [this.sourceDataDatailSelected]
      };
      this.nodeDataService.getDataDetail(citeria).subscribe((data: any) => {
        this.sourceDataDetail = data;
        this.sourceDetailTotal = this.sourceDataDetail.length;
        this.isSourceDetailDataReady = true;
        this.showDetail = true;
      });
    });
  }

  onSourceDetailTablePageChange(event) {
    this.sourceDetailLimit = event.pageSize;
    this.sourceDetailPageIndex = event.pageIndex;
  }

  selectDestinationDetail(nodeId, isClear = true) {
    this.destinationDetailSelected = nodeId;
    if (isClear) {
      this.destinationDataDetailSelected = [];
    }
    this.isDestinationDetailReady = false;
    this.isDestinationDetailColumnReady = false;
    this.isDestinationDataDetailReady = false;
    this.destinationDetailColumns = [];
    this.nodeService.get(nodeId).subscribe((nodeRes: any) => {
      this.destinationDetailNode = nodeRes;
      this.isDestinationDetailReady = true;
      this.destinationDetailNode.nodeColumns.forEach(element => {
        const destinationSelectedItem = this.criteria.Destinations.find(x => x.id === nodeRes.id);
        if (destinationSelectedItem.selectedProperties.find(x => x === element.name)) {
          const col = new ColumnTable(element.name, element.name, 'col', 'node-text', false);
          this.destinationDetailColumns.push(col);
        }
      });
      this.isDestinationDetailColumnReady = true;
    });
    const citeria = {
      id: nodeId,
      nodeDataIds: this.destinationDataDetailSelected
    };
    this.nodeDataService.getDataDetail(citeria).subscribe((data: any) => {
      this.destinationDetailData = data;
      this.destinationDetailTotal = this.destinationDetailData.length;
      this.isDestinationDataDetailReady = true;
      this.showDetail = true;
    });
  }

  onDestinationDetailTablePageChange(event) {
    this.destinationDetailLimit = event.pageSize;
    this.destinationDetailPageIndex = event.pageIndex;
  }

  initGraph() {
    this.showDetail = false;
    const N = APP_CONFIG.N;
    this.nodes = [];
    this.links = [];
    this.lables = [];
    this.tableData = [];
    this.loadingScreenService.loadingScreen.emit(true);
    this.nodeDataService.graphSearch(this.criteria).subscribe((res: any) => {
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < res.ids.length; i++) {
        let source = null;
        let destination = null;
        const sourceNodeDataId = res.dataList[i][0].NodeDataId[0];
        const destinationNodeDataId = res.dataList[i][res.dataList[i].length - 1].NodeDataId[0];
        const edge = res.linkedList.find(x => x.SourceId === sourceNodeDataId);
        const sourceName = res.lables[i][0];
        const destinationName = res.lables[i][res.lables[i].length - 1];
        let sourceValue = res.dataList[i][0];
        const destinationValue = res.dataList[i][res.dataList[i].length - 1];
        const createDate = edge ? edge.Create : null;
        // tslint:disable-next-line:max-line-length
        const duplicateData = this.tableData.find(x => x.SourceName === sourceName && x.DestinationName === destinationName && x.SourceNodeDataId === sourceNodeDataId);
        if (duplicateData) {
          const index = this.tableData.indexOf(duplicateData);
          this.tableData[index].DestinationValue.push(destinationValue);
          this.tableData[index].DestinationNodeDataId.push(destinationNodeDataId);
        } else {
          const sourceNode = this.sourceDropdownList.find(x => x.name === sourceName);
          let sourceCol = sourceNode.nodeColumns.find(x => x.isPrimary);
          if (!sourceCol) {
            sourceCol = sourceNode.nodeColumns[0];
          }
          const destinationNode = this.sourceDropdownList.find(x => x.name === destinationName);
          let destinationCol = destinationNode.nodeColumns.find(x => x.isPrimary);
          if (!destinationCol) {
            destinationCol = destinationNode.nodeColumns[0];
          }
          this.tableData.push({
            SourceId: sourceNode.id,
            SourceName: sourceName,
            DestinationId: destinationNode.id,
            DestinationName: destinationName,
            SourceValue: sourceValue,
            SourceNodeDataId: sourceNodeDataId,
            SourceCol: sourceCol.name,
            DestinationValue: [destinationValue],
            DestinationNodeDataId: [destinationNodeDataId],
            DestinationCol: destinationCol.name,
            CreateDate: createDate
          });
        }
        // tslint:disable-next-line:prefer-for-of
        for (let r = 0; r < res.ids[i].length; r++) {
          const lableName = res.lables[i][r];
          const sourceNode = this.sourceDropdownList.find(x => x.name === lableName);
          let sourceCol = sourceNode.nodeColumns.find(x => x.isPrimary);
          if (!sourceCol) {
            sourceCol = sourceNode.nodeColumns[0];
          }

          sourceValue = res.dataList[i][r][sourceCol.name];

          const duplicateLable = this.lables.find(x => x.lable === lableName);
          let lableObj = {
            lable: lableName,
            color: this.getRandomColor()
          };

          if (duplicateLable == null) {
            this.lables.push(lableObj);
          } else {
            lableObj = duplicateLable;
          }
          if (source == null) {
            const display = lableName + ': ' + sourceValue;
            source = new Node(res.ids[i][r].id, res.dataList[i][r], res.lables[i][r], lableObj.color, display, sourceNode.id);
            const duplicateSource = this.nodes.find(x => x.id === res.ids[i][r].id);
            if (duplicateSource == null) {
              this.nodes.push(source);
            } else {
              source = duplicateSource;
            }
          } else {
            const display = lableName + ': ' + sourceValue;
            destination = new Node(res.ids[i][r].id, res.dataList[i][r], res.lables[i][r], lableObj.color, display, sourceNode.id);
            const duplicateDestination = this.nodes.find(x => x.id === res.ids[i][r].id);
            if (duplicateDestination == null) {
              this.nodes.push(destination);
            } else {
              destination = duplicateDestination;
            }
            this.links.push(new Link(source, destination));
            source = destination;
          }
        }
      }
      this.advanceTotal = this.tableData.length;
      this.isSearch = true;
      this.loadingScreenService.loadingScreen.emit(false);
    });
  }

  getRandomColor() {
    const color = Math.floor(0x1000000 * Math.random()).toString(16);
    return '#' + ('000000' + color).slice(-6);
  }

  decryptData(data) {
    try {
      const bytes = CryptoJS.AES.decrypt(data, this.encryptSecretKey);
      if (bytes.toString()) {
        return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      }
      return data;
    } catch (e) {
      // console.log(e);
    }
  }

  onAdvanceTableOwnPageChange(event) {
    this.advanceLimit = event.pageSize;
    this.advancePageIndex = event.pageIndex;
  }


}

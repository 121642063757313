import { NodeService } from 'src/app/services/node.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UserService } from 'src/app/services/user.service';
import { AuthService } from 'angularx-social-login';
import { ToastrService } from 'ngx-toastr';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OrganizationService } from 'src/app/services/organization.service';
import { UserFeedbackService } from 'src/app/services/user-feedback.service';
import { ColumnTable } from 'src/app/models/column-table/column-table.component';
import { MappingNodeService } from 'src/app/services/mapping-node.service';
import { NodeDataService } from 'src/app/services/node-data.service';

@Component({
  selector: 'app-user-feedback-detail-modal',
  templateUrl: './user-feedback-detail-modal.component.html',
  styleUrls: ['./user-feedback-detail-modal.component.css']
})
export class UserFeedbackDetailModalComponent implements OnInit {
  form: FormGroup;
  user: any;
  organizations: any;
  isUpdate = false;
  mappingId = '';
  nodeId = '';
  detail: any;
  sourceDetailSelected: any;
  sourceDataDatailSelected: any;
  destinationDataDetailSelected: any;
  destinationDetailSelected = '';
  basicData: any;
  advanceLimit: any;
  advancePageIndex: any;
  isSourceDetailReady: boolean;
  isSourceDetailColumnReady: boolean;
  isSourceDetailDataReady: boolean;
  sourceDetailColumns: any[];
  sourceDetailNode: any;
  sourceDataDetail: any;
  sourceDetailTotal: any;
  showDetail: boolean;
  sourceDetailLimit = 10;
  sourceDetailPageIndex = 1;
  isDestinationDetailReady: boolean;
  isDestinationDetailColumnReady: boolean;
  isDestinationDataDetailReady: boolean;
  destinationDetailColumns: any[];
  destinationDetailNode: any;
  destinationDetailData: any;
  destinationDetailTotal: any;
  destinationDetailLimit = 10;
  destinationDetailPageIndex = 1;
  destinationId: any;
  id: any;
  nodeData: any;
  data: any;

  constructor(
    private fb: FormBuilder,
    private userService: UserService,
    private authService: AuthService,
    private toastr: ToastrService,
    public modal: NgbActiveModal,
    private modalService: NgbModal,
    private organizationService: OrganizationService,
    private userFeedbackService: UserFeedbackService,
    private nodeService: NodeService,
    private mappingNodeService: MappingNodeService,
    private nodeDataService: NodeDataService
  ) {
    this.nodeService.getDropDown().subscribe((res: any) => {
      this.nodeData = res;
    });
  }

  ngOnInit() {
    this.userFeedbackService.get(this.id).subscribe((res: any) => {
      this.data = res;
      this.sourceDetailSelected = res.sourceId;
      this.sourceDataDatailSelected = res.sourceNodeDataId;
      this.destinationDataDetailSelected = res.destinationNodeDataId;
      this.selectSourceDetail(this.sourceDetailSelected, false);
      this.destinationDetailSelected = res.destinationId;
      this.selectDestinationDetail(this.destinationDetailSelected, false);
    });
  }

  closeModal(event: string) {
    // this.dialogRef.close({event: event, data: data});
    this.modal.dismiss(event);
  }

  onBasicTableOwnPageChange(event, index) {
    this.basicData[index].Limit = event.pageSize;
    this.basicData[index].PageIndex = event.pageIndex;
  }
  onAdvanceTableOwnPageChange(event) {
    this.advanceLimit = event.pageSize;
    this.advancePageIndex = event.pageIndex;
  }

  selectSourceDetail(nodeId, isClear = true) {
    this.sourceDetailSelected = nodeId;
    if (isClear) {
      this.sourceDataDatailSelected = null;
    }
    this.isSourceDetailReady = false;
    this.isSourceDetailColumnReady = false;
    this.isSourceDetailDataReady = false;
    this.sourceDetailColumns = [];
    this.nodeService.get(nodeId).subscribe(nodeRes => {
      this.sourceDetailNode = nodeRes;
      this.isSourceDetailReady = true;
      this.sourceDetailNode.nodeColumns.forEach(element => {
        const col = new ColumnTable(element.name, element.name, 'col', 'node-text', false);
        this.sourceDetailColumns.push(col);
      });
      this.isSourceDetailColumnReady = true;
      const citeria = {
        id: nodeId,
        nodeDataIds: [this.sourceDataDatailSelected]
      };
      this.nodeDataService.getDataDetail(citeria).subscribe((data: any) => {
        this.sourceDataDetail = data;
        this.sourceDetailTotal = this.sourceDataDetail.length;
        this.isSourceDetailDataReady = true;
        this.showDetail = true;
      });
    });
  }

  onSourceDetailTablePageChange(event) {
    this.sourceDetailLimit = event.pageSize;
    this.sourceDetailPageIndex = event.pageIndex;
  }

  selectDestinationDetail(nodeId, isClear = true) {
    this.destinationDetailSelected = nodeId;
    if (isClear) {
      this.destinationDataDetailSelected = [];
    }
    this.isDestinationDetailReady = false;
    this.isDestinationDetailColumnReady = false;
    this.isDestinationDataDetailReady = false;
    this.destinationDetailColumns = [];
    this.nodeService.get(nodeId).subscribe(nodeRes => {
      this.destinationDetailNode = nodeRes;
      this.isDestinationDetailReady = true;
      this.destinationDetailNode.nodeColumns.forEach(element => {
        const col = new ColumnTable(element.name, element.name, 'col', 'node-text', false);
        this.destinationDetailColumns.push(col);
      });
      this.isDestinationDetailColumnReady = true;
    });
    const citeria = {
      id: nodeId,
      nodeDataIds: [this.destinationDataDetailSelected]
    };
    this.nodeDataService.getDataDetail(citeria).subscribe((data: any) => {
      this.destinationDetailData = data;
      this.destinationDetailTotal = this.destinationDetailData.length;
      this.isDestinationDataDetailReady = true;
      this.showDetail = true;
    });
  }

  onDestinationDetailTablePageChange(event) {
    this.destinationDetailLimit = event.pageSize;
    this.destinationDetailPageIndex = event.pageIndex;
  }

}

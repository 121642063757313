class ColumnTable {
  value: string;
  class: string;
  type: string;
  sortable: boolean;
  name: string;
  sourceValue: string;

  // tslint:disable-next-line:variable-name
  constructor(_name: string, _value: string, _class: string, _type: string, _sortable: boolean = false, _sourceValue: string = '') {
      this.value = _value;
      this.class = _class;
      this.type = _type;
      this.sortable = _sortable;
      this.name = _name;
      this.sourceValue = _sourceValue;
  }
}

export { ColumnTable };

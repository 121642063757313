import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import * as jwt_decode from 'jwt-decode';
@Injectable({ providedIn: 'root' })
export class UserService {
  private actionUrl = 'api/users/';
  // tslint:disable-next-line:ban-types
  public currentUserSubject: BehaviorSubject<Object>;
  // tslint:disable-next-line:ban-types
  public currentUser: Observable<Object>;

    constructor(private http: HttpClient) {
        // tslint:disable-next-line:ban-types
        this.currentUserSubject = new BehaviorSubject<Object>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
    }

  public login<T>(data: object): Observable<T> {
    return this.http.post<T>(this.actionUrl + 'authenticate', data);
  }

  public loginWithSocial<T>(data: object): Observable<T> {
    return this.http.post<T>(this.actionUrl + 'authenticateWithSocial', data);
  }

  public signUp<T>(data: object): Observable<T> {
    return this.http.post<T>(this.actionUrl + 'sign-up', data);
  }

  public generateUser<T>(data: object): Observable<T> {
    return this.http.post<T>(this.actionUrl + 'newuser', data);
  }

  public logOut() {
    return this.http.get('accounts.google.com/logout');
  }

  // tslint:disable-next-line:ban-types
  public checkEmailDuplicate<T>(data: object): Observable<Object> {
    return this.http.post<T>(this.actionUrl + 'DuplicateEmail', data);
  }

  // tslint:disable-next-line:ban-types
  public checkUsernameDuplicate<T>(data: object): Observable<Object> {
    return this.http.post<T>(this.actionUrl + 'DuplicateUsername', data);
  }

  public get<T>(id: number): Observable<T> {
    return this.http.get<T>(this.actionUrl + id);
  }


  public getCount<T>(): Observable<T> {
    return this.http.get<T>(this.actionUrl + 'count');
  }

  public getAll<T>(): Observable<T> {
    return this.http.get<T>(this.actionUrl);
  }

  public getAllToken<T>(): Observable<T> {
    return this.http.get<T>(this.actionUrl + 'getTokenList');
  }

  public getSingle<T>(id: number): Observable<T> {
    return this.http.get<T>(this.actionUrl + id);
  }

  public add<T>(itemName: string): Observable<T> {
    const toAdd = { ItemName: itemName };

    return this.http.post<T>(this.actionUrl, toAdd);
  }

  public update<T>(id: number, itemToUpdate: any): Observable<T> {
    return this.http.put<T>(this.actionUrl + id, itemToUpdate);
  }

  public updatestatus<T>(id: number, itemToUpdate: any): Observable<T> {
    return this.http.put<T>(this.actionUrl + 'status/' + id, itemToUpdate);
  }

  public delete<T>(id: number): Observable<T> {
    return this.http.delete<T>(this.actionUrl + id);
  }

  public generateToken<T>(data): Observable<T> {
    return this.http.post<T>(this.actionUrl + 'generateToken', data);
  }

  // tslint:disable-next-line:ban-types
  public get currentUserValue(): Object {
    return this.currentUserSubject.value;
  }
  public set setCurrentUser(user) {
    this.currentUserSubject.next(user);
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('jwt');
    localStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);
  }
  checkRole(roles) {
    const token = localStorage.getItem('jwt');
    const decoded = jwt_decode(token);
    const userRole = decoded.role;
    return roles.indexOf(userRole) !== -1;
  }

}

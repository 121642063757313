import { ContactUsService } from '../../services/contact-us.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, AbstractControl, ValidationErrors } from '@angular/forms';
import { UserService } from 'src/app/services/user.service';
import { AuthService } from 'angularx-social-login';
import { ToastrService } from 'ngx-toastr';
import { NgbActiveModal, NgbModal, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import { OrganizationService } from 'src/app/services/organization.service';
import { LoginComponent } from '../login/login.component';
import { SignUpComponent } from '../sign-up/sign-up.component';

@Component({
  selector: 'app-search-file-modal',
  templateUrl: './search-file-modal.component.html',
  styleUrls: ['./search-file-modal.component.css']
})
export class SearchFileModalComponent implements OnInit {

  constructor(
    private fb: FormBuilder,
    private userService: UserService,
    private authService: AuthService,
    private toastr: ToastrService,
    public modal: NgbActiveModal,
    private modalService: NgbModal,
    private organizationService: OrganizationService,
    private contactUsService: ContactUsService
  ) {
    
  }

  ngOnInit() {
    sessionStorage.setItem('isRedirectSearchFromFile', 'true');
  }

  signIn() {
    const ngbModalOptions: NgbModalOptions = {
      backdrop : true,
      keyboard : false,
    };
    const modalRef = this.modalService.open(
      LoginComponent, ngbModalOptions
    );
    modalRef.result.then((result) => {
    }, (reason) => {
      if (reason === 'Success') {
        this.userService.getAll().subscribe(res => {
        });
      }
    });
    this.modal.dismiss('Success');
  }

  signUp() {
    const ngbModalOptions: NgbModalOptions = {
      backdrop : true,
      keyboard : false,
    };
    const modalRef = this.modalService.open(
      SignUpComponent, ngbModalOptions
    );
    modalRef.result.then((result) => {
    }, (reason) => {
      if (reason === 'Success') {
        this.userService.getAll().subscribe(res => {
        });
      }
    });
    this.modal.dismiss('Success');
  }
}

import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormArray, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { NodeService } from 'src/app/services/node.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MappingSchemaService } from 'src/app/services/mapping-schema.service';

@Component({
  selector: 'app-mapping-create',
  templateUrl: './mapping-create.component.html',
  styleUrls: ['./mapping-create.component.scss']
})
export class MappingCreateComponent implements OnInit {

  form: FormGroup;
  nodes: any;
  columns: any;
  sourceColumns = [];
  DestinationColumns = [];
  source: any;
  destination: any;
  datas = [];
  suggessMappingDatas = [];
  sourceId = null;
  destinationId = null;
  isReady = false;
  sourceReady: any;
  destinationReady: boolean;
  title = 'New Mapping';
  isNew: boolean;
  desNode: any;

  constructor(private fb: FormBuilder,
              private toastr: ToastrService,
              private nodeService: NodeService,
              private mappingSchemaService: MappingSchemaService,
              private router: Router,
              private activatedRoute: ActivatedRoute) {
    this.activatedRoute.queryParams.subscribe(params => {
      this.sourceId = activatedRoute.snapshot.params.source_id;
      this.destinationId = activatedRoute.snapshot.params.destination_id;
    });
  }

  ngOnInit() {
    sessionStorage.setItem('isHome', 'false');
    sessionStorage.setItem('isRedirectSearchFromFile', 'false');
    
    this.form = this.fb.group({
      Source: [''],
      Destination: [''],
      Weight: [1,],
      MappingSourceColumns: this.fb.array([]),
      MappingDestinationColumns: this.fb.array([]),
      ConditionBetweenSourceAndDestination: this.fb.array([])
    });
      this.nodeService.getDropDown().subscribe((nodeRes: any) => {
        this.nodes = nodeRes;
        this.desNode = nodeRes;
      });
      this.isReady = true;
      this.isNew = true;
  }

  onCalcel(){
    this.router.navigateByUrl('mapping-management/');
  }

  onSubmit() {
    this.prepareDataToSubmit();
  }

  prepareDataToSubmit() {
    this.datas = [];
        this.datas.push({
          no: this.datas.length + 1,
          NodeSourceId: this.form.controls.Source.value || this.sourceId,
          NodeDestinationId: this.form.controls.Destination.value || this.destinationId,
          SourceCondition: null,
          DestinationCondition: null,
          Score: this.form.controls.Weight.value,
          ContainCondition: 1
        });
  }

  addMappingSchema() {
    // tslint:disable-next-line:only-arrow-functions
    this.prepareDataToSubmit();
    this.datas.forEach(function(v) { delete v.no; });
    this.mappingSchemaService.add(this.datas).subscribe(res => {
      this.toastr.success('Add Success');
      this.router.navigateByUrl('mapping-management');
    });
  }

  selectSource(value: any) {
    this.sourceReady = false;
    if (value !== '') {
      this.source = value;
      if (this.isNew) {
        this.desNode = this.nodes;
        this.mappingSchemaService.filterBySource(value).subscribe((response:any) => {
          response.forEach(element => {
          this.desNode = this.desNode.filter(x => x.id != element);
          });
        });
      }
      this.nodeService.getNodeColumn(value).subscribe(res => {
        this.columns = this.form.get('MappingSourceColumns') as FormArray;
        while (this.columns.length !== 0) {
          this.columns.removeAt(0);
        }
        this.columns = this.form.get('MappingDestinationColumns') as FormArray;
        while (this.columns.length !== 0) {
          this.columns.removeAt(0);
        }
        this.sourceColumns = res as [];
        this.sourceReady = true;
      });
    }
  }

  selectDestination(value: any) {
    this.destinationReady = false;
    if (value !== '') {
      this.destination = value;
      this.nodeService.getNodeColumn(value).subscribe(res => {
        this.columns = this.form.get('MappingDestinationColumns') as FormArray;
        while (this.columns.length !== 0) {
          this.columns.removeAt(0);
        }
        this.DestinationColumns = res as [];
        this.destinationReady = true;
      });
    }
  }

  getSource(name) {
    const source = this.form.controls.MappingSourceColumns.value;
    return this.sourceColumns.filter(x => !source.some(s => s.Name === x.name) || x.name === name);
  }

  getDestination(name) {
    const destination = this.form.controls.MappingDestinationColumns.value;
    return this.DestinationColumns.filter(x => !destination.some(s => s.Name === x.name) || x.name === name);
  }

}

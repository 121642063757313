import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { UserService } from 'src/app/services/user.service';
import { ColumnTable } from 'src/app/models/column-table/column-table.component';

@Component({
  selector: 'app-setting-token',
  templateUrl: './setting-token.component.html',
  styleUrls: ['./setting-token.component.css']
})
export class SettingTokenComponent implements OnInit {
  token = '';
  date = 7;
  application = '';
  applicationError: boolean;
  expriedDateError: boolean;
  columns: any[];
  userTotal = 100;
  userData = [];
  selectAction: any;
  actionEdit: any;
  userSortBy = 'application';
  userOrderBy = 'asc';
  isShowResult = false;
  userLimit = 10;
  userPageIndex = 1;
  isLoading = true;
  constructor(private userService: UserService) {
    this.loadData();
  }

  ngOnInit() {
    this.columns = [
      new ColumnTable('Application', 'application', 'col-2', 'text', true),
      new ColumnTable('Token', 'token', 'col-8', 'text-token', true),
      new ColumnTable('Expire Date', 'exprieDate', 'col-2', 'createDate', true),
    ];
    this.loadData();
  }

  loadData() {
    this.isLoading = true;
    this.userService.getAllToken().subscribe((res: []) => {
      this.userData = res;
      this.userTotal = this.userData.length;
      this.isLoading = false;
    });
  }

  copyInputMessage(inputElement) {
    inputElement.select();
    document.execCommand('copy');
    inputElement.setSelectionRange(0, 0);
  }

  generateToken() {
    this.applicationError = false;
    this.expriedDateError = false;
    if (!this.application) {
      this.applicationError = true;
    }
    if (this.date < 1) {
      this.expriedDateError = true;
    }
    if (!this.applicationError && !this.expriedDateError) {
      const data = {
        Date: this.date,
        Application: this.application
      };
      this.userService.generateToken(data).subscribe((res: any) => {
        this.loadData();
      });
    }
  }

  onTableOwnPageChange(event) {
    this.userLimit = event.pageSize;
    this.userPageIndex = event.pageIndex;
  }

}

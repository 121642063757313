import { ContactUsService } from './../../services/contact-us.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { Router } from '@angular/router';
import { OrganizationService } from 'src/app/services/organization.service';
import { FormBuilder, Validators, FormGroupDirective, NgForm } from '@angular/forms';
import { AuthService } from 'angularx-social-login';
import { ToastrService } from 'ngx-toastr';
import { LoginComponent } from '../login/login.component';
import { SignUpComponent } from '../sign-up/sign-up.component';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit {

  // tslint:disable-next-line:ban-types
  currentUser: any;
  form: any;
  user: any;
  organizations: unknown;

  @ViewChild('signForm', {static: true})
  el: NgForm;

  constructor(
    private userService: UserService,
    private router: Router,
    private fb: FormBuilder,
    private authService: AuthService,
    private toastr: ToastrService,
    private modalService: NgbModal,
    private organizationService: OrganizationService,
    private contactUsService: ContactUsService
  ) {
    this.userService.currentUser.subscribe(x => this.currentUser = x);
    this.form = this.fb.group({
      FirstName: ['', [Validators.required]],
      LastName: ['', [Validators.required]],
      Phone: ['', [Validators.required, Validators.minLength(9), Validators.minLength(10)]],
      Email: ['', [Validators.required, Validators.email]],
      Message: ['', Validators.required],
    });
  }

  ngOnInit() {
    sessionStorage.setItem('isHome', 'false');
    sessionStorage.setItem('isRedirectSearchFromFile', 'false');
  }

  submit() {
    if (this.form.valid) {
      this.contactUsService.add(this.form.value).subscribe(res => {
        this.toastr.success('Send Success');
        this.user = res;
        this.form.reset();
        this.el.resetForm();
      });
    }
  }

  clearForm() {
    this.form.reset();
  }
  
  signIn() {
    const ngbModalOptions: NgbModalOptions = {
      backdrop : true,
      keyboard : false,
    };
    const modalRef = this.modalService.open(
      LoginComponent, ngbModalOptions
    );
    modalRef.result.then((result) => {
    }, (reason) => {
      if (reason === 'Success') {
        this.userService.getAll().subscribe(res => {
        });
      }
    });
  }
  signUp() {
    const ngbModalOptions: NgbModalOptions = {
      backdrop : true,
      keyboard : false,
    };
    const modalRef = this.modalService.open(
      SignUpComponent, ngbModalOptions
    );
    modalRef.result.then((result) => {
    }, (reason) => {
      if (reason === 'Success') {
        this.userService.getAll().subscribe(res => {
        });
      }
    });
  }
}

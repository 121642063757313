import APP_CONFIG from '../../app.config';
import * as d3 from 'd3';
export class Node implements d3.SimulationNodeDatum {
  // optional - defining optional implementation properties - required for relevant typing assistance
  index?: number;
  x?: number;
  y?: number;
  vx?: number;
  vy?: number;
  fx?: number | null;
  fy?: number | null;

  id: string;
  linkCount = 0;
  detail: any;
  lable: string;
  nodeColor: string;
  display: string;
  nodeId: number;
  type: string;

  constructor(id, detail, lable, nodeColor, display, nodeId, nodeType = 'source') {
    this.id = id;
    this.detail = detail;
    this.lable = lable;
    this.nodeColor = nodeColor;
    this.display = display;
    this.nodeId = nodeId;
    this.type = nodeType;
  }

  normal = () => {
    return Math.sqrt(this.linkCount / APP_CONFIG.N);
  }

  get r() {
    return 50;
  }

  get fontSize() {
    return 30 + 'px';
  }

  get color() {
    const index = Math.floor(APP_CONFIG.SPECTRUM.length * this.normal());
    return APP_CONFIG.SPECTRUM[index];
  }
}

import { JwtHelperService } from '@auth0/angular-jwt';
import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import * as jwt_decode from 'jwt-decode';
import { UserService } from './user.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private jwtHelper: JwtHelperService,
              private router: Router,
              private userService: UserService) {
  }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const token = localStorage.getItem('jwt');
    if (token) {
      const decoded = jwt_decode(token);
      const userRole = decoded.role;
      if (!this.jwtHelper.isTokenExpired(token)) {
        const roles = route.data.roles as string;
        return roles.indexOf(userRole) > -1;
      }
    }
    localStorage.setItem('jwt', null);
    localStorage.setItem('currentUser', null);
    this.userService.currentUserSubject.next(null);
    this.router.navigate(['']);
    return false;
  }
}

import { UserService } from 'src/app/services/user.service';
import { NodeDataImportModalComponent } from './../node-data-import-modal/node-data-import-modal.component';
import { ToastrService } from 'ngx-toastr';
import { ConfirmDialogComponent } from './../../../base-components/dialogs/confirm-dialog/confirm-dialog.component';
import { NodeDataService } from './../../../services/node-data.service';
import { NodeDataAddModalComponent } from './../node-data-add-modal/node-data-add-modal.component';
import { ActivatedRoute } from '@angular/router';
import { NodeService } from 'src/app/services/node.service';
import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { NewUserModalComponent } from '../../new-user-modal/new-user-modal.component';
import { FormGroup, FormControl } from '@angular/forms';
import { ColumnTable } from 'src/app/models/column-table/column-table.component';
import { ActionModel } from 'src/app/models/table-action-model';
import {saveAs as importedSaveAs} from 'file-saver';
import { LoadingScreenService } from 'src/app/services/loading-screen.service';
import { Ng2TooltipComponent } from 'src/app/base-components/ng2-smart-table-custom-field/ng2-smart-table-tooltips.component';

@Component({
  selector: 'app-node-data-list',
  templateUrl: './node-data-list.component.html',
  styleUrls: ['./node-data-list.component.css']
})
export class NodeDataListComponent implements OnInit {
  nodeId: any;
  node: any;
  columns = [];
  isReady: boolean;
  data: any;
  isColumnReady: boolean;
  isDataReady: boolean;
  total = 100;
  sortBy = '';
  orderBy = 'asc';
  limit = 10;
  pageIndex = 1;
  actions = [];
  actionDelete: ActionModel;
  actionEdit: ActionModel;
  isSearch = true;
  searchText = '';
  settings: Object = {};
  dataTable: any = [];
  nodes;
  nodeDetails;
  isPaginate: boolean = true;

  constructor(private nodeService: NodeService,
              private activatedRoute: ActivatedRoute,
              private modalService: NgbModal,
              public userService: UserService,
              private nodeDataService: NodeDataService,
              private toastr: ToastrService,
              private loadingScreenService: LoadingScreenService) {
    this.activatedRoute.queryParams.subscribe(params => {
      this.nodeId = activatedRoute.snapshot.params.id;
    });
  }

  ngOnInit() {
    sessionStorage.setItem('isHome', 'false');
    sessionStorage.setItem('isRedirectSearchFromFile', 'false');
    
    this.loadingScreenService.loadingScreen.emit(true);
    this.isReady = false;
    this.isColumnReady = false;
    this.isDataReady = false;
    this.nodeService.get(this.nodeId).subscribe(nodeRes => {
      this.node = nodeRes;
      this.isReady = true;
      this.node.nodeColumns.forEach(element => {
        if (this.sortBy === '') {
          this.sortBy = element.name;
        }
        if (element.columnType.type === 'Image' || element.columnType.type === 'Binary') {
          const col = new ColumnTable(element.name, element.name, 'col', 'node-file', true, element.name);
          this.columns.push(col);
        } else {
          const col = new ColumnTable(element.name, element.name, 'col', 'node-text', true, element.name);
          this.columns.push(col);
        }
      });
      this.isColumnReady = true;
      this.loadDataToSmartTable();
    });
  }

  loadDataToSmartTable() {
    this.loadingScreenService.loadingScreen.emit(true);
    this.isDataReady = false;

    this.nodeService.getDropDown().subscribe((resNode: any) => {
      this.nodes = resNode;
      this.nodeDetails = this.nodes.find((x) => x.id === +this.nodeId);

      this.nodeDataService.getAllById(this.nodeId).subscribe((res: any) => {
        this.dataTable = res;
        if (this.dataTable.length <= 10) {
          this.isPaginate = false;
        } else {
          this.isPaginate = true;
        }
        let column = {};

        this.nodeDetails.nodeColumns.forEach((element) => {
          column[element.name] = {
            title: element.name,
            filter: true,
            type: 'custom',
            renderComponent:Ng2TooltipComponent,
            }
        });

        this.settings = {
          mode: 'external',
          columns: column,
          actions: {
            add: false,
            edit: true,
            delete: true,
            position: 'right'
          },
          edit: {
            editButtonContent: '<label><i class="fas fa-pencil text-warning"></i> Edit</label>'
          },
          delete: {
            deleteButtonContent: '<label><i class="fas fa-times text-danger action-btn"></i> Delete</label>'
          },
          pager: {
            perPage: 10,
            paginateSize: 10
          }
        };
        this.isDataReady = true;
        this.loadingScreenService.loadingScreen.emit(false);
      });
    });
  }

  onEdit(event){
    const ngbModalOptions: NgbModalOptions = {
      backdrop : 'static',
      keyboard : false,
    };
    const modalRef = this.modalService.open(NodeDataAddModalComponent, ngbModalOptions);
    modalRef.componentInstance.nodeId = this.nodeId;
    modalRef.componentInstance.nodeDataId = event.data.NodeDataId;
    modalRef.result.then((result) => {
        this.loadDataToSmartTable();
    });
  }

  onDelete(event){
    const modalRef = this.modalService.open(ConfirmDialogComponent);
    modalRef.result.then((result) => {
      if (result) {
      this.loadingScreenService.loadingScreen.emit(true);
        this.nodeDataService.delete(event.data.NodeDataId).subscribe(() => {
          this.toastr.success('Delete Success');
          this.loadDataToSmartTable();
          this.loadingScreenService.loadingScreen.emit(false);
        });
      }
    }, (reason) => {
      this.loadingScreenService.loadingScreen.emit(false);
    });
  }

  addNew() {
    const ngbModalOptions: NgbModalOptions = {
      backdrop : 'static',
      keyboard : false,
    };
    const modalRef = this.modalService.open(NodeDataAddModalComponent, ngbModalOptions);
    modalRef.componentInstance.nodeId = this.nodeId;
    modalRef.result.then((result) => {
      this.loadDataToSmartTable();
    });
  }

  importData() {
    const ngbModalOptions: NgbModalOptions = {
      backdrop : 'static',
      keyboard : false,
    };
    const modalImport = this.modalService.open(NodeDataImportModalComponent, ngbModalOptions);
    modalImport.componentInstance.nodeId = this.nodeId;
    modalImport.result.then((result) => {
      this.loadDataToSmartTable();
    });
  }

  donwloadTemplate() {
    this.nodeDataService.getTemplate(this.nodeId).subscribe((blob: any) => {
      importedSaveAs(blob, this.node.name);
    });
  }

  donwloadData() {
    this.loadingScreenService.loadingScreen.emit(true);
    const criteria = {
      Text: this.searchText,
      Page: this.pageIndex,
      Limit: this.limit,
      NodeId: this.nodeId,
      OrderBy: this.orderBy,
      SortBy: this.sortBy
    };
    this.nodeDataService.downloadData(criteria).subscribe((res: any) => {
      importedSaveAs(res, this.node.name);
      this.loadingScreenService.loadingScreen.emit(false);
    });
  }

}

import { UserFeedbackService } from './../../services/user-feedback.service';
import { Component, OnInit } from '@angular/core';
import { Users } from 'src/app/models/users';
import { FormBuilder, Validators, AbstractControl, ValidationErrors, FormGroup } from '@angular/forms';
import { UserService } from 'src/app/services/user.service';
import { AuthService } from 'angularx-social-login';
import { ToastrService } from 'ngx-toastr';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OrganizationService } from 'src/app/services/organization.service';
import { Observable } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';

@Component({
  selector: 'app-user-feedback-modal',
  templateUrl: './user-feedback-modal.component.html',
  styleUrls: ['./user-feedback-modal.component.css']
})
export class UserFeedbackModalComponent implements OnInit {
  form: FormGroup;
  user: any;
  organizations: any;
  isUpdate = false;
  sourceId = '';
  destinationId = '';
  sourceNodeDataId = '';
  destinationNodeDataId = '';
  phoneNumber;
  email;
  name;

  constructor(
    private fb: FormBuilder,
    private userService: UserService,
    private authService: AuthService,
    private toastr: ToastrService,
    public modal: NgbActiveModal,
    private modalService: NgbModal,
    private organizationService: OrganizationService,
    private userFeedbackService: UserFeedbackService,
  ) {
    this.form = this.fb.group({
      Name: [''],
      Phone: [''],
      Email: [''],
      Message: ['', [Validators.required]],
      SourceId: [this.sourceId],
      DestinationId: [this.destinationId],
      SourceNodeDataId: [this.sourceNodeDataId],
      DestinationNodeDataId: [this.destinationNodeDataId]
    });
  }

  ngOnInit() {
    this.form.controls.Email.setValue(this.email);
    this.form.controls.Name.setValue(this.name);
    this.form.controls.Phone.setValue(this.phoneNumber);
  }

  submit() {
    if (this.form.valid) {
      const data = this.form.value;
      data.SourceId = this.sourceId;
      data.DestinationId = this.destinationId;
      data.SourceNodeDataId = this.sourceNodeDataId;
      data.DestinationNodeDataId = this.destinationNodeDataId;
      this.userFeedbackService.add(data).subscribe(res => {
        this.modal.close('');
      });
    }
  }

  closeModal(event: string) {
    // this.dialogRef.close({event: event, data: data});
    this.modal.dismiss(event);
  }
}

import { RouterTestingModule } from '@angular/router/testing';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ColumnTable } from 'src/app/models/column-table/column-table.component';
import { UserService } from 'src/app/services/user.service';
import { NgbModal, NgbActiveModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { NewUserModalComponent } from '../new-user-modal/new-user-modal.component';
import { ActionModel } from 'src/app/models/table-action-model';
import { ToastrService } from 'ngx-toastr';
import { Users } from 'src/app/models/users';

@Component({
  selector: 'app-manage-user',
  templateUrl: './manage-user.component.html',
  styleUrls: ['./manage-user.component.scss']
})
export class ManageUserComponent implements OnInit {

  columns: ColumnTable[];
  userTotal = 100;
  userData: any;
  selectAction: any;
  actionEdit: any;
  userSortBy = 'name';
  userOrderBy = 'asc';
  isShowResult = false;
  userLimit = 10;
  userPageIndex = 1;
  modalRef: any;
  userDetail: Users;
  isSelfSort = true;
  rawData: any;
  isLoading = true;

  constructor(
    private router: Router,
    private userService: UserService,
    private toastr: ToastrService,
    private modal: NgbActiveModal,
    private modalService: NgbModal
  ) {

  }

  ngOnInit() {
    sessionStorage.setItem('isHome', 'false');
    sessionStorage.setItem('isRedirectSearchFromFile', 'false');
    
    this.columns = [
      new ColumnTable('Name', 'name', 'col-2', 'name', true, 'name'),
      new ColumnTable('Organization', 'organization', 'col-2', 'organization', true, 'organization'),
      new ColumnTable('Email', 'email', 'col-2', 'text', true, 'email'),
      new ColumnTable('Phone', 'phone', 'col-2', 'text', true, 'phone'),
      new ColumnTable('Role', 'role', 'col-1', 'text', true, 'role'),
      new ColumnTable('Status', 'status', 'col-1', 'select', true, 'status'),
      new ColumnTable('', 'edit', 'col-2 text-right', 'iconEdit', true),
    ];

    this.selectAction = new ActionModel({
        text: 'Status',
        icon: '',
        callBack: (data, status) => this.updateStatus(data, status),
        class: 'text-danger pl-1 pr-2'
      });

    this.actionEdit = new ActionModel({
      text: 'Edit',
      icon: 'fas fa-edit',
      callBack: (data) => this.openModal(data),
      class: 'text-warning'
    });

    this.userService.getAll().subscribe(res => {
      this.userData = res;
      this.rawData =  res;
      this.userTotal = this.userData.length;
      this.isLoading = false;
    });
  }

  onTableOwnPageChange(event) {
    this.userLimit = event.pageSize;
    this.userPageIndex = event.pageIndex;
  }

  signout() {
    localStorage.removeItem('jwt');
    this.router.navigate(['']);
  }

  openModal(data: any) {
    const ngbModalOptions: NgbModalOptions = {
      backdrop : 'static',
      keyboard : false,
      windowClass: 'modal-invite-new-people',
    };
    this.modalRef = this.modalService.open(
      NewUserModalComponent, ngbModalOptions
    );

    if (data) {
      this.modalRef.componentInstance.id = data.id;
      this.modalRef.componentInstance.isUpdate = true;
      this.modalRef.componentInstance.title = 'Update User';
      this.modalRef.result.then((res: any) => {
      }, (reason) => {
        if (reason === 'Success') {
          this.userService.getAll().subscribe(res => {
            this.userData = res;
            this.userTotal = this.userData.length;
          });
        }
      });
    } else {
      this.modalRef.result.then((result) => {
      }, (reason) => {
        if (reason === 'Success') {
          this.userService.getAll().subscribe(res => {
            this.userData = res;
            this.userTotal = this.userData.length;
          });
        }
      });
    }
  }

  updateStatus(data: any, status: any) {
    const userDetail = {
      Id: data.id,
      Status: status
    };
    this.userService.updatestatus(data.id, userDetail).subscribe(res => {
      if (res) {
        this.toastr.success('Update Status Success');
      } else {
        this.toastr.error('Update Status Error');
      }
    });
  }

  userItemSelected(data) {

  }

  onSortChange(event) {
    if (event.sortBy === 'name') {
      this.userData = this.rawData.sort((a, b) => {
        if (a.firstName + a.lastName < b.firstName + b.lastName) {
          return event.orderBy === 'asc' ? -1 : 1;
        } else if (a.firstName + a.lastName > b.firstName + b.lastName)  {
          return event.orderBy === 'asc' ? 1 : -1;
        } else {
          return 0;
        }
      });
    } else if (event.sortBy === 'organization') {
      this.userData = this.rawData.sort((a, b) => {
        const aValue = a.organization ? a.organization.name : a.organizationOther;
        const bValue = b.organization ? b.organization.name : b.organizationOther;
        if (aValue < bValue) {
          return event.orderBy === 'asc' ? -1 : 1;
        } else if (aValue > bValue)  {
          return event.orderBy === 'asc' ? 1 : -1;
        } else {
          return 0;
        }
      });
    } else {
      this.userData = this.rawData.sort((a, b) => {
        if (a[event.sortBy] < b[event.sortBy]) {
          return event.orderBy === 'asc' ? -1 : 1;
        } else if (a[event.sortBy] > b[event.sortBy])  {
          return event.orderBy === 'asc' ? 1 : -1;
        } else {
          return 0;
        }
      });
    }
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class UserFeedbackService {
  private actionUrl = 'api/userFeedback/';

  constructor(private http: HttpClient) {}

  public getAll<T>(): Observable<T> {
    return this.http.get<T>(this.actionUrl);
  }

  public getUnRead<T>(): Observable<T> {
    return this.http.get<T>(this.actionUrl + 'UnRead');
  }

  public get<T>(id): Observable<T> {
    return this.http.get<T>(this.actionUrl + id);
  }

  public delete<T>(id): Observable<T> {
    return this.http.delete<T>(this.actionUrl + id);
  }

  public add<T>(data): Observable<T> {
    return this.http.post<T>(this.actionUrl + 'Create', data);
  }

  public update<T>(data): Observable<T> {
    return this.http.post<T>(this.actionUrl + 'Update', data);
  }

  public getSpecific<T>(sourceId, sourceDataId, destinationId, destinationDataId): Observable<T> {
    return this.http.get<T>(this.actionUrl + 'Specific?sourceId=' + sourceId + '&sourceDataId=' + sourceDataId + '&destinationId=' + destinationId + '&destinationDataId=' + destinationDataId);
  }

}

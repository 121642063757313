import { AdvanceSearchResultComponent } from './pages/advance-search-result/advance-search-result.component';
import { BasicSearchResultComponent } from './pages/basic-search-result/basic-search-result.component';
import { ContactUsComponent } from './pages/contact-us/contact-us.component';
import { SettingMainComponent } from './pages/setting/setting-main/setting-main.component';
// tslint:disable-next-line:max-line-length
import { MappingWaitingApproveListComponent } from './pages/mapping-schemas/mapping-waiting-approve-list/mapping-waiting-approve-list.component';
import { MappingWaitingApproveComponent } from './pages/mapping-schemas/mapping-waiting-approve/mapping-waiting-approve.component';
import { MappingListComponent } from './pages/mapping-schemas/mapping-list/mapping-list.component';
import { MappingManualComponent } from './pages/mapping-schemas/mapping-manual/mapping-manual.component';
import { MappingNodeComponent } from './pages/mapping-schemas/mapping-node/mapping-node.component';

import { NodeManagementComponent } from './pages/node-managements/node-management/node-management.component';
import { SignUpComponent } from './pages/sign-up/sign-up.component';
import { AuthGuard } from './services/auth-guard.service';
import { LoginComponent } from './pages/login/login.component';
import { HomeComponent } from './pages/home/home.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { NewUserModalComponent } from './pages/new-user-modal/new-user-modal.component';
import { ManageUserComponent } from './pages/manage-user/manage-user.component';
import { NodeAddComponent } from './pages/node-managements/node-add/node-add.component';
import { UserFeedbackComponent } from './pages/user-feedback/user-feedback.component';
import { NodeDataListComponent } from './pages/node-managements/node-data-list/node-data-list.component';
import { MappingManagementComponent } from './pages/mapping-schemas/mapping-management/mapping-management.component';
import { MappingAddComponent } from './pages/mapping-schemas/mapping-add/mapping-add.component';
import { MappingApprovedComponent } from './pages/mapping-schemas/mapping-approved/mapping-approved.component';
import { MappingApprovedListComponent } from './pages/mapping-schemas/mapping-approved-list/mapping-approved-list.component';
import { ContactUsListComponent } from './pages/contact-us-list/contact-us-list.component';
import { NotificationComponent } from './pages/notification/notification.component';
import { SearchFromFileComponent } from './pages/search-from-file/search-from-file.component';
import { ChangelogComponent } from './pages/changelog/changelog.component';
import { NodeDetailComponent } from './pages/node-managements/node-detail/node-detail.component';
import { MappingCreateComponent } from './pages/mapping-schemas/mapping-create/mapping-create.component';


const routes: Routes = [
  { path: '', component: HomeComponent, pathMatch: 'full'},
  { path: 'basic-search-result', component: BasicSearchResultComponent },
  { path: 'advance-search-result', component: AdvanceSearchResultComponent },
  { path: 'sign-in', component: LoginComponent },
  { path: 'sign-up', component: SignUpComponent },
  // tslint:disable-next-line:max-line-length
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard], data: { roles: 'Admin,Approver,Mapper', showSideBar: true} },
  { path: 'manage-user', component: ManageUserComponent, canActivate: [AuthGuard], data: { roles: 'Admin', showSideBar: true} },
  // tslint:disable-next-line:max-line-length
  { path: 'node-management', component: NodeManagementComponent, canActivate: [AuthGuard], data: { roles: 'Admin,Approver,Mapper', showSideBar: true} },
  // tslint:disable-next-line:max-line-length
  { path: 'node-management/add', component: NodeAddComponent, canActivate: [AuthGuard], data: { roles: 'Admin', showSideBar: true} },
  // tslint:disable-next-line:max-line-length
  { path: 'node-management/edit/:id', component: NodeAddComponent, canActivate: [AuthGuard], data: { roles: 'Admin,Approver,Mapper', showSideBar: true} },
  // tslint:disable-next-line:max-line-length
  { path: 'node-management/data-list/:id', component: NodeDataListComponent, canActivate: [AuthGuard], data: { roles: 'Admin,Approver,Mapper', showSideBar: true} },
  { path: 'user-feedback', component: UserFeedbackComponent, canActivate: [AuthGuard], data: { roles: 'Admin', showSideBar: true} },
  { path: 'mapping-schema', component: MappingManagementComponent, canActivate: [AuthGuard], data: { roles: 'Admin', showSideBar: true} },
  { path: 'mapping-schema/add', component: MappingAddComponent, canActivate: [AuthGuard], data: { roles: 'Admin', showSideBar: true} },
  { path: 'mapping-schema/create', component: MappingCreateComponent, canActivate: [AuthGuard], data: { roles: 'Admin', showSideBar: true} },
  // tslint:disable-next-line:max-line-length
  { path: 'mapping-schema/edit/:source_id/:destination_id', component: MappingAddComponent, canActivate: [AuthGuard], data: { roles: 'Admin', showSideBar: true} },
  // tslint:disable-next-line:max-line-length
  { path: 'mapping-management', component: MappingNodeComponent, canActivate: [AuthGuard], data: { roles: 'Admin,Approver,Mapper', showSideBar: true} },
  // tslint:disable-next-line:max-line-length
  { path: 'mapping-management/list/:source_id/:destination_id', component: MappingListComponent, canActivate: [AuthGuard], data: { roles: 'Admin,Approver,Mapper', showSideBar: true} },
  // tslint:disable-next-line:max-line-length
  { path: 'mapping-management/add', component: MappingManualComponent, canActivate: [AuthGuard], data: { roles: 'Admin,Approver,Mapper', showSideBar: true} },
  // tslint:disable-next-line:max-line-length
  { path: 'mapping-management/add/:sourceId/:destinationId', component: MappingManualComponent, canActivate: [AuthGuard], data: { roles: 'Admin,Approver,Mapper', showSideBar: true} },
  // tslint:disable-next-line:max-line-length
  { path: 'mapping-management/edit/:id', component: MappingManualComponent, canActivate: [AuthGuard], data: { roles: 'Admin,Approver,Mapper', showSideBar: true} },
  // tslint:disable-next-line:max-line-length
  { path: 'mapping-waiting-approve', component: MappingWaitingApproveComponent, canActivate: [AuthGuard], data: { roles: 'Admin,Approver', showSideBar: true} },
  // tslint:disable-next-line:max-line-length
  { path: 'mapping-waiting-approve/list/:source_id/:destination_id', component: MappingWaitingApproveListComponent, canActivate: [AuthGuard], data: { roles: 'Admin,Approver', showSideBar: true} },
  // tslint:disable-next-line:max-line-length
  { path: 'mapping-waiting-approve/approve/:id', component: MappingManualComponent, canActivate: [AuthGuard], data: { roles: 'Admin,Approver', mode: 'approve', showSideBar: true} },
  // tslint:disable-next-line:max-line-length
  { path: 'mapping-approved', component: MappingApprovedComponent, canActivate: [AuthGuard], data: { roles: 'Admin,Approver,Mapper', showSideBar: true} },
  // tslint:disable-next-line:max-line-length
  { path: 'mapping-approved/list/:source_id/:destination_id', component: MappingApprovedListComponent, canActivate: [AuthGuard], data: { roles: 'Admin,Approver,Mapper', showSideBar: true} },
  // tslint:disable-next-line:max-line-length
  { path: 'mapping-approved/viewDetail/:id', component: MappingManualComponent, canActivate: [AuthGuard], data: { roles: 'Admin,Approver,Mapper', mode: 'viewDetail', showSideBar: true} },
  { path: 'setting', component: SettingMainComponent, canActivate: [AuthGuard], data: { roles: 'Admin', showSideBar: true} },
  { path: 'contact-us', component: ContactUsComponent, data: { roles: 'Admin', showSideBar: false} },
  { path: 'contact-us/list', component: ContactUsListComponent, canActivate: [AuthGuard], data: { roles: 'Admin', showSideBar: true} },
  { path: 'management/contact-us', component: ContactUsComponent, canActivate: [AuthGuard], data: { roles: 'Admin', showSideBar: true} },
  // tslint:disable-next-line:max-line-length
  { path: 'notification', component: NotificationComponent, canActivate: [AuthGuard], data: { roles: 'Admin,Approver,Mapper', showSideBar: true} },
  { path: 'search-from-file', component: SearchFromFileComponent, canActivate: [AuthGuard], data: { roles: 'Admin,Approver,Mapper,User'} },
  { path: 'changelog', component: ChangelogComponent },
  { path: 'node-detail/:name/:id', component: NodeDetailComponent },
  { path: 'user-feedback/:source_id/:source_data_id/:destination_id/:destination_data_id', component: UserFeedbackComponent, canActivate: [AuthGuard], data: { roles: 'Admin', showSideBar: true} },
  { path: 'mapping-approved/list/:source_id/:destination_id/mappingId/:mapping_id', component: MappingApprovedListComponent, canActivate: [AuthGuard], data: { roles: 'Admin', showSideBar: true} },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { NodeDataService } from 'src/app/services/node-data.service';
import { MappingNodeService } from 'src/app/services/mapping-node.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { NodeService } from 'src/app/services/node.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  countUser: unknown;
  countApprove: unknown;
  waitingApproveList: [];
  nodes: any;

  constructor(
    private router: Router,
    public userService: UserService,
    private mappingNodeService: MappingNodeService,
    private nodeService: NodeService) { }

  ngOnInit() {
    sessionStorage.setItem('isHome', 'false');
    sessionStorage.setItem('isRedirectSearchFromFile', 'false');
    sessionStorage.setItem('isRedirectSearch', 'false');

    this.userService.getCount().subscribe(res => {
      this.countUser = res;
    });
    this.mappingNodeService.getCountApproved().subscribe(res => {
      this.countApprove = res;
    });
    this.mappingNodeService.getAllWaitingApprove().subscribe((res: []) => {
      this.waitingApproveList = res;
    });
    this.nodeService.getNodeCount().subscribe(res => {
      this.nodes = res;
    });
  }

  goDataNode() {
    this.router.navigateByUrl('node-management/add');
  }

}

import { HubConnection, HubConnectionBuilder } from '@aspnet/signalr';
import { Injectable, EventEmitter } from '@angular/core';
import * as jwt_decode from 'jwt-decode';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {PlatformLocation } from '@angular/common';
@Injectable({
  providedIn: 'root'
})
export class NotifyService {
  messageReceived = new EventEmitter<any>();
  connectionEstablished = new EventEmitter<boolean>();
  notification = new EventEmitter<any>();
  contactUs = new EventEmitter<any>();
  feedback = new EventEmitter<any>();
  suggestion = new EventEmitter<any>();
  importNodeData = new EventEmitter<any>();
  importMapping = new EventEmitter<any>();
  suggessMapping = new EventEmitter<any>();
  showSidebar = new EventEmitter<any>();

  private connectionIsEstablished = false;
  private hubConnection: HubConnection;
  currentUser: any;
  userRole: any;
  private actionUrl = 'api/notification/';

  constructor(private http: HttpClient, private platformLocation: PlatformLocation) {
    this.createConnection();
    this.registerOnServerEvents();
    this.startConnection();
  }

  sendMessage(message: any) {
    this.hubConnection.invoke('NewMessage', message);
  }

  private createConnection() {
    this.hubConnection = new HubConnectionBuilder()
      .withUrl((this.platformLocation as any).location.origin + '/notify')
      .build();
  }

  private startConnection(): void {
    this.hubConnection
      .start()
      .then(() => {
        this.connectionIsEstablished = true;
        this.connectionEstablished.emit(true);
      })
      .catch(err => {
        setTimeout(function() {
          this.startConnection();
        }, 5000);
      });
  }

  private registerOnServerEvents(): void {
    this.hubConnection.on('BroadcastMessage', (type: string, payload: string) => {
      const token = localStorage.getItem('jwt');
      let currentUser = '';
      let userRole = '';
      if (token) {
        const decoded = jwt_decode(token);
        currentUser = decoded.unique_name;
        userRole = decoded.role;
      }
      if (type == 'contactUs') {
        this.contactUs.emit(payload);
      } else if (type == 'feedback') {
        this.feedback.emit(payload);
      } else if (type.indexOf('SuggestionMapping') > -1) {
        const tmp = type.split('/');
        if (currentUser == tmp[1]) {
          this.suggestion.emit(payload);
        }
      } else if (type.indexOf('ImportNodeData') > -1) {
        const tmp = type.split('/');
        if (currentUser == tmp[1]) {
          this.importNodeData.emit(payload);
        }
      } else if (type.indexOf('ImportMapping') > -1) {
        const tmp = type.split('/');
        if (currentUser == tmp[1]) {
          this.importMapping.emit(payload);
        }
      } else if (type.indexOf('SuggessMapping') > -1) {
        const tmp = type.split('/');
        if (currentUser == tmp[1]) {
          this.suggessMapping.emit(payload);
        } else if (type.indexOf(userRole) > -1) {
          this.suggessMapping.emit(payload);
        }
      } else {
        const tmp = type.split('/');
        if (tmp[0] === 'Creater' && currentUser == tmp[1]) {
          this.notification.emit(payload);
        }  else if (type.indexOf(userRole) > -1) {
          this.notification.emit(payload);
        }
      }
    });
  }

  public getAll() {
    return this.http.get(this.actionUrl);
  }

  public update<T>(data): Observable<T> {
    return this.http.post<T>(this.actionUrl + 'Update', data);
  }

  public delete<T>(id): Observable<T> {
    return this.http.delete<T>(this.actionUrl + id);
  }

  public deleteAll<T>(ids): Observable<T> {
    return this.http.post<T>(this.actionUrl + 'DeleteAll', ids);
  }
}

class ActionModel {
    public icon: string;
    public text: string;
    public class: string;
    // tslint:disable-next-line:ban-types
    public callBack: Function;
    public type: string;

    public constructor(object: any) {
        this.icon = object.icon;
        this.text = object.text;
        this.callBack = object.callBack;
        this.class = object.class;
        this.type = object.type;
    }
}
export {ActionModel};




import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, Validators, AbstractControl, ValidationErrors, FormGroup } from '@angular/forms';
import { UserService } from 'src/app/services/user.service';
import { AuthService } from 'angularx-social-login';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { Users } from 'src/app/models/users';
import { OrganizationService } from 'src/app/services/organization.service';

@Component({
  selector: 'app-new-user-modal',
  templateUrl: './new-user-modal.component.html',
  styleUrls: ['./new-user-modal.component.css']
})
export class NewUserModalComponent implements OnInit {

  form: FormGroup;
  user: any;
  id: any;
  organizations: any;
  isUpdate = false;
  isAdmin = true;
  title = 'New User';
  data: any;

  constructor(
    private fb: FormBuilder,
    private userService: UserService,
    private authService: AuthService,
    private toastr: ToastrService,
    public modal: NgbActiveModal,
    private modalService: NgbModal,
    private organizationService: OrganizationService
  ) {
  }

  ngOnInit() {
    this.authService.authState.subscribe((user) => {
      if (user) {
        this.authService.signOut(true);
      }
    });
    this.organizationService.getAll().subscribe((res) => {
      this.organizations = res;
    });

    this.form = this.fb.group({
      FirstName: ['', [Validators.required]],
      LastName: ['', [Validators.required]],
      Password: ['', [Validators.minLength(6), Validators.required]],
      OrganizationId: ['', Validators.required],
      OrganizationOther: [''],
      Phone: ['', [Validators.required, Validators.minLength(9), Validators.maxLength(10)]],
      Email: ['', [Validators.required, Validators.email], this.validateEmailDuplicate.bind(this)],
      Role: ['User', Validators.required],
      Status: ['waiting', Validators.required],
    });
    if (this.isUpdate) {
      this.userService.get(this.id).subscribe((result: any) => {
        result.organizationId === null ? result.organizationId = '99999' : result.organizationId = result.organizationId;
        this.form = this.fb.group({
          FirstName: [result.firstName, [Validators.required]],
          LastName: [result.lastName, [Validators.required]],
          Password: ['', Validators.minLength(6)],
          OrganizationId: [result.organizationId, Validators.required],
          OrganizationOther: [result.organizationOther],
          Phone: [result.phone],
          Email: [result.email, [Validators.required, Validators.email], this.validateEmailDuplicate.bind(this)],
          Role: [result.role, Validators.required],
          Status: [result.status, Validators.required],
        });
      });
    }
  }

  submit() {
    if ( this.form.valid && this.isUpdate ) {
      this.userService.update(this.id, this.form.value).subscribe(res => {
        this.toastr.success('Update User Success');
        this.closeModal('Success');
      });
    } else if (this.form.valid && !this.isUpdate) {
      this.userService.generateUser(this.form.value).subscribe(res => {
        this.toastr.success('Created User Success');
        this.closeModal('Success');
      });
    }
  }

  validateEmailDuplicate({value}: AbstractControl): Observable<ValidationErrors | null> {
    const data = {
      Email: value,
      Id: this.id
    };

    return this.userService.checkEmailDuplicate(data)
      .pipe(debounceTime(500), map((emailDuplicate: boolean) => {
        if (emailDuplicate) {
          this.form.controls.Password.disable();
          this.form.controls.Password.setValue('');
          return {
            isDuplicate: true
          };
        } else {
          this.form.controls.Password.enable();
        }
        return null;
    }));
  }

  closeModal(event: string) {
    // this.dialogRef.close({event: event, data: data});
    this.modal.dismiss(event);
  }

  generatePassword() {
    if (!this.form.controls.Password.disabled) {
      this.form.controls.Password.setValue(Math.random().toString().slice(-6));
    }
  }

}

import { NotifyService } from 'src/app/services/notify.service';
import { MappingSchemaService } from './../../../services/mapping-schema.service';
import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { MappingNodeService } from 'src/app/services/mapping-node.service';

import { saveAs as importedSaveAs } from 'file-saver';
import { TimeoutError } from 'rxjs';
import { SettingService } from 'src/app/services/setting.service';
import { HttpErrorResponse } from '@angular/common/http';
@Component({
  selector: 'app-mapping-import-suggest-modal',
  templateUrl: './mapping-import-suggest-modal.component.html',
  styleUrls: ['./mapping-import-suggest-modal.component.css']
})
export class MappingImportSuggestModalComponent implements OnInit {

  nodeId: any;
  fileName: string;
  allowFileTypes = ['xlsx'];
  file: File;
  isSuccess: boolean;
  countDuplicate: any;
  countSuccess: any;
  countError: any;

  sourceId: any;
  destinationId: any;
  countNotFound: any;
  data = [];
  selectedItem = 0;
  suggestMappingSelected: boolean;
  hasFile: boolean;
  progress = 0;
  isWaiting = false;
  limitFilSizeValue = 0;
  fileSizeInvalid: boolean;
  countApprove: any;
  constructor(public activeModal: NgbActiveModal,
              private toastr: ToastrService,
              private mappingNodeService: MappingNodeService,
              private mappingSchemaService: MappingSchemaService,
              private notifyService: NotifyService,
              private settingService: SettingService) { }

  ngOnInit() {
    this.mappingSchemaService.getAll().subscribe((res: any) => {
      // tslint:disable-next-line:forin
      for (const key in res) {
        const result = {
          Source_id: res[key].nodeSource.id,
          Destination_id: res[key].nodeDestination.id,
          Source: res[key].nodeSource.name,
          Destination: res[key].nodeDestination.name,
          Checked: false,
          Score: res[key].score
        };
        this.data.push(result);
      }
    });
    this.notifyService.importMapping.subscribe(res => {
      this.progress = res;
    });
    this.settingService.get({name: 'FileSize'}).subscribe((res: any) => {
      if (res) {
        this.limitFilSizeValue = res.value;
      }
    });
  }

  closeModal() {
    this.activeModal.close('close');
  }

  onFileChange(event) {
    this.fileSizeInvalid = false;
    const target = event.target as HTMLInputElement;
    if (target.files && target.files[0]) {
      if (!this.validateFile(target.files[0].name)) {
        this.toastr.error('Selected file format is not supported');
        return false;
      }
      const fileSize = target.files[0].size / 1024 / 1024;
      if (fileSize > this.limitFilSizeValue) {
        this.fileSizeInvalid = true;
      } else {
        this.file = target.files[0];
        this.fileName = target.files[0].name;
        this.hasFile = false;
      }

    }
  }

  validateFile(name) {
    const ext = name.substring(name.lastIndexOf('.') + 1);
    if (this.allowFileTypes.indexOf(ext.toLowerCase()) > -1) {
        return true;
    } else {
        return false;
    }
  }

  dataSelected(event) {
    this.suggestMappingSelected = false;
  }

  upload() {
    this.progress = 0;
    this.suggestMappingSelected = false;
    this.hasFile = false;
    if (!this.selectedItem) {
      this.suggestMappingSelected = true;
    }
    if (!this.file) {
      this.hasFile = true;
    }
    if (!this.suggestMappingSelected && !this.hasFile) {
      this.isWaiting = true;
      const data = new FormData();
      data.append('FileImport', this.file);
      data.append('SourceId', this.data[this.selectedItem].Source_id);
      data.append('DestinationId', this.data[this.selectedItem].Destination_id);
      this.isSuccess = false;
      this.mappingNodeService.import(data).subscribe((res: any) => {
        this.toastr.success('Import success.');
        this.isSuccess = true;
        this.countDuplicate = res.countDuplicate;
        this.countError = res.countError;
        this.countSuccess = res.countSuccess;
        this.countNotFound = res.countNotFound;
        this.countApprove = res.countApprove;
        if (this.countDuplicate > 0 || this.countError > 0 ||  this.countNotFound ) {
          const byteArray = new Uint8Array(res.file);
          const blob = this.FileContenttoBlob(res.file, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
          importedSaveAs(blob, 'ErrorData.xlsx');
        }
      }, error => {
        if (+error.status === 504) { // timeout
          // tslint:disable-next-line:max-line-length
        this.toastr.info('Server กำลังประมวลผล ซึ่งอาจใช้เวลานาน กรุณาปิดหน้านี้แล้วรอแจ้งผลการทำงานในเมนู Notification', '', {disableTimeOut: true})
        .onHidden.subscribe(x => {
          this.closeModal();
        });
        }
      });
    }
  }

  public base64toBlob(base64Data) {
    const sliceSize = 1024;
    const byteCharacters = atob(base64Data);
    const bytesLength = byteCharacters.length;
    const slicesCount = Math.ceil(bytesLength / sliceSize);
    const byteArrays = new Array(slicesCount);

    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      const begin = sliceIndex * sliceSize;
      const end = Math.min(begin + sliceSize, bytesLength);

      const bytes = new Array(end - begin);
      for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays);
  }

  public  FileContenttoBlob(fileContent, fileType) {
    const sliceSize = 1024;
    const byteCharacters = atob(fileContent);
    const bytesLength = byteCharacters.length;
    const slicesCount = Math.ceil(bytesLength / sliceSize);
    const byteArrays = new Array(slicesCount);

    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      const begin = sliceIndex * sliceSize;
      const end = Math.min(begin + sliceSize, bytesLength);

      const bytes = new Array(end - begin);
      for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, { type: fileType});
  }

  donwloadTemplate() {
    if (!this.selectedItem) {
      this.suggestMappingSelected = true;
    }
    if (!this.suggestMappingSelected) {
      // tslint:disable-next-line:max-line-length
      this.mappingNodeService.downloadTemplate(this.data[this.selectedItem].Source_id, this.data[this.selectedItem].Destination_id).subscribe((blob: any) => {
        importedSaveAs(blob, 'MappingTemplate');
      });
    }
  }
}

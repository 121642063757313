import { SettingService } from './../../../services/setting.service';
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-setting-file-size',
  templateUrl: './setting-file-size.component.html',
  styleUrls: ['./setting-file-size.component.css']
})
export class SettingFileSizeComponent implements OnInit {
  limitFilSizeValue: 0;
  fileSizeInvalid = false;
  constructor(
    private settingService: SettingService,
    private toastr: ToastrService,
  ) { }

  ngOnInit() {
    this.settingService.get({name: 'FileSize'}).subscribe((res: any) => {
      if (res) {
        this.limitFilSizeValue = res.value;
      }
    });
  }

  create() {
    this.fileSizeInvalid = false;
    const data = {
      name: 'FileSize',
      value: this.limitFilSizeValue,
    };
    if (this.limitFilSizeValue <= 0) {
      this.fileSizeInvalid = true;
    } else {
      this.settingService.create(data).subscribe(res => {
        this.toastr.success('Save Success');
      });
    }
  }

}

import { Component, OnInit } from '@angular/core';
import { MappingSchemaService } from 'src/app/services/mapping-schema.service';
import { ColumnTable } from 'src/app/models/column-table/column-table.component';
import { ActionModel } from 'src/app/models/table-action-model';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmDialogComponent } from 'src/app/base-components/dialogs/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-mapping-management',
  templateUrl: './mapping-management.component.html',
  styleUrls: ['./mapping-management.component.scss']
})
export class MappingManagementComponent implements OnInit {

  MappingColumns: ColumnTable[];
  limit = 10;
  pageIndex = 1;
  isShowResult = false;
  total = 10;
  datas;

  actionEdit: ActionModel;
  actionDelete: ActionModel;
  actions = [];
  userSortBy = 'source';
  userOrderBy = 'asc';
  rawData: any[];
  constructor(
    private mappingSchemaService: MappingSchemaService,
    private router: Router,
    private toastr: ToastrService,
    private modal: NgbModal,
  ) { }

  ngOnInit() {
    sessionStorage.setItem('isHome', 'false');
    sessionStorage.setItem('isRedirectSearchFromFile', 'false');

    this.mappingSchemaService.getAll().subscribe((res: any) => {
      // tslint:disable-next-line:forin
      for (const key in res) {
        const result = {
          Source_id: res[key].nodeSource.id,
          Destination_id: res[key].nodeDestination.id,
          Source: res[key].nodeSource.name,
          Destination: res[key].nodeDestination.name,
          createdAt: res[key].createdAt,
          updatedAt: res[key].updatedAt,
          user: res[key].user.firstName + ' ' + res[key].user.lastName
        };

        if(!this.datas)
        {
          this.datas = [];
        }
        this.datas.push(result);
      }
      this.rawData = this.datas;
      this.total = this.datas.length;
    });

    this.MappingColumns = [
      new ColumnTable('Source', 'Source', 'col-2', 'text', true, 'source'),
      new ColumnTable('Destination', 'Destination', 'col-2', 'text', true, 'destination'),
      new ColumnTable('Create date', 'createdAt', 'col-2', 'createDate', true, 'createdAt'),
      new ColumnTable('Last update', 'updatedAt', 'col-2', 'ModifyDate', true, 'updatedAt'),
      new ColumnTable('User', 'user', 'col-2', 'text', true, 'user'),
    ];
    this.actions.push(new ActionModel({
      text: 'Edit',
      icon: 'fas fa-edit',
      callBack: (data) => this.editMapping(data),
      class: 'text-warning',
      type: 'edit'
    }));
    this.actions.push(new ActionModel({
      text: 'Delete',
      icon: 'fas fa-trash',
      callBack: (data) => this.deleteMapping(data),
      class: 'text-danger pl-1 pr-2',
      type: 'delete'
    }));
  }

  editMapping(data) {
    this.router.navigate(['/mapping-schema/edit/' + data.Source_id + '/' + data.Destination_id]);
  }

  deleteMapping(data) {
    const modalRef = this.modal.open(ConfirmDialogComponent);
    modalRef.componentInstance.data = 'Semantic mapping : ' + data.Source + ',' + data.Destination;
    modalRef.result.then((result) => {
      if (result) {
        this.mappingSchemaService.delete(data.Source_id, data.Destination_id).subscribe(res => {
          this.toastr.success('Delete Success');
          window.location.reload();
        });
      }
    }, (reason) => {
    });
  }

  onTablePageChange(event) {
    this.limit = event.pageSize;
    this.pageIndex = event.pageIndex;
  }

  userItemSelected(data) {

  }

  onSortChange(event) {
    if (event.sortBy === 'user') {
      this.datas = this.rawData.sort((a, b) => {
        if (a.user < b.user) {
          return event.orderBy === 'asc' ? -1 : 1;
        } else if (a.user > b.user)  {
          return event.orderBy === 'asc' ? 1 : -1;
        } else {
          return 0;
        }
      });
    } else if (event.sortBy === 'source') {
      this.datas = this.rawData.sort((a, b) => {
        const aValue = a.Source;
        const bValue = b.Source;
        if (aValue < bValue) {
          return event.orderBy === 'asc' ? -1 : 1;
        } else if (aValue > bValue)  {
          return event.orderBy === 'asc' ? 1 : -1;
        } else {
          return 0;
        }
      });
    } else if (event.sortBy === 'destination') {
      this.datas = this.rawData.sort((a, b) => {
        const aValue = a.Destination;
        const bValue = b.Destination;
        if (aValue < bValue) {
          return event.orderBy === 'asc' ? -1 : 1;
        } else if (aValue > bValue)  {
          return event.orderBy === 'asc' ? 1 : -1;
        } else {
          return 0;
        }
      });
    } else if (event.sortBy === 'createdAt') {
      this.datas = this.rawData.sort((a, b) => {
        const aValue = new Date(a.createdAt);
        const bValue = new Date(b.createdAt);
        if (aValue < bValue) {
          return event.orderBy === 'asc' ? -1 : 1;
        } else if (aValue > bValue)  {
          return event.orderBy === 'asc' ? 1 : -1;
        } else {
          return 0;
        }
      });
    } else if (event.sortBy === 'updatedAt') {
      this.datas = this.rawData.sort((a, b) => {
        const aValue = new Date(a.updatedAt);
        const bValue = new Date(b.updatedAt);
        if (aValue < bValue) {
          return event.orderBy === 'asc' ? -1 : 1;
        } else if (aValue > bValue)  {
          return event.orderBy === 'asc' ? 1 : -1;
        } else {
          return 0;
        }
      });
    }
  }
}

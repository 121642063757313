import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class MappingNodeService {
  private actionUrl = 'api/MappingNode/';

  constructor(private http: HttpClient) {}

  public getAll<T>(): Observable<T> {
    return this.http.get<T>(this.actionUrl);
  }

  public getAllWaitingApprove<T>(): Observable<T> {
    return this.http.get<T>(this.actionUrl + 'WaitingApprove');
  }

  public getAllWaitingApproveTotal(sourceId, destinationId): Observable<any> {
    return this.http.get(this.actionUrl + 'WaitingApproveTotal/' + sourceId + '/' + destinationId);
  }

  public getBySourceAndDestination<T>(sourceId, destinationId): Observable<T> {
    return this.http.get<T>(this.actionUrl + sourceId + '/' + destinationId);
  }

  public SearchBySourceAndDestination<T>(criteria): Observable<T> {
    return this.http.post<T>(this.actionUrl + 'SearchMapping', criteria);
  }

  public getBySourceAndDestinationWaitingApprove<T>(sourceId, destinationId): Observable<T> {
    return this.http.get<T>(this.actionUrl + 'WaitingApproved/' + sourceId + '/' + destinationId);
  }

  public SearchBySourceAndDestinationWaitingApprove<T>(criteria): Observable<T> {
    return this.http.post<T>(this.actionUrl + 'SearchMappingWaitingApprove', criteria);
  }

  public downloadBySourceAndDestinationWaitingApprove<T>(criteria): Observable<T> {
    return this.http.post<T>(this.actionUrl + 'DownloadMappingWaitingApprove', criteria, {responseType: 'blob' as 'json'});
  }

  public downloadBySourceAndDestination<T>(criteria): Observable<T> {
    return this.http.post<T>(this.actionUrl + 'DownloadMapping', criteria, {responseType: 'blob' as 'json'});
  }

  public SearchBySourceAndDestinationApprove<T>(criteria): Observable<T> {
    return this.http.post<T>(this.actionUrl + 'SearchMappingApprove', criteria);
  }

  public downloadBySourceAndDestinationApprove<T>(criteria): Observable<T> {
    return this.http.post<T>(this.actionUrl + 'DownloadMappingApprove', criteria, {responseType: 'blob' as 'json'});
  }

  public getAllApproved<T>(): Observable<T> {
    return this.http.get<T>(this.actionUrl + 'Approved');
  }

  public getCountApproved<T>(): Observable<T> {
    return this.http.get<T>(this.actionUrl + 'CountApproved');
  }

  public getRemainingCount<T>(sourceId, destinationId): Observable<T> {
    return this.http.get<T>(this.actionUrl + 'GetRemaining?sourceId=' + sourceId + '&destinationId=' + destinationId);
  }

  public getBySourceAndDestinationApproved<T>(sourceId, destinationId): Observable<T> {
    return this.http.get<T>(this.actionUrl + 'Approved/' + sourceId + '/' + destinationId);
  }

  public get<T>(id): Observable<T> {
    return this.http.get<T>(this.actionUrl + id);
  }

  public getForApprove<T>(id): Observable<T> {
    return this.http.get<T>(this.actionUrl + 'GetForApprove/' + id);
  }

  public getApprove<T>(id): Observable<T> {
    return this.http.get<T>(this.actionUrl + 'GetApprove/' + id);
  }

  public add<T>(data): Observable<T> {
    return this.http.post<T>(this.actionUrl, data);
  }

  public suggestMapping<T>(data): Observable<T> {
    return this.http.post<T>(this.actionUrl + 'suggestMapping', data);
  }

  public previewSuggestMapping<T>(data): Observable<T> {
    return this.http.post<T>(this.actionUrl + 'PreviewSuggestMapping', data);
  }

  public update<T>(data): Observable<T> {
    return this.http.put<T>(this.actionUrl, data);
  }

  public approve<T>(data): Observable<T> {
    return this.http.put<T>(this.actionUrl + 'approve', data);
  }

  public approveAllCheck<T>(data): Observable<T> {
    return this.http.put<T>(this.actionUrl + 'approveAllCheck', data);
  }

  public approveAllInDB<T>(data): Observable<T> {
    return this.http.put<T>(this.actionUrl + 'approveAll', data);
  }

  public reject<T>(data): Observable<T> {
    return this.http.put<T>(this.actionUrl + 'reject', data);
  }

  public delete<T>(id): Observable<T> {
    return this.http.delete<T>(this.actionUrl + 'Delete/' + id);
  }

  public deleteApprove<T>(id): Observable<T> {
    return this.http.delete<T>(this.actionUrl + 'DeleteApprove/' + id);
  }

  public deleteAllApprove<T>(ids): Observable<T> {
    return this.http.post<T>(this.actionUrl + 'DeleteAllApprove', ids);
  }

  public deleteAll<T>(ids): Observable<T> {
    return this.http.post<T>(this.actionUrl + 'DeleteAll', ids);
  }

  public deleteAllPreparation<T>(data): Observable<T> {
    return this.http.post<T>(this.actionUrl + 'DeleteAllPreparation', data);
  }

  public downloadTemplate<T>(sourceId, destinationId): Observable<T> {
    const data = {
      SourceId: sourceId,
      DestinationId: destinationId
    };
    return this.http.post<T>(this.actionUrl + 'DownloadTemplate', data, {responseType: 'blob' as 'json'});
  }

  public import<T>(data): Observable<T> {
    return this.http.post<T>(this.actionUrl + 'import', data);
  }

  public getDisableInvisibalData<T>(sourceId, destinationId): Observable<T> {
    return this.http.get<T>(this.actionUrl + 'GetDisableInvisibalData/' + sourceId + '/' + destinationId);
  }

  public splitMappingDetails<T>(data): Observable<T> {
    return this.http.post<T>(this.actionUrl + 'SplitMappingDetails', data);
  }
}

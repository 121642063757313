import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormArray, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ColumnTypeService } from 'src/app/services/columnType.service';
import { NodeService } from 'src/app/services/node.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MappingSchemaService } from 'src/app/services/mapping-schema.service';
import { ColumnTable } from 'src/app/models/column-table/column-table.component';
import { ActionModel } from 'src/app/models/table-action-model';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { MappingPreviewComponent } from '../mapping-preview/mapping-preview.component';
import { MappingNodeService } from 'src/app/services/mapping-node.service';
import { LoadingScreenService } from 'src/app/services/loading-screen.service';

@Component({
  selector: 'app-mapping-add',
  templateUrl: './mapping-add.component.html',
  styleUrls: ['./mapping-add.component.scss']
})
export class MappingAddComponent implements OnInit {

  form: FormGroup;
  nodes: any;
  columnTypes: unknown;
  columns: any;
  sourceColumns = [];
  DestinationColumns = [];
  source: any;
  destination: any;

  isShowAddMapping = false;

  MappingColumns: ColumnTable[];
  isShowResult = false;
  isShowPage = false;
  limit = 0;
  pageIndex = 1;
  datas = [];
  actionDelete: any;
  suggessMappingDatas = [];

  sourceId = null;
  destinationId = null;
  sourceName: any;
  destinationName: any;
  isReady = false;
  sourceReady: any;
  destinationReady: boolean;
  title = 'Import Semantic mapping';
  sourceDropdown = [];
  isNew: boolean;
  desNode: any;
  prevDatas = [];
  prevCount = 0;
  hasMatching = false;
  betweenCondition: any = [{Display: 'Source in Destination', Value: 1}, {Display: 'Destination in Source', Value: 2}]
  splitMapping;

  constructor(private fb: FormBuilder,
              private toastr: ToastrService,
              private columnTypeService: ColumnTypeService,
              private nodeService: NodeService,
              private mappingSchemaService: MappingSchemaService,
              private mappingNodeService: MappingNodeService,
              private loadingScreenService: LoadingScreenService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private modalService: NgbModal) {
    this.activatedRoute.queryParams.subscribe(params => {
      this.sourceId = activatedRoute.snapshot.params.source_id;
      this.destinationId = activatedRoute.snapshot.params.destination_id;
    });
  }

  ngOnInit() {
    sessionStorage.setItem('isHome', 'false');
    sessionStorage.setItem('isRedirectSearchFromFile', 'false');
    
    this.form = this.fb.group({
      Source: [''],
      Destination: [''],
      Weight: [1,],
      MappingSourceColumns: this.fb.array([], Validators.required),
      MappingDestinationColumns: this.fb.array([], Validators.required),
      ConditionBetweenSourceAndDestination: this.fb.array([], Validators.required)
    });

    this.MappingColumns = [
      new ColumnTable('#', 'no', 'col-1', 'text', true),
      new ColumnTable('Source', 'SourceCondition', 'col-4', 'text', true),
      new ColumnTable('Destination', 'DestinationCondition', 'col-4', 'text', true),
      new ColumnTable('', 'delete', 'col', 'iconDelete', true),
    ];

    this.actionDelete = new ActionModel({
      text: 'Delete',
      icon: 'fas fa-times"',
      callBack: (data) => this.removeMapping(data),
      class: 'text-danger'
    });
    if (this.sourceId && this.destinationId) {
      this.isNew = false;
      this.isReady = false;
      this.title = 'Import Semantic mapping';
      this.selectSource(this.sourceId);
      this.selectDestination(this.destinationId);
      this.nodeService.getDropDown().subscribe((nodeRes: any) => {
        this.nodes = nodeRes;
        this.desNode = nodeRes;
        this.mappingSchemaService.getBySourceDestination(this.sourceId, this.destinationId).subscribe((res: any) => {
          this.isShowAddMapping = true;
          this.sourceName = this.nodes.find(x => x.id === +this.sourceId);
          this.destinationName = this.nodes.find(x => x.id === +this.destinationId);
          res.forEach(element => {
            this.datas.push({
              no: this.datas.length + 1,
              Id: element.id,
              NodeSourceId: element.nodeSourceId,
              NodeDestinationId: element.nodeDestinationId,
              SourceCondition: element.sourceCondition,
              DestinationCondition: element.destinationCondition,
              Score: element.score,
              ContainCondition: element.containCondition

            });
          });
          this.generateDefaultCondition();
          this.initSuggessMapping();
          this.isReady = true;
        });
      });
    } else {
      this.nodeService.getDropDown().subscribe((nodeRes: any) => {
        this.nodes = nodeRes;
        this.desNode = nodeRes;
        this.initSuggessMapping();
      });
      this.isReady = true;
      this.isNew = true;
    }
  }

  addSource(): void {
    this.columns = this.form.get('MappingSourceColumns') as FormArray;
    this.columns.push(this.createColumn());
  }

  deleteSource(index) {
    this.columns = this.form.get('MappingSourceColumns') as FormArray;
    this.columns.removeAt(index);
  }

  addDestination(): void {
    this.columns = this.form.get('MappingDestinationColumns') as FormArray;
    this.columns.push(this.createColumn());
  }

  deleteDestination(index) {
    this.columns = this.form.get('MappingDestinationColumns') as FormArray;
    this.columns.removeAt(index);
  }

  addBetween(): void {
    this.columns = this.form.get('ConditionBetweenSourceAndDestination') as FormArray;
    // this.columns.push(this.createColumn());
    this.columns.clear();
    this.columns.push(this.setDefaultValue(1));
  }

  addMappingCondition(): void {
    this.columns = this.form.get('MappingSourceColumns') as FormArray;
    this.columns.push(this.createColumn());
    this.columns = this.form.get('MappingDestinationColumns') as FormArray;
    this.columns.push(this.createColumn());
    this.columns = this.form.get('ConditionBetweenSourceAndDestination') as FormArray;
    this.columns.push(this.setDefaultValue(1));
  }

  deleteMappingCondition(index) {
    this.columns = this.form.get('MappingSourceColumns') as FormArray;
    this.columns.removeAt(index);
    this.columns = this.form.get('MappingDestinationColumns') as FormArray;
    this.columns.removeAt(index);
    this.columns = this.form.get('ConditionBetweenSourceAndDestination') as FormArray;
    this.columns.removeAt(index);
    this.loadMatchingItem();
  }

  onCalcel(){
    this.router.navigateByUrl('mapping-management/list/' + this.sourceId + '/' + this.destinationId);
  }

  loadMatchingItem(){
    if (this.form.valid) {
      this.loadingScreenService.loadingScreen.emit(true);
      this.prepareDataToSubmit();
      let mapData = this.suggessMappingDatas.filter(x => x.Checked === true).map(x => x.Data);
      let sourceCon = this.datas.map(e => e.SourceCondition).join(",");
      let desCon = this.datas.map(e => e.DestinationCondition).join(",");
      let containCon = this.datas.map(e => e.ContainCondition).join(",");
      mapData[0].prevContainCondition = containCon;
      mapData[0].sourceCondition = sourceCon;
      mapData[0].destinationCondition = desCon;
      mapData[0].splitMapping = this.splitMapping;
      this.mappingNodeService.previewSuggestMapping(mapData).subscribe((res:any) => {
        this.prevDatas = res.data
        this.prevCount = res.total
        this.hasMatching = res.total > 0 ? true : false;
      this.loadingScreenService.loadingScreen.emit(false);
      })
    }
  }

  previewDialog(source: any, destination: any) {
    this.prepareDataToSubmit();

    const mapData = this.suggessMappingDatas.filter(x => x.Checked === true).map(x => x.Data);

    const ngbModalOptions: NgbModalOptions = {
      backdrop : 'static',
      keyboard : false,
      size: 'lg',
      windowClass: 'waiting-approve-view-detail',
    };
    const modalImport = this.modalService.open(MappingPreviewComponent, ngbModalOptions);
    modalImport.componentInstance.nodeId = source;
    modalImport.componentInstance.destinationNode = destination;
    modalImport.componentInstance.sourceCondition = this.datas[this.datas.length -1].SourceCondition;
    modalImport.componentInstance.destinationCondition = this.datas[this.datas.length -1].DestinationCondition;
    modalImport.componentInstance.betweenCodition = this.datas[this.datas.length -1].ConditionBetween;
    modalImport.componentInstance.datas = this.prevDatas;
    modalImport.componentInstance.mapData = mapData;
    modalImport.componentInstance.isSplitMapping = this.splitMapping;
    modalImport.result.then((result) => {
    });
  }

  generateDefaultCondition() {
    this.columns = this.form.get('MappingSourceColumns') as FormArray;
    this.columns.clear();
    this.datas.forEach(res => {
      this.columns.push(this.setDefaultValue(res.SourceCondition));
    });

    this.columns = this.form.get('MappingDestinationColumns') as FormArray;
    this.columns.clear();
    this.datas.forEach(res => {
    this.columns.push(this.setDefaultValue(res.DestinationCondition));
    });

    this.columns = this.form.get('ConditionBetweenSourceAndDestination') as FormArray;
    this.columns.clear();
    this.datas.forEach(res => {
    this.columns.push(this.setDefaultValue(res.ContainCondition));
    });
  }

  createColumn(): FormGroup {
    return this.fb.group({
      Name: ['', Validators.required]
    });
  }

  setDefaultValue(value): FormGroup {
    return this.fb.group({
      Name: [value, Validators.required]
    });
  }

  removeMapping(data) {
    // tslint:disable-next-line:variable-name
    const index_remove = this.datas.findIndex(ele => {
      return ele.SourceCondition === data.SourceCondition && ele.DestinationCondition === data.DestinationCondition;
    });
    this.datas.splice(index_remove, 1);
    // tslint:disable-next-line:only-arrow-functions
    this.datas.map(function(elem, i) {
        return elem.no = i + 1;
    });

  }

  onSubmit() {
    this.prepareDataToSubmit();
      this.isShowAddMapping = true;
  }

  prepareDataToSubmit() {
    if (this.splitMapping == undefined) {
      this.splitMapping = false;
    }
    this.datas = [];
    if (this.form.valid) {
      for (let i = 0; i < this.form.controls.MappingSourceColumns.value.length; i++) {
        this.datas.push({
          no: this.datas.length + 1,
          NodeSourceId: this.form.controls.Source.value || this.sourceId,
          NodeDestinationId: this.form.controls.Destination.value || this.destinationId,
          SourceCondition: this.form.controls.MappingSourceColumns.value[i].Name,
          DestinationCondition: this.form.controls.MappingDestinationColumns.value[i].Name,
          Score: this.form.controls.Weight.value,
          ContainCondition: this.form.controls.ConditionBetweenSourceAndDestination.value[i].Name,
          SplitMapping: this.splitMapping
        });
      }
    }
  }

  addMappingSchema() {
    // tslint:disable-next-line:only-arrow-functions
    this.prepareDataToSubmit();
    this.datas.forEach(function(v) { delete v.no; });
    this.mappingSchemaService.add(this.datas).subscribe(res => {
      this.toastr.success('Add Success');
      this.router.navigateByUrl('mapping-management');
    });
  }

  updateMappingSchema() {
    // tslint:disable-next-line:only-arrow-functions
    this.prepareDataToSubmit();
    this.datas.forEach(function(v) { delete v.no; });
    const data = {
      SourceId: this.sourceId,
      DestinationId: this.destinationId,
      MappingSchemas: this.datas,
    };
    this.mappingSchemaService.update(data).subscribe(res => {
      const mapData = this.suggessMappingDatas.filter(x => x.Checked === true).map(x => x.Data);
      this.loadingScreenService.loadingScreen.emit(true);
      mapData.forEach(element => {
        element.splitMapping = this.splitMapping;
      });
      this.mappingNodeService.suggestMapping(mapData).subscribe((res: number) => {
        this.loadingScreenService.loadingScreen.emit(false);
        this.toastr.success('Update Success');
        this.toastr.success('Suggess Semantic mapping data total ' + res + ' rows');
        this.router.navigateByUrl('mapping-management/list/' + this.sourceId + '/' + this.destinationId);
      });
    });
  }

  selectSource(value: any) {
    this.sourceReady = false;
    if (value !== '') {
      this.source = value;
      if (this.isNew) {
        this.desNode = this.nodes;
        this.mappingSchemaService.filterBySource(value).subscribe((response:any) => {
          response.forEach(element => {
          this.desNode = this.desNode.filter(x => x.id != element);
          });
        });
      }
      this.nodeService.getNodeColumn(value).subscribe(res => {
        this.columns = this.form.get('MappingSourceColumns') as FormArray;
        while (this.columns.length !== 0) {
          this.columns.removeAt(0);
        }
        this.columns = this.form.get('MappingDestinationColumns') as FormArray;
        while (this.columns.length !== 0) {
          this.columns.removeAt(0);
        }
        this.addSource();
        this.addBetween()
        this.sourceColumns = res as [];
        this.sourceReady = true;
      });
    }
  }

  selectDestination(value: any) {
    this.destinationReady = false;
    if (value !== '') {
      this.destination = value;
      this.nodeService.getNodeColumn(value).subscribe(res => {
        this.columns = this.form.get('MappingDestinationColumns') as FormArray;
        while (this.columns.length !== 0) {
          this.columns.removeAt(0);
        }
        this.addDestination();
        this.DestinationColumns = res as [];
        this.destinationReady = true;
      });
    }
  }

  getSource(name) {
    // const source = this.form.controls.MappingSourceColumns.value;
    // return this.sourceColumns.filter(x => !source.some(s => s.Name === x.name) || x.name === name);
    return this.sourceColumns;
  }

  getDestination(name) {
    // const destination = this.form.controls.MappingDestinationColumns.value;
    // return this.DestinationColumns.filter(x => !destination.some(s => s.Name === x.name) || x.name === name);
    return this.DestinationColumns;
  }

  initSuggessMapping() {
    this.mappingSchemaService.getAll().subscribe((res: any) => {
      if (!this.sourceId && !this.destinationId) {
        // tslint:disable-next-line:forin
        for (const key in res) {
          const result = {
            Source_id: res[key].nodeSource.id,
            Destination_id: res[key].nodeDestination.id,
            Source: res[key].nodeSource.name,
            Destination: res[key].nodeDestination.name,
            Checked: false,
            Score: res[key].score,
            Data: res[key]
          };
          this.suggessMappingDatas.push(result);
        }
      } else {
        // tslint:disable-next-line:forin
        for (const key in res) {
          if (res[key].nodeSource.id === +this.sourceId && res[key].nodeDestination.id === +this.destinationId) {
            const result = {
              Source_id: res[key].nodeSource.id,
              Destination_id: res[key].nodeDestination.id,
              Source: res[key].nodeSource.name,
              Destination: res[key].nodeDestination.name,
              Data: res[key],
              Checked: true
            };
            this.suggessMappingDatas.push(result);
          }
        }
      }
      this.loadMatchingItem();
    });
  }

}

import { Component, OnInit } from '@angular/core';
import { MappingNodeService } from 'src/app/services/mapping-node.service';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-mapping-approved',
  templateUrl: './mapping-approved.component.html',
  styleUrls: ['./mapping-approved.component.scss']
})
export class MappingApprovedComponent implements OnInit {
  data: any[];
  search: any;
  rawData: any[];
  constructor(
    private mappingNodeService: MappingNodeService,
    private toastr: ToastrService,
    private modalService: NgbModal) { }

  ngOnInit() {
    sessionStorage.setItem('isHome', 'false');
    sessionStorage.setItem('isRedirectSearchFromFile', 'false');
    
    this.mappingNodeService.getAllApproved().subscribe((res: any) => {
      this.rawData = res;
      this.data = this.rawData;
    });
  }

  onSearch(event) {
   // tslint:disable-next-line:max-line-length
   this.data = this.rawData.filter(x => x.nodeSource.name.indexOf(event) > -1 || x.nodeDestination.name.indexOf(event) > -1 || event === '');
  }

}

import { Component, Input } from '@angular/core';
import { Node } from '../../../d3';

@Component({
  // tslint:disable-next-line:component-selector
  selector: '[nodeVisual]',
  template: `
    <svg:g [attr.transform]="'translate(' + node.x + ',' + node.y + ')'">
      <svg:path *ngIf="node.type=='destinationNode'"
        d="M0,-20L20,10L-20,10Z"
        [attr.fill]="node.nodeColor">
      </svg:path>
      <svg:rect *ngIf="node.type=='source'"
          class="node show"
          [attr.fill]="node.nodeColor"
          x="-25"
          y="-25"
          width="35"
          height="35"
          style="stroke:red;stroke-width:3"
          [attr.nodeId]="node.id">
      </svg:rect>
      <svg:rect *ngIf="node.type=='destination'"
          class="node show"
          [attr.fill]="node.nodeColor"
          x="-25"
          y="-25"
          width="35"
          height="35"
          [attr.nodeId]="node.id">
      </svg:rect>
      <svg:circle *ngIf="node.type=='destination_draft'"
          class="node show"
          [attr.fill]="node.nodeColor"
          cx="0"
          cy="0"
          [attr.r]="30"
          [attr.nodeId]="node.id">
      </svg:circle>
      <svg:text
          class="node-name show"
          [attr.font-size]="node.fontSize"
          [attr.nodeId]="node.id"
          x="40" y="-50">
        {{node.display}}
      </svg:text>
    </svg:g>
  `,
  styleUrls: ['./node-visual.component.css']
})
export class NodeVisualComponent {
  // tslint:disable-next-line:no-input-rename
  @Input('nodeVisual') node: Node;
  hover: boolean;
}

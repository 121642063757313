import { Component, OnInit } from '@angular/core';
import { ColumnTable } from 'src/app/models/column-table/column-table.component';
import { FormBuilder, Validators } from '@angular/forms';
import { UserService } from 'src/app/services/user.service';
import { AuthService } from 'angularx-social-login';
import { ToastrService } from 'ngx-toastr';
import { NgbActiveModal, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { OrganizationService } from 'src/app/services/organization.service';
import { UserFeedbackService } from 'src/app/services/user-feedback.service';
import { ActionModel } from 'src/app/models/table-action-model';
import { UserFeedbackDetailModalComponent } from '../user-feedback-detail-modal/user-feedback-detail-modal.component';
import { ConfirmDialogComponent } from 'src/app/base-components/dialogs/confirm-dialog/confirm-dialog.component';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-user-feedback',
  templateUrl: './user-feedback.component.html',
  styleUrls: ['./user-feedback.component.scss']
})
export class UserFeedbackComponent implements OnInit {

  columns: ColumnTable[];
  sortBy = 'activityName';
  orderBy = 'asc';
  isShowResult = false;
  limit = 10;
  pageIndex = 1;
  data: any;
  dataTotal: 0;
  actions = [];
  rowClass = 'pointer';
  sourceId;
  sourceDataId;
  destinationId;
  destinationDataId;
  isLoading = true;

  constructor(
    private fb: FormBuilder,
    private userService: UserService,
    private authService: AuthService,
    private toastr: ToastrService,
    public modal: NgbActiveModal,
    private modalService: NgbModal,
    private organizationService: OrganizationService,
    private userFeedbackService: UserFeedbackService,
    private activatedRoute: ActivatedRoute
  ) {
    this.activatedRoute.queryParams.subscribe(params => {
      this.sourceId = activatedRoute.snapshot.params.source_id;
      this.sourceDataId = activatedRoute.snapshot.params.source_data_id;
      this.destinationId = activatedRoute.snapshot.params.destination_id;
      this.destinationDataId = activatedRoute.snapshot.params.destination_data_id;
    });

  }

  ngOnInit() {
    sessionStorage.setItem('isHome', 'false');
    sessionStorage.setItem('isRedirectSearchFromFile', 'false');
    
    this.columns = [
      new ColumnTable('Source', 'source', 'col', 'text', true),
      new ColumnTable('Destination', 'destination', 'col', 'text', true),
      new ColumnTable('Comment', 'message', 'col-4', 'text', true),
      new ColumnTable('Email', 'email', 'col-3', 'text', true),
      new ColumnTable('Create date', 'createdAt', 'col', 'createDate', true),
    ];
    this.actions.push(new ActionModel({
      callBack: (data) => this.unRead(data),
      type: 'unread'
    }));
    this.actions.push(new ActionModel({
      callBack: (data) => this.read(data),
      type: 'read'
    }));
    this.actions.push(new ActionModel({
      callBack: (data) => this.userItemSelected(data),
      type: 'viewDetail'
    }));
    this.actions.push(new ActionModel({
      callBack: (data) => this.delete(data),
      type: 'delete'
    }));

    if (this.sourceId && this.destinationId && this.sourceDataId && this.destinationDataId) {
      this.userFeedbackService.getSpecific(this.sourceId, this.sourceDataId, this.destinationId, this.destinationDataId).subscribe(res => {
        this.data = res;
        this.dataTotal = this.data.length;
        this.isLoading = false;
      });
    } else {
      this.userFeedbackService.getAll().subscribe(res => {
        this.data = res;
        this.dataTotal = this.data.length;
        this.isLoading = false;
      });
    }
    

  }

  unRead(data: any) {
    data.isRead = false;
    this.userFeedbackService.update(data).subscribe(x => {
      this.userFeedbackService.getAll().subscribe((res: []) => {
        this.data = res;
        this.dataTotal = this.data.length;
      });
    });
  }
  read(data: any) {
    data.IsRead = true;
    this.userFeedbackService.update(data).subscribe(x => {
      this.userFeedbackService.getAll().subscribe((res: []) => {
        this.data = res;
        this.dataTotal = this.data.length;
      });
    });
  }

  onTableOwnPageChange(event) {
    this.limit = event.pageSize;
    this.pageIndex = event.pageIndex;
  }

  userItemSelected(data) {
    const ngbModalOptions: NgbModalOptions = {
      backdrop : 'static',
      keyboard : false,
      size: 'lg'
    };
    const modalRef = this.modalService.open(
      UserFeedbackDetailModalComponent, ngbModalOptions
    );
    modalRef.componentInstance.id = data.id;
    modalRef.result.then((res: any) => {
      this.toastr.success('Send contact successful');
    }, (reason) => {
    });

  }

  delete(data) {
    const modalRef = this.modalService.open(ConfirmDialogComponent);
    modalRef.result.then((result) => {
      if (result) {
        this.userFeedbackService.delete(data.id).subscribe(x => {
          this.userFeedbackService.getAll().subscribe((res: []) => {
            this.data = res;
            this.dataTotal = this.data.length;
          });
          this.toastr.success('Delete Success');
        });
      }
    }, (reason) => {
    });
  }
}

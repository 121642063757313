import { Component, OnInit } from '@angular/core';
import { Users } from 'src/app/models/users';
import { FormBuilder, Validators, AbstractControl, ValidationErrors } from '@angular/forms';
import { UserService } from 'src/app/services/user.service';
import { AuthService } from 'angularx-social-login';
import { ToastrService } from 'ngx-toastr';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OrganizationService } from 'src/app/services/organization.service';
import { Observable } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';

@Component({
  selector: 'app-organization-modal',
  templateUrl: './organization-modal.component.html',
  styleUrls: ['./organization-modal.component.css']
})
export class OrganizationModalComponent implements OnInit {

  form: any;
  data = {
    id: 0,
    name: ''
  };
  organizations: any;
  isUpdate = false;

  constructor(
    private fb: FormBuilder,
    private userService: UserService,
    private authService: AuthService,
    private toastr: ToastrService,
    public modal: NgbActiveModal,
    private modalService: NgbModal,
    private organizationService: OrganizationService
  ) {
  }

  ngOnInit() {
    this.form = this.fb.group({
      Id: [this.data.id],
      Name: [this.data.name, [Validators.required], this.validateNameDuplicate.bind(this)],
    });
    this.authService.authState.subscribe((user) => {
      if (user) {
        this.authService.signOut(true);
      }
    });
  }

  submit() {
    if ( this.form.valid && this.isUpdate ) {
      this.organizationService.update(this.form.value).subscribe(res => {
        this.toastr.success('Update User Success');
        this.closeModal('Success');
      });
    } else if (this.form.valid && !this.isUpdate) {
      this.organizationService.add(this.form.value).subscribe(res => {
        this.toastr.success('Created User Success');
        this.closeModal('Success');
      });
    }
  }

  closeModal(event: string) {
    // this.dialogRef.close({event: event, data: data});
    this.modal.dismiss(event);
  }

  validateNameDuplicate({value}: AbstractControl): Observable<ValidationErrors | null> {
    const tmp = {
      Name: value,
      Id: this.data.id
    };

    return this.organizationService.checkDuplicate(tmp)
      .pipe(debounceTime(500), map((duplicate: boolean) => {
        if (duplicate) {
            return {
                isDuplicate: true
            };
        }
        return null;
    }));
  }
}

import { NotifyService } from 'src/app/services/notify.service';
import { ConfirmDialogComponent } from './../../../base-components/dialogs/confirm-dialog/confirm-dialog.component';
import { Component, OnInit } from '@angular/core';
import { NodeService } from 'src/app/services/node.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-node-management',
  templateUrl: './node-management.component.html',
  styleUrls: ['./node-management.component.scss']
})
export class NodeManagementComponent implements OnInit {
  nodes: any;

  constructor(private nodeService: NodeService,
              private toastr: ToastrService,
              private modal: NgbModal,
              private router: Router,
              private notifyService: NotifyService,
              public userService: UserService) { }

  ngOnInit() {
    sessionStorage.setItem('isHome', 'false');
    sessionStorage.setItem('isRedirectSearchFromFile', 'false');
    
    this.loadData();
  }

  loadData() {
    this.nodeService.getNodeAll().subscribe(res => {
      this.nodes = res;
    });
  }

  deleteNode(data) {
    const modalRef = this.modal.open(ConfirmDialogComponent);
    modalRef.componentInstance.data = data.name;
    modalRef.result.then((result) => {
      if (result) {
        this.nodeService.delete(data.id).subscribe(() => {
          this.loadData();
          this.toastr.success('Delete Success');
        });
      }
    }, (reason) => {
    });
  }

  editNode(data) {
    this.router.navigate(['node-management/edit/' + data.id]);
  }

}

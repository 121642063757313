import { MappingViewdetailComponent } from './../mapping-viewdetail/mapping-viewdetail.component';
import { UserService } from 'src/app/services/user.service';
import { Component, OnInit } from '@angular/core';
import { ColumnTable } from 'src/app/models/column-table/column-table.component';
import { MappingNodeService } from 'src/app/services/mapping-node.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ActionModel } from 'src/app/models/table-action-model';
import { ConfirmDialogComponent } from 'src/app/base-components/dialogs/confirm-dialog/confirm-dialog.component';
import { MappingSuggessModalComponent } from '../mapping-suggess-modal/mapping-suggess-modal.component';
import {saveAs as importedSaveAs} from 'file-saver';
import { Ng2Column } from 'src/app/models/ng2-smart-table-model';
import { LoadingScreenService } from 'src/app/services/loading-screen.service';

@Component({
  selector: 'app-mapping-approved-list',
  templateUrl: './mapping-approved-list.component.html',
  styleUrls: ['./mapping-approved-list.component.css'],
  styles: ['.text_header { color: back; }']
})
export class MappingApprovedListComponent implements OnInit {

  sourceId: any;
  destinationId: any;
  data: any;
  total: any;
  limit = 10;
  pageIndex = 1;
  isReady: boolean;
  rawData: any;
  sourceName = '';
  destinationName = '';
  search = '';
  source: any;
  destination: any;
  sortBy = 'sourceValue';
  orderBy = 'asc';
  isSearch = true;
  isMapping = true;
  searchText: any;
  mappingType = '0';
  settings: Object = {};
  dataTable = [];
  classToApply = '';

  constructor(
    private mappingNodeService: MappingNodeService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private userService: UserService,
    private modalService: NgbModal,
    private loadingScreenService: LoadingScreenService) {
      this.activatedRoute.queryParams.subscribe(params => {
        this.sourceId = activatedRoute.snapshot.params.source_id;
        this.destinationId = activatedRoute.snapshot.params.destination_id;
        if (activatedRoute.snapshot.params.mapping_id) {;
          this.viewDetail({ id: activatedRoute.snapshot.params.mapping_id });
        }
      });
    }

  ngOnInit() {
    sessionStorage.setItem('isHome', 'false');
    sessionStorage.setItem('isRedirectSearchFromFile', 'false');

    this.loadingScreenService.loadingScreen.emit(true);
    this.loadSmartTable();

  }

  delete(data: any) {
    const modalRef = this.modalService.open(ConfirmDialogComponent);
    modalRef.result.then((result) => {
      if (result) {
        this.mappingNodeService.deleteApprove(data.id).subscribe(x => {
          this.toastr.success('Delete Success');
          let index = this.dataTable.findIndex(prop => prop.id == data.id);
          let newDataTable = this.dataTable
          newDataTable.splice(index, 1);
          this.reloadTable(newDataTable);
        });
      }
    }, (reason) => {
    });
  }

  reloadTable(data : any) {
    this.dataTable = [];
    this.loadingScreenService.loadingScreen.emit(true);
    this.isReady = false;

    const criteria = {
      Text: this.searchText,
      Page: this.pageIndex,
      Limit: this.limit,
      OrderBy: this.orderBy,
      SortBy: this.sortBy,
      SourceId: this.sourceId,
      DestinationId: this.destinationId,
      IsSuggest: false,
      IsImport: false,
      IsManual: false,
    };
    if (this.mappingType === '1') {
      criteria.IsSuggest = true;
    } else if (this.mappingType === '2') {
      criteria.IsImport = true;
    } else if (this.mappingType === '3') {
      criteria.IsManual = true;
    } else {
      criteria.IsSuggest = true;
      criteria.IsImport = true;
      criteria.IsManual = true;
    }

      let column = {
        source: {
          title: 'Source',
          filter: true
        },destination: {
          title: 'Destination',
          filter: true
        },mappingtype: {
          title: 'Mapping Type',
          filter: true,
          class: 'text-center',
          type: 'html',
          valuePrepareFunction: (data) => { 
            return '<div class="text-center">' + data + '</div>'; 
          }
        },approvedby: {
          title: 'Approved By',
          filter: true
        },createdate: {
          title: 'Create Date',
          filter: true
        },
      };
  
      this.dataTable = data;

      this.settings = {
        mode: 'external',
        columns: column,
        actions: {
          columnTitle: ' ',
          add: false,
          edit: true,
          delete: true,
          position: 'right'
        },
        edit: {
          editButtonContent: '<label class ="icon-view"><i class="fas fa-file-alt text-warning"></i> View </label>'
        },
        delete: {
          deleteButtonContent: '<label class ="icon-delete"><i class="fas fa-trash-alt text-danger action-btn"></i> Delete</label>'
        },
        pager: {
          perPage: 10,
          paginateSize: 10
        }
      };
      this.isReady = true;
      this.loadingScreenService.loadingScreen.emit(false);
  }

  loadSmartTable() {
    this.dataTable = [];
    this.loadingScreenService.loadingScreen.emit(true);
    this.isReady = false;
    const criteria = {
      Text: this.searchText,
      Page: this.pageIndex,
      Limit: this.limit,
      OrderBy: this.orderBy,
      SortBy: this.sortBy,
      SourceId: this.sourceId,
      DestinationId: this.destinationId,
      IsSuggest: false,
      IsImport: false,
      IsManual: false,
    };
    if (this.mappingType === '1') {
      criteria.IsSuggest = true;
    } else if (this.mappingType === '2') {
      criteria.IsImport = true;
    } else if (this.mappingType === '3') {
      criteria.IsManual = true;
    } else {
      criteria.IsSuggest = true;
      criteria.IsImport = true;
      criteria.IsManual = true;
    }

    this.mappingNodeService.SearchBySourceAndDestinationApprove(criteria).subscribe((res: any) => {
      this.data = res.data.data;
      this.source = res.data.source;
      this.destination = res.data.destination;

      let column = {
        source: {
          title: 'Source',
          filter: true
        },destination: {
          title: 'Destination',
          filter: true
        },mappingtype: {
          title: 'Mapping Type',
          filter: false,
          class: 'text-center',
          type: 'html',
          valuePrepareFunction: (data) => { 
            return '<div class="text-center">' + data + '</div>'; 
          }
        },approvedby: {
          title: 'Approved By',
          filter: true
        },createdate: {
          title: 'Create Date',
          filter: true
        },
      };
      res.data.data.forEach(element => {
        let destinationCount = element.destinationValue.split(",");
        let destinationVal = destinationCount.length > 1 ? `${destinationCount.length} items` : element.destinationValue;
        let date_ob = new Date(element.createdAt);
        let date = ("0" + date_ob.getDate()).slice(-2);
        let month = date_ob.toLocaleDateString('default', { month: 'short' })
        let year = date_ob.getFullYear();
        let hours = date_ob.getHours();
        let minutes = (date_ob.getMinutes()<10?'0':'') + date_ob.getMinutes();

        let icon = '';
        if (element.isManualMapping) {
          icon += '<i class="fas fa-user-check"></i>'
        }
        if (element.isSuggestMapping) {
          icon += '<i class="fas fa-robot"></i>'
        }
        if (element.isImportMapping) {
          icon += '<i class="fas fa-file-import"></i>'
        }

        this.dataTable.push({
          source: this.source.name + ': ' +  element.sourceValue,
          destination: this.destination.name + ': ' + destinationVal,
          mappingtype: icon,
          createdate: date + " " + month + " " + year + " " + hours + ":" + minutes,
          approvedby: element.approveBy ? element.approveBy.firstName + " " + element.approveBy.lastName : "",
          id : element.id
        })
      });

      this.settings = {
        mode: 'external',
        columns: column,
        actions: {
          columnTitle: ' ',
          add: false,
          edit: true,
          delete: true,
          position: 'right'
        },
        edit: {
          editButtonContent: '<label class ="icon-view"><i class="fas fa-file-alt text-warning"></i> View </label>'
        },
        delete: {
          deleteButtonContent: '<label class ="icon-delete"><i class="fas fa-trash-alt text-danger action-btn"></i> Delete</label>'
        },
        pager: {
          perPage: 10,
          paginateSize: 10
        }
      };
      this.isReady = true;
      this.loadingScreenService.loadingScreen.emit(false);
    });
  }

  viewDetail(data: any) {
    const ngbModalOptions: NgbModalOptions = {
      backdrop : 'static',
      keyboard : false,
      size: 'lg',
      windowClass: 'waiting-approve-view-detail',
    };
    const modalImport = this.modalService.open(MappingViewdetailComponent, ngbModalOptions);
    modalImport.componentInstance.mappingNodeId = data.id;
    modalImport.componentInstance.mode = 'viewDetail';
    modalImport.result.then((result) => {
      if (result == 'delete') 
      {
        let index = this.dataTable.findIndex(prop => prop.id == data.id);
        let newDataTable = this.dataTable
        newDataTable.splice(index, 1);
        this.reloadTable(newDataTable);
      }
    });
  }

  donwloadData() {
    const criteria = {
      Text: this.searchText,
      Page: this.pageIndex,
      Limit: this.limit,
      OrderBy: this.orderBy,
      SortBy: this.sortBy,
      SourceId: this.sourceId,
      DestinationId: this.destinationId,
      IsSuggest: false,
      IsImport: false,
      IsManual: false,
    };
    if (this.mappingType === '1') {
      criteria.IsSuggest = true;
    } else if (this.mappingType === '2') {
      criteria.IsImport = true;
    } else if (this.mappingType === '3') {
      criteria.IsManual = true;
    } else {
      criteria.IsSuggest = true;
      criteria.IsImport = true;
      criteria.IsManual = true;
    }

    this.mappingNodeService.downloadBySourceAndDestinationApprove(criteria).subscribe((res: any) => {
      importedSaveAs(res, 'MappingApprove');
    });
  }

  onDeleteAll(event: any[]) {
    //TODO : Remove this function if lastest mockup doesn't require check box to delete all
    const modalRef = this.modalService.open(ConfirmDialogComponent);
    modalRef.componentInstance.text = 'Are you sure to delete notification ' + event.length + ' items ?';
    modalRef.componentInstance.type = 'custom';
    modalRef.result.then((result) => {
      if (result) {
        this.mappingNodeService.deleteAllApprove(event).subscribe(x => {
          this.loadSmartTable();
          this.toastr.success('Delete Success');
        });
      }
    }, (reason) => {
    });
  }

}

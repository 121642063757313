import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-setting-main',
  templateUrl: './setting-main.component.html',
  styleUrls: ['./setting-main.component.scss']
})
export class SettingMainComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    sessionStorage.setItem('isHome', 'false');
    sessionStorage.setItem('isRedirectSearchFromFile', 'false');
  }

}
